import React, { useEffect, useState } from "react";
import {
  Table,
  Space,
  Button,
  notification,
  Tag,
  message,
  Typography,
  Tooltip,
} from "antd";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import FormService from "./FormService";
import axiosInstance from "../../../Utils/axios";
import EditService from "./EditService";
import DeleteService from "./DeleteService";

const ServicesConfiguration = () => {
  const [api, contextHolder] = notification.useNotification();
  const [servers, setServers] = useState([]);
  const [services, setServices] = useState([]);

  const exportData = () => {
    const data = services.map((service) => ({
      Service: service.name,
      Serveurs: service.server.map((elem) => {
        const server = servers.find((serv) => serv._id === elem._id);
        const serverName =
          server?.pseudo !== "" && server?.pseudo
            ? server?.pseudo
            : server?.hostName;
        return serverName ?? "N/A";
      }).join(" , "),
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Services");
    XLSX.writeFile(workbook, "services.xlsx");
  };  

  const columns = [
    {
      title: "Service",
      dataIndex: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: 500,
      render: (text) => (
        <>
          {text ? (
            <Typography.Paragraph
              style={{ margin: 0, whiteSpace: "pre-wrap" }}
              ellipsis={{
                rows: 2,
                expandable: true,
                symbol: "Plus",
              }}
            >
              {text}
            </Typography.Paragraph>
          ) : (
            "N/A"
          )}
        </>
      ),
    },
    {
      title: "Serveur(s) associé(s)",

      render: (_, record) => (
        <div className="row px-3">
          {record?.server?.map((elem) => {
            const server = servers?.find((serv) => serv?._id === elem?._id);
            const serverName =
              server?.pseudo !== "" && server?.pseudo
                ? server?.pseudo
                : server?.hostName;
            console.log(server);
            return (
              <Tooltip
                key={elem?._id}
                title={elem?.url}
                className="col-auto my-2"
              >
                <Tag color="blue">{serverName ?? "N/A"}</Tag>
              </Tooltip>
            );
          })}
        </div>
      ),
    },
    {
      title: "Action",
      render: (_, record) => (
        <Space>
          <EditService
            servers={servers}
            services={services}
            setServices={setServices}
            record={record}
          />
          <DeleteService
            services={services}
            setServices={setServices}
            record={record}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    async function fetchServers() {
      try {
        const { data } = await axiosInstance.get("/api/administration/servers");

        setServers(data?.data?.servers);

        if (data?.data?.servers.length === 0)
          api.info({
            message: `Notification`,
            description:
              "Aucun serveur trouvé, veuillez vérifier la liste de vos serveurs avant de procéder à toute action liée aux services.",
            placement: "topRight",
          });
      } catch (error) {}
    }

    async function fetchServices() {
      try {
        const { data } = await axiosInstance.get(
          "/api/administration/services"
        );

        setServices(data?.data?.services);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
    fetchServices();
    fetchServers();
  }, []);
  return (
    <div className="bg-white p-4 rounded-4 shadow-sm ">
      <div className="mb-3 d-flex justify-content-between">
        <h4>⚙️ Configuration des services</h4>
        <Space>
          <FormService
            services={services}
            setServices={setServices}
            servers={servers}
          />
          <Button onClick={exportData}>
            Exporter
          </Button>
        </Space>
        {contextHolder}
      </div>
      <Table columns={columns} dataSource={services} size="small" bordered />
    </div>
  );
};

export default ServicesConfiguration;

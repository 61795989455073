import React, { useEffect } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import axiosInstance from '../../../Utils/axios';

const Plesk = ({ data }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            plesk: data
        })
    }, [data])

    const onFinish = async (formValues) => {
        try {
            const { data } = await axiosInstance?.patch("/api/platform/config-access", formValues);
            if (data?.status === "success") {
                message.success(data?.message);
            }
        } catch (error) {
            message.error(error?.response?.data?.message);
        }
    }
    return (
        <Form
            form={form}
            onFinish={onFinish}
            className='border p-2 rounded-2 shadow-sm'
            autoComplete="off"
            layout='vertical'
        >
            <Form.List name="plesk">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <div
                                key={key}
                                className='d-flex align-items-center'
                            >
                                <Form.Item
                                    {...restField}
                                    name={[name, 'type']}
                                    label="Type"
                                >
                                    <Input defaultValue="Plesk" disabled />
                                </Form.Item>
                                <Form.Item
                                    className='w-100 mx-2'
                                    {...restField}
                                    name={[name, 'serveur']}
                                    label="Serveur"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Veuillez saisir le nom du serveur.',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Serveur" className='w-100' />
                                </Form.Item>
                                <MinusCircleOutlined className='mt-2 text-danger' onClick={() => remove(name)} />
                            </div>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Ajouter un serveur
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
            <Form.Item>
                <Button type="primary" shape='round' htmlType="submit">
                    Sauvguarder
                </Button>
            </Form.Item>
        </Form>
    )
};
export default Plesk;
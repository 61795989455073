import React, { useState } from "react";
import { Button, Descriptions, Space, Tag, Modal, Tooltip,Skeleton } from "antd";
import axiosInstance from "../Utils/axios";
import dayjs from "dayjs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const TeleworkRequestsDrawer = ({ record }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  const showDrawer = () => {
    setLoading(true);
    axiosInstance.get(`/api/leaves/${record?._id}`).then((res) => {
      setData(res?.data?.Leave);
      console.log(res?.data?.Leave);
      setLoading(false);
    });
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Tooltip title="Voir les détails">
        <Button size="small" shape="circle" onClick={showDrawer}>
          <FontAwesomeIcon icon={faEye} />
        </Button>
      </Tooltip>

      <Modal
        open={open}
        onCancel={onClose}
        onOk={onClose}
        width={1000}
        footer={[
          <Button key="back" type="primary" onClick={onClose}>
            Ok
          </Button>,
        ]}
      >
        <div className="mx-3">
        {!loading ? (
          <Descriptions bordered title="Détails de la demande">
            {data?.fullName && (
              <Descriptions.Item span={3} label={"Demandeur"}>
                {data?.fullName}
              </Descriptions.Item>
            )}
            <Descriptions.Item span={3} label="Date de soumission">
              {dayjs(data?._posted_date).format("DD/MM/YYYY HH:mm")}
            </Descriptions.Item>
            {data?._typeLeave && (
              <Descriptions.Item span={3} label={"Type de la demande"}>
                {data?._typeLeave}
              </Descriptions.Item>
            )}

            <Descriptions.Item span={3} label="Date de début">
              {dayjs(data?.BeginingDate).format("DD/MM/YYYY")}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="Date de fin">
              {dayjs(data?.EndingDate).format("DD/MM/YYYY")}
            </Descriptions.Item>

            <Descriptions.Item span={3} label="Durée">
              {data?.DaysNumber + " Jour(s)"}{" "}
            </Descriptions.Item>
            {data?._except && (
              <Descriptions.Item span={3} label="Sauf">
                {" "}
                <Space>
                  {data?._except?.map((elem) => {
                    return <Tag color={"blue"}>{elem}</Tag>;
                  })}
                </Space>
              </Descriptions.Item>
            )}

            <Descriptions.Item
              span={4}
              label="Motif"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {data?._motif}
            </Descriptions.Item>
            <Descriptions.Item
              span={4}
              label="Statut"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {data?.status === "Pending" && (
                <Tag color="orange">En attente</Tag>
              )}
              {data?.status === "Accepted" && <Tag color="green">Acceptée</Tag>}
              {data?.status === "Rejected" && <Tag color="red">Rejetée</Tag>}
              {data?.status === "Canceled" && <Tag color="red">Annulée</Tag>}
            </Descriptions.Item>
          </Descriptions>
           ) : (
            <div className="row">
              <Descriptions bordered title="Détails de la demande">
               
                <Descriptions.Item span={3} label={ <Skeleton.Input active className="w-100" size="large" />} >
                  <Skeleton.Input active className="w-100" size="large" />
                </Descriptions.Item>
               
                <Descriptions.Item span={3} label={ <Skeleton.Input active className="w-100" size="large" />} >
                  <Skeleton.Input active className="w-100" size="large" />
                </Descriptions.Item>
               
                <Descriptions.Item span={3} label={ <Skeleton.Input active className="w-100" size="large" />} >
                  <Skeleton.Input active className="w-100" size="large" />
                </Descriptions.Item>
               
                <Descriptions.Item span={3} label={ <Skeleton.Input active className="w-100" size="large" />} >
                  <Skeleton.Input active className="w-100" size="large" />
                </Descriptions.Item>
               
                <Descriptions.Item span={3} label={ <Skeleton.Input active className="w-100" size="large" />} >
                  <Skeleton.Input active className="w-100" size="large" />
                </Descriptions.Item>
               
                <Descriptions.Item span={3} label={ <Skeleton.Input active className="w-100" size="large" />} >
                  <Skeleton.Input active className="w-100" size="large" />
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}
        </div>
      </Modal>

      {/* <Drawer
        title={record?._typeLeave}
        placement="right"
        onClose={onClose}
        open={open}
        size={"large"}
      >
        <Descriptions layout="vertical">
          {record?.fullName && (
            <Descriptions.Item span={3} label={"Nom et prénom"}>
              {record?.fullName}
            </Descriptions.Item>
          )}

          <Descriptions.Item span={2} label="Date de début">
            {dayjs(record?.BeginingDate).format("DD/MM/YYYY")}
          </Descriptions.Item>
          <Descriptions.Item span={2} label="Date de fin">
            {dayjs(record?.EndingDate).format("DD/MM/YYYY")}
          </Descriptions.Item>
          <Descriptions.Item span={2} label="Date de soumission">
            {dayjs(record?._posted_date).format("DD/MM/YYYY HH:mm")}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Durée">
            {record?.DaysNumber + " Jour(s)"}{" "}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Sauf">
            {" "}
            <Space>
              {record?._except?.map((elem) => {
                return <Tag color={"blue"}>{elem}</Tag>;
              })}
            </Space>
          </Descriptions.Item>

          <Descriptions.Item
            span={4}
            label="Motif"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {record?._motif}
          </Descriptions.Item>
        </Descriptions>
      </Drawer> */}
    </>
  );
};
export default TeleworkRequestsDrawer;

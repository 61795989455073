import React from "react";
import { Button, Calendar, Tag, Tooltip } from "antd";
import dayjs from "dayjs";
import PlusModal from "./PlusModal";

const CalendarMonth = ({ headerRender, data }) => {
  const getLeaveByDate = (value) => {
    const returnedData = data.filter((leave) => {
      if (
        dayjs(leave.BeginingDate).isBefore(dayjs(value)) &&
        dayjs(value).isBefore(dayjs(leave.EndingDate))
      ) {
        return leave;
      }
    });
    return returnedData || [];
  };

  const dateCellRender = (value) => {
    const leaves = getLeaveByDate(value);
    const dayOfWeek = value.day();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      return null; // Si la cellule est un samedi ou un dimanche, ne pas afficher de congé
    }
    return (
      <>
        {leaves.map((elem, idx) => {
          if (idx < 2) {
            return (
              <div
                className="w-100 mb-1 overflow-hidden"
                style={{
                  borderLeft: "3px solid grey",
                  paddingLeft: "7px",
                  borderColor: elem?._is_half_day
                    ? "#FFBE0B"
                    : elem?._typeLeave === "Congé annuel"
                    ? "#1ECB44"
                    : elem?._typeLeave === "Autorisation"
                    ? "#8338EC"
                    : elem?._typeLeave === "Congé sans solde"
                    ? "#FB0707"
                    : elem?._typeLeave === "Congé Special"
                    ? "#FB33FF"
                    : "#3A86FF",
                }}
              >
                <span
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {capitalizeFirstLetter(elem?.fullName)}
                </span>
              </div>
            );
          }
        })}
        {leaves?.length > 2 && <PlusModal data={leaves} date={value} />}
      </>
    );
  };

  const capitalizeFirstLetter = (str) => {
    const capitalized = str[0].toUpperCase() + str.slice(1).toLowerCase();
    return capitalized;
  };
  return (
    <Calendar
      validRange={[dayjs().startOf("month"), dayjs().endOf("month")]}
      headerRender={headerRender}
      dateCellRender={dateCellRender}
    />
  );
};
export default CalendarMonth;

const pathLocations = [
  {
    path: "/",
    key: "1",
  },
  {
    path: "/manage/self",
    key: "2",
  },
  {
    path: "/team/calendar",
    key: "13",
  },
  {
    path: "/absences",
    key: "33",
  },
  {
    path: "/request",
    key: "2",
  },
  {
    path: "/manage/self/telework",
    key: "988",
  },
  {
    path: "/pending/requests",
    key: "256",
  },
  {
    path: "/self/access",
    key: "977",
  },
  {
    path: "/request/telework",
    key: "988",
  },

  {
    path: "/balance/archive",
    key: "34",
  },

  {
    path: "/other/requests",
    key: "999",
  },
  {
    path: "/manage/subordinates",
    key: "556",
  },
  {
    path: "/manage/subordinates/archive",
    key: "557",
  },
  {
    path: "/request/equipments",
    key: "511",
  },
  {
    path: "/self/equipments",
    key: "512",
  },
  {
    path: "/tools",
    key: "6",
  },
  {
    path: "/trombinoscope",
    key: "7",
  },
  {
    path: "/faq",
    key: "8",
  },
  {
    path: "/suggestions",
    key: "10",
  },
  {
    path: "/work/certificate",
    key: "11",
  },
];

export default pathLocations;

import {
  Button,
  Popconfirm,
  Space,
  Steps,
  Table,
  Tag,
  Typography,
  Tooltip,
  ConfigProvider,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../../../../Utils/axios";
import {
  LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  MinusCircleTwoTone,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import RejectModal from "./RejectModal";
import GlobalContext from "../../../../Context/GlobalContext";
import SysAdminApproveModal from "./SysAdminApproveModal";
import EquipmentRequestsDrawer from "../../../../Drawers/EquipmentRequestsDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const EquipmentsRequests = ({ size, getColumnSearchProps }) => {
  const { profile } = useContext(GlobalContext);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [availableEquipments, setAvailableEquipments] = useState([]);

  const handleApprove = async (id) => {
    try {
      const { data } = await axiosInstance.patch(
        `/api/request/access/${
          profile?.sub_role === "SUPER_ADMIN" ? "hr" : "sysadmin"
        }/${id}`,
        { status: true }
      );
      if (data?.status === "success") {
        setRefresh(!refresh);
        message.success(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    function fetchRecords() {
      axiosInstance
        ?.get("/api/request/equipments")
        .then((res) => {
          if (res?.data?.status === "success") {
            const temp = res?.data?.requests?.filter(
              (elem) => elem?.status === "PENDING"
            );
            setRecords(temp);

            axiosInstance.get("/api/equipments/available").then((response) => {
              setAvailableEquipments(response?.data?.equipments);
              setLoading(false);
            });
          }
        })
        .catch((error) => {
          message.error(error?.response?.data?.message);
        });
    }

    fetchRecords();
  }, [refresh]);

  const columns = [
    {
      title: "Nom et prénom",
      dataIndex: "sender",
      ...getColumnSearchProps("sender", "nom et prénom"),
    },
    {
      title: "Équipement demandé",
      dataIndex: "request",
      ...getColumnSearchProps("request", "équipement demandé"),
      render: (_, requestedLeaves) => (
        <Typography.Paragraph>
          <Tag
            color={
              JSON.parse(requestedLeaves?.json)?.type === "Réparation"
                ? "red"
                : "blue"
            }
          >
            {JSON.parse(requestedLeaves?.json)?.type}
          </Tag>{" "}
          {requestedLeaves.request}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Statut",
      filters: [
        {
          text: (
            <Tag color="red">
              Rejetée <CloseCircleOutlined style={{ marginLeft: "5px" }} />
            </Tag>
          ),
          value: "REJECTED",
        },
        {
          text: (
            <Tag color="green">
              Approuvée <CheckCircleOutlined style={{ marginLeft: "5px" }} />
            </Tag>
          ),
          value: "APPROVED",
        },
        {
          text: (
            <Tag color="orange">
              En attente <LoadingOutlined style={{ marginLeft: "5px" }} />
            </Tag>
          ),
          value: "PENDING",
        },
        {
          text: (
            <Tag color="cyan">
              Annulée <MinusCircleOutlined style={{ marginLeft: "5px" }} />
            </Tag>
          ),
          value: "CANCELLED",
        },
      ],
      onFilter: (value, record) => record.status.includes(value),
      render: (_, record) => (
        <Steps
          items={[
            {
              title: "Responsable",
              status:
                record?.status !== "PENDING"
                  ? "finish"
                  : record?.status === !record?.answered_by[0]?.by
                  ? "process"
                  : "finish",
              description:
                record?.status === "CANCELLED"
                  ? "Annulé"
                  : record?.status === "REJECTED"
                  ? "Rejeté"
                  : record?.answered_by[0]?.by || "En attente",
              icon:
                record?.status !== "PENDING" && !record?.answered_by[0]?.by ? (
                  <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                ) : !record?.answered_by[0]?.by ? (
                  <LoadingOutlined />
                ) : record?.answered_by[0].status ? (
                  <CheckCircleTwoTone twoToneColor={"#87d068"} />
                ) : (
                  <CloseCircleTwoTone twoToneColor={"#f3531e"} />
                ),
            },
            {
              title: "RH",
              status:
                record?.status !== "PENDING"
                  ? "finish"
                  : !record?.answered_by[0]?.by ||
                    !record?.answered_by[0]?.status
                  ? "wait"
                  : record?.answered_by[0]?.by && !record?.answered_by[1]?.by
                  ? "process"
                  : "finish",
              description:
                record?.status === "CANCELLED"
                  ? "Annulé"
                  : record?.status === "REJECTED"
                  ? "Rejeté"
                  : record?.answered_by[1]?.by || "En attente",
              icon:
                record?.status !== "PENDING" && !record?.answered_by[1]?.by ? (
                  <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                ) : !record?.answered_by[0]?.by ||
                  !record?.answered_by[0]?.status ? (
                  <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                ) : record?.answered_by[0]?.by &&
                  !record?.answered_by[1]?.by ? (
                  <LoadingOutlined />
                ) : record?.answered_by[1].status ? (
                  <CheckCircleTwoTone twoToneColor={"#87d068"} />
                ) : (
                  <CloseCircleTwoTone twoToneColor={"#f3531e"} />
                ),
            },
            {
              title: "Sys-Admin",
              status:
                record?.status !== "PENDING"
                  ? "finish"
                  : !record?.answered_by[1]?.by ||
                    !record?.answered_by[1]?.status
                  ? "wait"
                  : record?.answered_by[1]?.by && !record?.answered_by[2]?.by
                  ? "process"
                  : "finish",
              description:
                record?.status === "CANCELLED"
                  ? "Annulé"
                  : record?.status === "REJECTED"
                  ? "Rejeté"
                  : record?.answered_by[2]?.by || "En attente",
              icon:
                record?.status !== "PENDING" && !record?.answered_by[2]?.by ? (
                  <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                ) : !record?.answered_by[1]?.by ||
                  !record?.answered_by[1]?.status ? (
                  <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                ) : record?.answered_by[1]?.by &&
                  !record?.answered_by[2]?.by ? (
                  <LoadingOutlined />
                ) : record?.answered_by[2].status ? (
                  <CheckCircleTwoTone twoToneColor={"#87d068"} />
                ) : (
                  <CloseCircleTwoTone twoToneColor={"#f3531e"} />
                ),
            },
          ]}
          size="small"
        />
      ),
    },
    {
      title: "Action",
      render: (_, record) => (
        <Space>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#52C41A",
              },
            }}
          >
            <Popconfirm
              placement="topLeft"
              title="Etes-vous sûr d'approuver la demande ?"
              okText="Oui"
              cancelText="Non"
              onConfirm={() => handleApprove(record?._id)}
              disabled={
                record?.answered_by[
                  profile?.sub_role === "SUPER_ADMIN" ? 1 : 2
                ] || record?.status !== "PENDING"
              }
            >
              {profile?.sub_role === "SUPER_ADMIN" ? (
                <Tooltip title="Approuver la demande">
                  <Button
                    size="small"
                    shape="circle"
                    type="primary"
                    disabled={
                      record?.answered_by.length !== 1 ||
                      record?.status !== "PENDING"
                    }
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                </Tooltip>
              ) : (
                <SysAdminApproveModal
                  refresh={refresh}
                  availableEquipments={availableEquipments}
                  setAvailableEquipments={setAvailableEquipments}
                  setRefresh={setRefresh}
                  record={record}
                  index={profile?.sub_role === "SUPER_ADMIN" ? 1 : 2}
                />
              )}
            </Popconfirm>
          </ConfigProvider>
          <RejectModal
            refresh={refresh}
            setRefresh={setRefresh}
            record={record}
            index={profile?.sub_role === "SUPER_ADMIN" ? 1 : 2}
          />
          <EquipmentRequestsDrawer record={record} />
        </Space>
      ),
    },
  ];
  return (
    <div>
      <Table
        columns={columns}
        size={size}
        loading={loading}
        dataSource={records}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default EquipmentsRequests;

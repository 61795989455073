import { Button, Skeleton, Table, Tag, message } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import axiosInstance from "../../../Utils/axios";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faFileArrowDown,
} from "@fortawesome/free-solid-svg-icons";

const UserSkills = () => {
  const [skillsGrid, setSkillsGrid] = useState([]);
  const [loading, setLoading] = useState(true);
  const Navigate = useNavigate();

  const queryParameters = new URLSearchParams(window.location.search);
  const title = queryParameters.get("name");
  const { id } = useParams();

  useEffect(() => {
    async function fetchGridResults() {
      try {
        const { data } = await axiosInstance.get(
          "/api/grid/skills/buckets/users/by/" + id
        );

        setSkillsGrid(data?.usersSkillBuckets);
        setLoading(false);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }

    fetchGridResults();
  }, []);

  const handleExportToXLSX = () => {
    const data = skillsGrid.map((record) => {
      let temp = {};
      temp["Matricule"] = record?.matricule;
      temp["Nom et Prénom"] = record?.fullName;
      temp["Date"] = dayjs(record?.postedDate).format("DD/MM/YYYY");

      record?.advanced?.forEach((elem) => {
        temp[elem?.label] = "Avancé";
      });
      record?.average?.forEach((elem) => {
        temp[elem?.label] = "Moyen";
      });
      record?.beginner?.forEach((elem) => {
        temp[elem?.label] = "Débutant";
      });
      record?.notApplicable?.forEach((elem) => {
        temp[elem?.label] = "N/A";
      });

      return temp;
    });

    const worksheet = XLSX.utils.json_to_sheet(data);

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Grid");

    XLSX.writeFile(
      workbook,
      `Grille_de_compétances_${dayjs().format("DD_MM_YYYY_HH_mm")}.xlsx`
    );
  };

  const columns = [
    // {
    //   title: "Nom et Prénom",
    //   dataIndex: "fullName",
    //   width: 200,
    // },
    {
      title: "Soumettre le",
      dataIndex: "postedDate",
      width: 170,
      render: (text) => (
        <span style={{ fontWeight: "500" }}>
          {dayjs(text).format("DD/MM/YYYY HH:mm")}
        </span>
      ),
    },
    {
      title: "Niveau",
      children: [
        {
          title: "N/A",
          render: (_, record) => (
            <div>
              {record?.notApplicable?.map((elem) => (
                <Tag className="my-1">{elem?.label}</Tag>
              ))}
            </div>
          ),
        },
        {
          title: <span style={{ color: "#D4380D" }}>Débutant</span>,
          render: (_, record) => (
            <div>
              {record?.beginner?.map((elem) => (
                <Tag className="my-1" color="red">
                  {elem?.label}
                </Tag>
              ))}
            </div>
          ),
        },
        {
          title: <span style={{ color: "#D46B08" }}>Moyen</span>,
          render: (_, record) => (
            <div>
              {record?.average?.map((elem) => (
                <Tag className="my-1" color="orange">
                  {elem?.label}
                </Tag>
              ))}
            </div>
          ),
        },
        {
          title: <span style={{ color: "#389E0D" }}>Avancé</span>,
          render: (_, record) => (
            <div>
              {record?.advanced?.map((elem) => (
                <Tag className="my-1" color="green">
                  {elem?.label}
                </Tag>
              ))}
            </div>
          ),
        },
      ],
    },
  ];
  return (
    <div className="pt-2">
      <div className="mb-3">
        <h4>{title}</h4>
      </div>
      <div className="bg-white p-4 rounded-4 shadow-sm ">
        <div>
          <div className="d-flex justify-content-between">
            <Button
              onClick={() => Navigate("/administration/employees/skills")}
              shape="round"
              icon={<FontAwesomeIcon icon={faCircleChevronLeft} />}
            >
              Grille des compétances
            </Button>
            <Button
              shape="round"
              className="mb-2"
              icon={
                loading ? (
                  <LoadingOutlined />
                ) : (
                  <FontAwesomeIcon icon={faFileArrowDown} />
                )
              }
              onClick={handleExportToXLSX}
              disabled={loading}
            >
              Exporter
            </Button>
          </div>
          <Table
            loading={loading}
            columns={columns}
            dataSource={skillsGrid}
            scroll={{ x: "1080px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default UserSkills;

const pathLocations = [
  {
    path: "/administration",
    key: "1",
  },
  {
    path: "/administration/employees/contracts",
    key: "25",
  },
  {
    path: "/administration/employees/skills",
    key: "26",
  },
  {
    path: "/administration/employees/kpis",
    key: "27",
  },
  {
    path: "/administration/employees/form",
    key: "21",
  },
  {
    path: "/administration/employees/list",
    key: "22",
  },
  {
    path: "/administration/leaves/form",
    key: "32",
  },
  {
    path: "/administration/absences/list",
    key: "35",
  },
  {
    path: "/administration/absences/list/others",
    key: "36",
  },
  {
    path: "/administration/leaves/list",
    key: "31",
  },

  {
    path: "/administration/team/calendar",
    key: "13",
  },
  {
    path: "/administration/documents/form",
    key: "152",
  },
  {
    path: "/administration/documents/list",
    key: "151",
  },

  {
    path: "/administration/equipments/managment",
    key: "103",
  },
  {
    path: "/administration/equipments/list",
    key: "102",
  },
  {
    path: "/administration/requests",
    key: "444",
  },
  {
    path: "/administration/requests/archive",
    key: "454",
  },
  {
    path: "/administration/holidays",
    key: "5",
  },

  {
    path: "/administration/logs",
    key: "6",
  },
  {
    path: "/administration/parametre",
    key: "9",
  },
  {
    path: "/administration/faq",
    key: "99",
  },
  {
    path: "/administration/employees/access",
    key: "23",
  },
  {
    path: "/administration/users/access/list",
    key: "24",
  },
];

export default pathLocations;

import { Pagination, Typography, Input } from "antd";
import { useState, useEffect } from "react";
import EmployeeCard from "../../Components/EmployeeCard";
import axios from "../../Utils/axios";

const { Search } = Input;

const Trombinoscope = () => {
  const [employees, setEmployees] = useState([]);
  const [current, setCurrent] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchedValue, setSearchedValue] = useState("");

  const onChange = (page) => {
    setCurrent(page);
  };

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(
        `/api/employees/ids/list?perPage=9&page=${current}&name=${searchedValue}`
      );
      setEmployees(data?.employees);
      setTotalPages(data?.pages);
      return data;
    }

    fetchData();
  }, [current, searchedValue]);

  const onSearch = (value) => {
    setSearchedValue(value);
  };

  return (
    <div className="pt-4 px-3">
      <div className="bg-white p-4 rounded-4 shadow-sm  ">
        <div className=" mb-3 ">
          <h4>Trombinoscope de l'équipe</h4>
        </div>
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            expandable: true,
            symbol: "Plus",
          }}
          style={{ whiteSpace: "pre-wrap" }}
          className="pb-3"
        >
          Bienvenue sur la page de trombinoscope de notre équipe ! Cette section
          est dédiée à la présentation de chaque membre de notre équipe, afin
          que vous puissiez mieux nous connaître !
        </Typography.Paragraph>
        <div className="mt-3">
          <Search
            placeholder="Nom collaborateur...."
            onSearch={onSearch}
            allowClear
            enterButton
            style={{
              width: 270,
            }}
          />
        </div>
        <div className="row mt-2">
          {employees?.map((employee) => {
            return (
              <div
                className="col-lg-4 col-md-6 col-sm-12 my-2"
                key={employee?._id}
              >
                <EmployeeCard employee={employee} />
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-center mt-3">
          <Pagination
            current={current}
            defaultPageSize={9}
            onChange={onChange}
            total={9 * (totalPages - 1)}
          />
        </div>
      </div>
    </div>
  );
};

export default Trombinoscope;

import { useState, useEffect, useContext } from "react";

import {
  Button,
  Menu,
  Layout,
  Drawer,
  Typography,
  Tooltip,
  message,
  Avatar,
  Dropdown,
} from "antd";
import closeIcon from "../../../Assets/close.svg";
import eyeIcon from "../../../Assets/eye.svg";
import eyeHiddenIcon from "../../../Assets/eyehidden.svg";
import { useNavigate, Link, Outlet, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInbox,
  faCalendar,
  faArrowRightFromBracket,
  faFolderOpen,
  faBoxArchive,
  faBell,
  faComputer,
  faHouse,
  faBook,
  faGear,
  faFolder,
  faFolderTree,
  faFolderPlus,
  faQuestionCircle,
  faArrowsRotate,
  faUserGear,
  faFileContract,
  faCode,
  faKey,
  faPeopleGroup,
  faClipboardList,
  faChartLine,
  faClock,
  faFingerprint,
  faFileLines,
  faListCheck,
  faServer,
  faSitemap,
} from "@fortawesome/free-solid-svg-icons";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  FileAddOutlined,
} from "@ant-design/icons";

import moment from "moment";
import axios from "../../../Utils/axios";
import LocalStorageService from "../../../Utils/localStorageService";
import iconDark from "../../../Assets/brand_icon_dark.png";
import logoDark from "../../../Assets/brand_large_dark.png";
import GlobalContext from "../../../Context/GlobalContext";
import pathLocations from "../../../Constants/adminPathLocations";

const { Text } = Typography;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const { Header, Sider, Content } = Layout;

const LayoutAdmin = ({ role }) => {
  const {
    profile,
    setProfile,
    theme,
    handleRoleSwap,
    rolePrevilleges,
    contextHolderCollab,
  } = useContext(GlobalContext);
  const [autoNotif, setAutoNotif] = useState(
    JSON.parse(LocalStorageService().getNotif())
  );
  const location = useLocation();
  const [selectedLocation, setSelectedLocation] = useState("1");

  useEffect(() => {
    const temp = pathLocations.find((elem) => elem.path === location.pathname);
    setSelectedLocation(temp?.key);
  }, [location]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [Notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  const [visible, setVisible] = useState(false);

  // eslint-disable-next-line
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      if (windowSize.innerWidth < 992) {
        setCollapsed(true);
      }
    }
    window.addEventListener("resize", handleWindowResize);

    handleWindowResize();

    async function GetNotifications(autoNotif) {
      const { data } = await axios.get("/api/notif/");
      setNotifications(data.notifs);
      setAutoNotif(false);
      LocalStorageService().setNotif(false);
      return data;
    }

    GetNotifications(autoNotif);

    async function fetchProfilePhotoAndName() {
      const { data } = await axios.get("/api/employees/me/mini");
      setProfile(data);
      setLoading(false);
      return data;
    }

    fetchProfilePhotoAndName();
    // eslint-disable-next-line
  }, []);

  let Navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    Navigate("/login");
    window.location.reload();
  };

  const [collapsed, setCollapsed] = useState(false);

  const items = [
    profile?.sub_role !== profile?.role &&
      getItem(
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleRoleSwap(role);
          }}
        >
          {" "}
          Mode Collaborateur
        </div>,
        "RoleSwap",
        <FontAwesomeIcon icon={faArrowsRotate} />
      ),
    getItem(
      <Link to="/administration" style={{ textDecoration: "none" }}>
        Accueil
      </Link>,
      "1",
      <FontAwesomeIcon icon={faHouse} />
    ),
    getItem(
      <div>Collaborateurs</div>,
      "2",
      <FontAwesomeIcon icon={faPeopleGroup} />,
      [
        rolePrevilleges?.previllegesList?.find(
          (elem) => elem?.code === "CONSULT_USERS"
        ) &&
          getItem(
            <Link
              to="/administration/employees/list"
              style={{ textDecoration: "none" }}
            >
              Gestion Collaborateurs
            </Link>,
            "22",
            <FontAwesomeIcon icon={faPeopleGroup} />
          ),
        rolePrevilleges?.previllegesList?.find(
          (elem) => elem?.code === "UPDATE_ROLE_PREVILLEGES"
        ) &&
          getItem(
            <Link
              to="/administration/employees/access"
              style={{ textDecoration: "none" }}
            >
              Gestion des Rôles
            </Link>,
            "23",
            <FontAwesomeIcon icon={faUserGear} />
          ),
        rolePrevilleges?.previllegesList?.find(
          (elem) => elem?.code === "CONSULT_USER_ACCESS"
        ) &&
          getItem(
            <Link
              to="/administration/users/access/list"
              style={{ textDecoration: "none" }}
            >
              Gestion des Accès
            </Link>,
            "24",
            <FontAwesomeIcon icon={faKey} />
          ),

        rolePrevilleges?.previllegesList?.find(
          (elem) => elem?.code === "CONTRACT_MANAGEMENT"
        ) &&
          getItem(
            <Link
              to="/administration/employees/contracts"
              style={{ textDecoration: "none" }}
            >
              Gestion des Contrats
            </Link>,
            "25",
            <FontAwesomeIcon icon={faFileContract} />
          ),
        rolePrevilleges?.previllegesList?.find(
          (elem) => elem?.code === "SKILL_GRID_MANAGEMENT"
        ) &&
          getItem(
            <Link
              to="/administration/employees/skills"
              style={{ textDecoration: "none" }}
            >
              Grille des Compétences
            </Link>,
            "26",
            <FontAwesomeIcon icon={faCode} />
          ),
        rolePrevilleges?.previllegesList?.find(
          (elem) => elem?.code === "KPIS_MANAGEMENT"
        ) &&
          getItem(
            <Link
              to="/administration/employees/kpis"
              style={{ textDecoration: "none" }}
            >
              Gestion des KPIS
            </Link>,
            "27",
            <FontAwesomeIcon icon={faChartLine} />
          ),
      ]
    ),
    role === "SYS_ADMIN" &&
      getItem("Configuration", "Conf", <FontAwesomeIcon icon={faGear} />, [
        getItem(
          <Link
            to="/administration/config/servers"
            style={{ textDecoration: "none" }}
          >
            Gestion des serveurs
          </Link>,
          "Conf-01",
          <FontAwesomeIcon icon={faServer} />
        ),
        getItem(
          <Link
            to="/administration/config/services"
            style={{ textDecoration: "none" }}
          >
            Gestion des services
          </Link>,
          "Conf-02",
          <FontAwesomeIcon icon={faSitemap} />
        ),
      ]),
    (role === "ACCOUNTANT" || role === "SUPER_ADMIN") &&
      getItem(<div>Absence(s)</div>, "3", <FontAwesomeIcon icon={faBook} />, [
        rolePrevilleges?.previllegesList?.find(
          (elem) => elem?.code === "DECLARE_ABSENCE"
        ) &&
          getItem(
            <Link
              to="/administration/leaves/form"
              style={{ textDecoration: "none" }}
            >
              Déclarer une Absence
            </Link>,
            "32",
            <FileAddOutlined />
          ),
        rolePrevilleges?.previllegesList?.find(
          (elem) => elem?.code === "CONSULT_ABSENCES"
        ) &&
          getItem(
            <Link
              to="/administration/absences/list"
              style={{ textDecoration: "none" }}
            >
              Absences de Reporting
            </Link>,
            "35",
            <FontAwesomeIcon icon={faClipboardList} />
          ),
        rolePrevilleges?.previllegesList?.find(
          (elem) => elem?.code === "CONSULT_ABSENCES"
        ) &&
          getItem(
            <Link
              to="/administration/absences/list/others"
              style={{ textDecoration: "none" }}
            >
              Absences
            </Link>,
            "36",
            <FontAwesomeIcon icon={faClipboardList} />
          ),
      ]),
    rolePrevilleges?.previllegesList?.find(
      (elem) => elem?.code === "CONSULT_MONTHLY_REGISTER"
    ) &&
      getItem(
        <Link
          to="/administration/leaves/list"
          style={{ textDecoration: "none" }}
        >
          Registre mensuel
        </Link>,
        "31",
        <FontAwesomeIcon icon={faFolderOpen} />
      ),
    rolePrevilleges?.previllegesList?.find(
      (elem) => elem?.code === "SHARE_DOCUMENTS"
    ) &&
      getItem(
        <div>Documents partagés</div>,
        "15",
        <FontAwesomeIcon icon={faFolder} />,
        [
          getItem(
            <Link
              to="/administration/documents/form"
              style={{ textDecoration: "none" }}
            >
              Ajouter
            </Link>,
            "152",
            <FontAwesomeIcon icon={faFolderPlus} />
          ),

          getItem(
            <Link
              to="/administration/documents/list"
              style={{ textDecoration: "none" }}
            >
              Liste
            </Link>,
            "151",
            <FontAwesomeIcon icon={faFolderTree} />
          ),
        ]
      ),

    getItem(
      <Link
        to="/administration/equipments/list"
        style={{ textDecoration: "none" }}
      >
        Équipements
      </Link>,
      "10",
      <FontAwesomeIcon icon={faComputer} />
    ),
    rolePrevilleges?.previllegesList?.find(
      (elem) => elem?.code === "CONSULT_REQUESTS"
    ) &&
      getItem(
        <div>Demandes</div>,
        "4",
        <FontAwesomeIcon icon={faFileLines} />,
        [
          getItem(
            <Link
              to="/administration/requests"
              style={{ textDecoration: "none" }}
            >
              Demandes en cours
            </Link>,
            "444",
            <FontAwesomeIcon icon={faClock} />
          ),
          rolePrevilleges?.previllegesList?.find(
            (elem) => elem?.code === "CONSULT_REQUESTS"
          ) &&
            getItem(
              <Link
                to="/administration/requests/archive"
                style={{ textDecoration: "none" }}
              >
                Historiques
              </Link>,
              "454",
              <FontAwesomeIcon icon={faBoxArchive} />
            ),
        ]
      ),
    role === "SYS_ADMIN" &&
      getItem(
        <Link to="/administration/requests" style={{ textDecoration: "none" }}>
          Demandes en cours
        </Link>,
        "444",
        <FontAwesomeIcon icon={faInbox} />
      ),
    role === "SUPER_ADMIN" &&
      getItem(
        <Link to="/administration/holidays" style={{ textDecoration: "none" }}>
          Jours fériés
        </Link>,
        "5",
        <FontAwesomeIcon icon={faCalendar} />
      ),
    rolePrevilleges?.previllegesList?.find(
      (elem) => elem?.code === "CONSULT_ARCHIVE"
    ) &&
      getItem(
        // eslint-disable-next-line
        <Link to="/administration/logs" style={{ textDecoration: "none" }} s>
          Historiques
        </Link>,
        "6",
        <FontAwesomeIcon icon={faFingerprint} />
      ),

    rolePrevilleges?.previllegesList?.find(
      (elem) => elem?.code === "CONSULT_SCHEDULED_TASKS"
    ) &&
      getItem(
        <Link
          to="/administration/planned/tasks"
          style={{ textDecoration: "none" }}
        >
          Tâches planifiées
        </Link>,
        "499",
        <FontAwesomeIcon icon={faListCheck} />
      ),
    getItem(
      // eslint-disable-next-line
      <a href="#" onClick={showDrawer} style={{ textDecoration: "none" }}>
        Notifications
      </a>,
      "7",
      <FontAwesomeIcon icon={faBell} />
    ),
    rolePrevilleges?.previllegesList?.find(
      (elem) => elem?.code === "CONSULT_PARAMS"
    ) &&
      getItem(
        <Link style={{ textDecoration: "none" }} to="/administration/parametre">
          Paramètres
        </Link>,
        "9",
        <FontAwesomeIcon icon={faGear} />
      ),
    getItem(
      <Link style={{ textDecoration: "none" }} to="/administration/faq">
        FAQ
      </Link>,
      "99",
      <FontAwesomeIcon icon={faQuestionCircle} />
    ),
    getItem(
      // eslint-disable-next-line
      <a href="#" onClick={handleLogout} style={{ textDecoration: "none" }}>
        Se déconnecter
      </a>,
      "8",
      <FontAwesomeIcon icon={faArrowRightFromBracket} />
    ),
  ];

  const handleDisableNotif = async (id, status) => {
    try {
      const { data } = await axios.patch("/api/notif/read/" + id, {
        status: status,
      });
      if (data.status === "success") {
        const new_array = Notifications.map((notif) => {
          if (notif._id === id) {
            notif.read = status;
            return notif;
          } else {
            return notif;
          }
        });
        setNotifications(new_array);
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const handleDeleteNotif = async (id, status) => {
    try {
      const { data } = await axios.patch("/api/notif/deleted/" + id, {
        status: status,
      });
      if (data.status === "success") {
        let new_array = [];
        Notifications.forEach((notif) => {
          if (notif._id !== id) new_array.push(notif);
        });
        setNotifications(new_array);
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <div onClick={handleLogout}>
              <div style={{ cursor: "pointer" }}>
                <FontAwesomeIcon
                  icon={faArrowRightFromBracket}
                  style={{ marginRight: "3px" }}
                />{" "}
                Se deconnecter
              </div>
            </div>
          ),
        },
      ]}
    />
  );

  return loading ? (
    <div className="loading">
      <div className="dot-spinner">
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
      </div>
    </div>
  ) : (
    <Layout className="vh-100 vw-100 overflow-hidden">
      <Sider
        style={{ backgroundColor: theme.siderBg }}
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={270}
      >
        <div className="mx-2 my-2 text-center">
          {!collapsed ? (
            <img
              src={logoDark}
              style={{
                height: "45px",
                cursor: "pointer",
                transition: "width 0.2s ease",
              }}
              alt="Neopolis"
            />
          ) : (
            <img
              src={iconDark}
              style={{
                height: "30px",
                cursor: "pointer",
                transition: "width 0.2s ease",
              }}
              alt="Neopolis"
            />
          )}
        </div>
        <div style={{ height: "calc(100vh - 80px)" }}>
          <Menu
            selectedKeys={[selectedLocation]}
            className="h-100"
            mode="inline"
            style={{ overflowY: "scroll", backgroundColor: theme.siderBg }}
            inlineCollapsed={collapsed}
            items={items}
          />
        </div>
      </Sider>
      <Layout style={{ background: "#FFFFFF" }}>
        {contextHolderCollab}
        <Header
          style={{
            padding: 0,
            background: theme.headerBg,
          }}
          className="d-flex justify-content-between"
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
          {profile?.sub_role !== profile?.role && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginRight: "10px" }}
            >
              <div
                className="d-flex flex-column align-items-end mx-2"
                style={{ height: "64px", marginTop: "-16px" }}
              >
                <p
                  style={{
                    height: "20px",

                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  {profile?.fullName} #{profile?.matricule}
                </p>
                <p
                  style={{
                    height: "28px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  En tant que {" "}
                  {profile?.sub_role === "SUPER_ADMIN"
                    ? "resources humaines"
                    : profile?.sub_role === "SYS_ADMIN"
                    ? "sys admin"
                    : "comptabilité"}
                </p>
              </div>
              <Dropdown
                overlay={menu}
                placement="bottomRight"
                trigger={["click"]}
              >
                {profile?.imgProfile ? (
                  <Avatar
                    size={"large"}
                    src={profile?.imgProfile}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <Avatar
                    size={"large"}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "24px",
                    }}
                  >
                    {profile?.fullName[0]}
                  </Avatar>
                )}
              </Dropdown>
            </div>
          )}
        </Header>
        <Drawer
          title="Notifications"
          placement="right"
          onClose={onClose}
          visible={visible}
        >
          {Notifications.map((notif) => {
            return !notif.read ? (
              <div
                className="rounded d-flex mt-2"
                style={{
                  backgroundColor: "#F0FAFD",
                  paddingRight: "10px",
                  paddingBottom: "5px",
                }}
              >
                <div className="px-2 py-1">
                  <span style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon
                      icon={faBell}
                      style={{ color: "#2c5397" }}
                    />
                  </span>
                </div>
                <div className="d-flex justify-content-between w-100">
                  <div className="d-flex flex-column py-1">
                    <Text style={{ fontSize: "12px", fontWeight: "600" }}>
                      {notif.title}
                    </Text>
                    <Text style={{ fontSize: "12px", fontWeight: "400" }}>
                      {moment(notif.send_time_date).format(
                        "MMMM DD YYYY - hh:mm:ss"
                      )}
                    </Text>
                    <Text style={{ fontSize: "12px", fontWeight: "400" }}>
                      {notif.sender}
                    </Text>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <img
                      onClick={() => {
                        handleDeleteNotif(notif._id, true);
                      }}
                      src={closeIcon}
                      alt="close_icon"
                      style={{ cursor: "pointer" }}
                    />
                    <Tooltip
                      title="Cliquez pour désactiver"
                      placement="topRight"
                    >
                      <img
                        onClick={() => {
                          handleDisableNotif(notif._id, true);
                        }}
                        src={eyeHiddenIcon}
                        alt="hidden_icon"
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="rounded d-flex mt-2"
                style={{
                  backgroundColor: "#F0FAFD",
                  paddingRight: "10px",
                  paddingBottom: "7px",
                }}
              >
                <div className="px-2 py-1">
                  <span style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon
                      icon={faBell}
                      style={{ color: "#2c5397" }}
                    />
                  </span>
                </div>
                <div className="d-flex justify-content-between w-100">
                  <div className="d-flex flex-column py-1">
                    <Text
                      style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#90A9CB",
                      }}
                    >
                      {notif.title}
                    </Text>
                    <Text
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#90A9CB",
                      }}
                    >
                      {moment(notif.send_time_date).format(
                        "MMMM DD YYYY - hh:mm:ss"
                      )}
                    </Text>
                    <Text
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#90A9CB",
                      }}
                    >
                      {notif.sender}
                    </Text>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <img
                      onClick={() => {
                        handleDeleteNotif(notif._id, true);
                      }}
                      src={closeIcon}
                      alt="close_icon"
                      style={{ cursor: "pointer" }}
                    />

                    <img src={eyeIcon} alt="hidden_icon" />
                  </div>
                </div>
              </div>
            );
          })}
        </Drawer>
        <Content
          className="container-fluid h-100 pb-4 pt-4"
          style={{
            overflowY: "scroll",
            background: "#F4F5F7",
            borderTopLeftRadius: "20px",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutAdmin;

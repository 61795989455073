import { Button } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AccessTable from "../OwnLeaves/AccessTable";
import AccessRequestDrawer from "../../Components/LayoutClient/AccessRequestDrawer";

const AccessRequests = () => {
  const [refresh, setRefresh] = useState(false);
  const Navigate = useNavigate();
  return (
    <div className="pt-4">
      <div className="bg-white p-4 rounded shadow-sm ">
        <div className="mb-3 d-flex justify-content-between">
          <h4>📋 Historique de mes demandes d'accés :</h4>
          <Button type="primary" shape="round" onClick={()=>Navigate('/self/access/request')}>
        Nouvelle demande
      </Button>
          {/* <AccessRequestDrawer refresh={refresh} setRefresh={setRefresh} /> */}
        </div>
        <AccessTable refresh={refresh} />
      </div>
    </div>
  );
};

export default AccessRequests;

import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
    Button,
    DatePicker,
    Drawer,
    Form,
    Select,
    Space,
    Tabs,
    Timeline,
    message,
} from "antd";
import axiosInstance from "../../../Utils/axios";
import dayjs from "dayjs";

const ContractDrawer = ({ refresh, setRefresh, record, disabled }) => {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const items = [
        {
            key: "1",
            label: "Avec Période d'essai",
            children: (
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <Form.Item
                            label="Date de début de la période d'essai"
                            name="trailStartDate"
                        >
                            <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <Form.Item
                            label="Date de fin de la période d'essai"
                            name="trailEndDate"
                        >
                            <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
                        </Form.Item>
                    </div>
                </div>
            ),
        },
        {
            key: "2",
            label: "Sans Période d'essai",
        },
    ];





    const handleSave = async () => {
        form
            .validateFields()
            .then(async (res) => {
                try {
                    const contractData = {
                        contractType: res?.contractType,
                        matricule: record?.matricule,
                        fullName: record?.fullName,
                        civility: record?.civility,
                        embaucheDate: dayjs(res?.embaucheDate).toDate(),
                        endContractDate: res?.endContractDate
                            ? dayjs(res?.endContractDate).toDate()
                            : null,
                        trail: {
                            startDate: res?.trailStartDate
                                ? dayjs(res?.trailStartDate).toDate()
                                : null,
                            endDate: res?.trailEndDate
                                ? dayjs(res?.trailEndDate).toDate()
                                : null,
                        },
                        CIN: record?.CIN,
                        Poste: record?.Poste,
                        status: "onHold",
                    };

                    const { data } = await axiosInstance.patch(
                        "/api/employees/invited/user/hr",
                        contractData
                    );

                    if (data.status === "success") {
                        message.success("Le contrat a été ajouté avec succès.")
                        onClose();
                        setRefresh(!refresh)
                        form.resetFields();
                    }
                } catch (error) {
                    message.error(error?.response?.data?.message);
                }
            })
            .catch((error) => {
                return error;
            });
    };

    return (
        <>
            <Button
                type="primary"
                disabled={disabled}
                onClick={showDrawer}
                size="small"
            >
                Contract
            </Button>
            <Drawer
                title="Nouveau contrat"
                width={720}
                onClose={onClose}
                open={open}
                bodyStyle={{
                    paddingBottom: 80,
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Annuler</Button>
                        <Button onClick={handleSave} type="primary">
                            Soumettre
                        </Button>
                    </Space>
                }
            >
                <Form form={form} layout="vertical">
                    <Timeline>
                        <Timeline.Item>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <Form.Item
                                        label="Type de contrat"
                                        name="contractType"
                                        rules={[
                                            { required: true, message: "Merci de remplir le champ" },
                                        ]}
                                    >
                                        <Select placeholder="Type de contract (CDI, CIVP 1, CIVP 2, KARAMA, CDD)">
                                            <Select.Option key="1" value="CDI">
                                                CDI
                                            </Select.Option>
                                            <Select.Option key="2" value="CIVP 1">
                                                CIVP 1
                                            </Select.Option>
                                            <Select.Option key="22" value="CIVP 2">
                                                CIVP 2
                                            </Select.Option>
                                            <Select.Option key="3" value="KARAMA">
                                                KARAMA
                                            </Select.Option>
                                            <Select.Option key="4" value="CDD">
                                                CDD
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Timeline.Item>

                        <Timeline.Item>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <Form.Item
                                        label="Date d'embauche"
                                        name="embaucheDate"
                                        rules={[
                                            { required: true, message: "Merci de remplir le champ" },
                                        ]}
                                    >
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            format={"DD/MM/YYYY"}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <Form.Item
                                        label="Date de fin du contrat"
                                        name="endContractDate"
                                    >
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            format={"DD/MM/YYYY"}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </Timeline.Item>
                        <Timeline.Item>
                            <div>
                                <Tabs defaultActiveKey="1" items={items} />
                            </div>
                        </Timeline.Item>
                    </Timeline>
                </Form>
            </Drawer >
        </>
    );
};
export default ContractDrawer;

import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import lostInSpace from "../../Assets/404NotFound.svg";
import LocalStorageService from "../../Utils/localStorageService";

const ErrorPage = () => {
  const token = LocalStorageService().getAccessToken();

  const Navigate = useNavigate();

  const handleNavigate = () => {
    if (token) {
      Navigate("/");
    } else {
      Navigate("/login");
    }
  };

  return (
    <div className="vh-100 errorPageBackground">
      <div className="d-flex flex-column mx-auto justify-content-center h-100 align-items-center col-lg-4 col-md-6 col-sm-12">
        <h1 className="h1 text-uppercase">Page introuvable!</h1>
        <img src={lostInSpace} alt="LostInSpace" className="w-100" />
        <Button size="large" onClick={handleNavigate}>
          Retour à l'accueil
        </Button>
      </div>
    </div>
  );
};

export default ErrorPage;

import React, { useEffect, useState } from "react";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  ConfigProvider,
  Form,
  Input,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../Utils/axios";

const SkillFormList = () => {
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get(
          "/api/factory/skills/categories"
        );
        setCategories(data?.categories);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }

    fetchData();
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Form: {
            marginLG: 0,
          },
        },
      }}
    >
      {categories === null ? (
        <div className="d-flex justify-content-center">
          <Spin />
        </div>
      ) : (
        <Form.List name="skills">
          {(fields, { add, remove }) => (
            <div className="row container">
              {fields.map(({ key, name, ...restField }) => (
                <div className="col-4 px-2 py-2 ">
                  <div
                    className="px-2 rounded-3"
                    style={{
                      backgroundColor: "#E6F4FF",
                      color: "#1A64DC",
                      border: "1px solid #91CAFF",
                      fontSize: "14px",
                      padding: "3px 9px",
                    }}
                  >
                    <Space.Compact className="w-100 ">
                      <Form.Item
                        {...restField}
                        name={[name, "label"]}
                        className="w-100"
                      >
                        <Input
                          className="w-100"
                          placeholder="Compétence"
                          bordered={false}
                          size="small"
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "category"]}
                        className="w-100"
                      >
                        <Select
                          className="w-100"
                          placeholder="Catégorie"
                          bordered={false}
                          size="small"
                        >
                          {categories.map((elem) => {
                            return (
                              <Select.Option value={elem}>{elem}</Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>

                      <MinusCircleOutlined
                        onClick={() => remove(name)}
                        style={{ color: "red" }}
                      />
                    </Space.Compact>
                  </div>
                </div>
              ))}

              <Form.Item>
                <Space>
                  <Button
                    className="fw-bold"
                    type="primary"
                    shape="round"
                    onClick={() => add()}
                    icon={<PlusCircleOutlined />}
                  >
                    Ajouter une Compétance
                  </Button>
                  <Button
                    className="fw-bold"
                    htmlType="submit"
                    type="primary"
                    shape="round"
                    icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                  >
                    Sauvgarder
                  </Button>
                </Space>
              </Form.Item>
            </div>
          )}
        </Form.List>
      )}
    </ConfigProvider>
  );
};

export default SkillFormList;

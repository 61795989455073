import { Button, Form, Input, Modal, Select, Tooltip } from "antd";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faHeadphones,
  faComputerMouse,
  faPlug,
  faKeyboard,
  faLaptop,
  faDesktop,
  faMobile,
  faSimCard,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";

const { Option } = Select;

const EditModalEquipment = ({ record }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ ...record });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Tooltip title="Modifier l'équipement">
        <Button size="small" shape="circle" onClick={showModal}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </Button>
      </Tooltip>
      <Modal
        title="Modifier l'équipement"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 20 }}>
          <Form.Item name="_categorie" label="Designation">
            <Select placeholder="Choisir une designation">
              <Option value="ECRAN">
                Ecran <FontAwesomeIcon icon={faDesktop} />
              </Option>
              <Option value="PC">
                PC <FontAwesomeIcon icon={faLaptop} />
              </Option>
              <Option value="CHARGEUR">
                CHARGEUR <FontAwesomeIcon icon={faPlug} />
              </Option>
              <Option value="SOURIS">
                SOURIS <FontAwesomeIcon icon={faComputerMouse} />
              </Option>
              <Option value="CLAVIER">
                CLAVIER <FontAwesomeIcon icon={faKeyboard} />
              </Option>
              <Option value="CABLE">
                CABLE <FontAwesomeIcon icon={faPlug} />
              </Option>
              <Option value="CASQUE">
                CASQUE <FontAwesomeIcon icon={faHeadphones} />
              </Option>
              <Option value="TELEPHONE_PORTABLE">
                TELEPHONE_PORTABLE <FontAwesomeIcon icon={faMobile} />
              </Option>
              <Option value="LIGNE_TELEPHONIQUE">
                LIGNE_TELEPHONIQUE <FontAwesomeIcon icon={faSimCard} />
              </Option>
            </Select>
          </Form.Item>
          <Form.Item name="_ref" label="N°Serie">
            <Input placeholder="Réference" />
          </Form.Item>
          <Form.Item name="_brand" label="Marque">
            <Input placeholder="Mark (HP/DELL/SAMSUNG)" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default EditModalEquipment;

import React, { useEffect, useState } from "react";
import { Button, Drawer, Space, Descriptions } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";


const AccessDrawer = ({ record }) => {
  const [open, setOpen] = useState(false);
  const [desc, setDesc] = useState({});

  const showLargeDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const temp = {
      user: [
        {
          key: "1",
          label: "Nom et prénom",
          children: record?.userFullName,
          span: 1,
        },
        {
          key: "2",
          label: "Email Pro",
          children: record?.userEmail,
          span: 2,
        },
        {
          key: "3",
          label: "Poste",
          children: record?.userPoste,
          span: 2,
        },
        {
          key: "4",
          label: "Role",
          children: record?.userRole,
          span: 2,
        },
        {
          key: "5",
          label: "Matricule",
          children: record?.userMatricule,
          span: 2,
        },
      ],
      access: [
        {
          key: "1",
          label: "Login",
          children: record?.userName,
          span: 1,
        },
        {
          key: "2",
          label: "Email",
          children: record?.email,
          span: 2,
        },
        {
          key: "2",
          label: "Url",
          children: record?.url,
          span: 3,
        },
      ],
      service: [
        {
          key: "1",
          label: "Nom",
          children: record?.service?.name,
          span: 2,
        },
        {
          key: "2",
          label: "Host Name",
          children: record?.server?.hostName,
          span: 2,
        },
      ],
    };

    setDesc(temp);
  }, [record]);
  return (
    <>
      <Button size="small" shape="circle" onClick={showLargeDrawer}>
        <FontAwesomeIcon icon={faEye} />
      </Button>

      <Drawer
        title={`Détails de l'accés de ${record?.userFullName} au service ${record?.service?.name}`}
        placement="right"
        size={"large"}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button type="primary" onClick={onClose}>
              OK
            </Button>
          </Space>
        }
      >
        <div>
        <div className="border-1 border rounded-4 px-4 py-2 my-2">
            <Descriptions
              size="small"
              title="Informations du service"
              layout="vertical"
              items={desc?.service}
            />
          </div>
          <div className="border-1 border rounded-4 px-4 py-2 my-2">
            <Descriptions
              size="small"
              title="Informations de l'accés"
              layout="vertical"
              items={desc?.access}
            />
          </div>
          <div className="border-1 border rounded-4 px-4 py-2 my-2">
            <Descriptions
              size="small"
              title="Informations de l'utilisateur"
              layout="vertical"
              items={desc?.user}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
};
export default AccessDrawer;

import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Space,
  Tooltip,
  Input,
  message,
  ConfigProvider,
} from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../../Utils/axios";

const EditAccessDrawer = ({ record, index, setRefresh, refresh }) => {
  const [service, setService] = useState({});
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  const showDrawer = async () => {
    try {
      const { data } = await axiosInstance.get(
        "/api/administration/service/get/" + JSON.parse(record?.json)?.typeId
      );
      const response = await axiosInstance.get(
        "/api/auth/mini/" + record?.sender_id
      );
      setService(data?.data?.service);
      setOpen(true);

      const temp = data?.data?.service?.server?.map((elem) => {
        return {
          email: response?.data?.data?.email,
          url: elem?.url,
          password: "",
          userName: "",
          hostName: elem?._id?.hostName,
          server: elem?._id?._id,
          service: data?.data?.service?._id,
          userId: response?.data?.data?._id,
        };
      });
      form.setFieldValue("access", temp);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleFinish = () => {
    form.submit();
    form.validateFields().then(async (values) => {
      axiosInstance
        .post("/api/administration/access/create", values)
        .then((res) => {
          message.success("L'accès a été enregistré avec succès.");
          axiosInstance
            .patch(`/api/request/access/sysadmin/${record?._id}`, {
              status: true,
              motif: "N/A",
              ...values,
            })
            .then((response) => {
              message.success(
                "La demande a été validée et le demandeur a été averti."
              );
              setRefresh(!refresh);
              onClose();
            })
            .catch((error) => message.error(error?.response?.data?.message));
        })
        .catch((error) => message.error(error?.response?.data?.message));
    });
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#52C41A",
          },
        }}
      >
        <Tooltip title="Approuver la demande">
          <Button
            size="small"
            type="primary"
            shape="circle"
            disabled={
              record?.answered_by.length !== index ||
              record?.status !== "PENDING"
            }
            onClick={showDrawer}
          >
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </Tooltip>
      </ConfigProvider>
      <Drawer
        title="Modifier l'accès"
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Annuler</Button>
            <Button onClick={showDrawer}>Reset</Button>
            <Button type="primary" onClick={handleFinish}>
              Modifier
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" form={form}>
          <h4>Service: {service?.name}</h4>

          <Form.List name="access">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div className="row border rounded-3 my-2 p-3">
                    <h6>
                      HOSTNAME: {form.getFieldValue("access")[name]?.hostName}
                    </h6>
                    <Form.Item
                      {...restField}
                      name={[name, "userName"]}
                      className="col-6"
                      label="User Name"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className="col-6"
                      {...restField}
                      name={[name, "email"]}
                      label="Email"
                    >
                      <Input type="email" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "password"]}
                      label="Password"
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, "url"]} label="URL">
                      <Input />
                    </Form.Item>
                    <div>
                      <Button onClick={() => remove(name)}>Annuler</Button>
                    </div>
                  </div>
                ))}
              </>
            )}
          </Form.List>
        </Form>
      </Drawer>
    </>
  );
};

export default EditAccessDrawer;

import React from "react";

import OtherRequestsTable from "../../Components/OtherRequestsTable";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const OtherRequests = () => {
  const Navigate = useNavigate();
  return (
    <div className="pt-4">
      <div className="bg-white p-4 rounded shadow-sm ">
        <div className="mb-3 d-flex justify-content-between">
          <h4>Autre(s) demande(s)</h4>
          {/* <Button
            shape="round"
            onClick={() => Navigate("/request")}
            type="primary"
          >
            Nouvelle demande
          </Button> */}
        </div>
        <OtherRequestsTable size={"default"} />
      </div>
    </div>
  );
};

export default OtherRequests;

import React, { useContext, useEffect, useState } from "react";
import {
  Button,

  Descriptions,

  Modal,

  Tag,
  Tooltip
} from "antd";

import axiosInstance from "../../../Utils/axios";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const AccessRequestDrawer = ({ record }) => {
const [open, setOpen] = useState(false);
  const [data, setData] = useState();


  const showDrawer = () => {
    axiosInstance.get(`/api/request/byId/${record?._id}`).then((res) => {
      setData(res?.data?.request);
    });
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
    <Tooltip title="Voir les détails">
        <Button size="small" shape="circle" onClick={showDrawer}>
          <FontAwesomeIcon icon={faEye} />
        </Button>
      </Tooltip>

      <Modal open={open} onCancel={onClose} onOk={onClose} width={1000}>
        <div className="mx-3">
          <Descriptions bordered title="Détails de la demande">
            {data?.sender && (
              <Descriptions.Item span={3} label={"Demandeur"}>
                {data?.sender}
              </Descriptions.Item>
            )}

            {data?.request && (
              <Descriptions.Item span={3} label={"Type d'accés demandé"}>
                {data?.request}
              </Descriptions.Item>
            )}
            {data?.send_time_date && (
              <Descriptions.Item span={3} label={"Date de soumission"}>
                {dayjs(data?.send_time_date).format("DD/MM/YYYY")}
              </Descriptions.Item>
            )}

            <Descriptions.Item
              span={4}
              label="Motif"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {data?.motif}
            </Descriptions.Item>
            {data?.json && JSON.parse(data?.json)._description && (
              <Descriptions.Item
                span={4}
                label="Description"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {JSON.parse(data?.json)._description}
              </Descriptions.Item>
            )}
            <Descriptions.Item
              span={4}
              label="Statut"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {data?.status === "PENDING" && (
                <Tag color="orange">En attente</Tag>
              )}
              {data?.status === "APPROVED" && <Tag color="green">Acceptée</Tag>}
              {data?.status === "REJECTED" && <Tag color="red">Rejetée</Tag>}
              {data?.status === "CANCELLED" && <Tag color="red">Annulée</Tag>}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </Modal>

      {/* <Drawer
      title={record?.request}
      placement="right"
      onClose={onClose}
      open={open}
      size={"large"}
    >
      <Descriptions layout="vertical">
        {record?.sender && (
          <Descriptions.Item span={3} label={"Nom et prénom"}>
            {record?.sender}
          </Descriptions.Item>
        )}

        {/* <Descriptions.Item span={2} label="Date de début">
          {dayjs(record?.BeginingDate).format("DD/MM/YYYY")}
        </Descriptions.Item> */}
      {/* <Descriptions.Item span={2} label="Date de fin">
          {dayjs(record?.EndingDate).format("DD/MM/YYYY")}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Date de soumission">
          {dayjs(record?._posted_date).format("DD/MM/YYYY HH:mm")}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Durée">
          {record?.DaysNumber + " Jour(s)"}{" "}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Description">
          {JSON.parse(record?.json)?._description
            ? JSON.parse(record?.json)?._description
            : "N/A"}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Documents">
          <Space direction={"vertical"}>
            {record?._docs?.length === 0 && "N/A"}
            {record?._docs?.map((elem) => {
              return (
                <a
                  href={`${process.env.REACT_APP_API_URL}/api/files/${elem}`}
                  download={elem.substring(29)}
                  target="_blank"
                  rel="noreferrer" // Add this line
                >
                  <Tag
                    color={"blue"}
                    style={{ fontSize: "12px", padding: "5px 10px" }}
                  >
                    <FontAwesomeIcon
                      icon={faFileArrowDown}
                      style={{ marginRight: "5px" }}
                    />
                    {elem.substring(29)}
                  </Tag>
                </a>
              );
            })}
          </Space>
        </Descriptions.Item>

        <Descriptions.Item
          span={4}
          label="Motif"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {record?.motif}
        </Descriptions.Item>
      </Descriptions>
    </Drawer> */}
    </>
  );
};
export default AccessRequestDrawer;

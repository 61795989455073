import { useState, useEffect, useRef, useContext } from "react";
import {
    Table,
    Typography,
    Space,
    Spin,
    Button,
    Input,
    Form,
    Modal,
    message,
    Tooltip,
    Dropdown,
    Steps,
} from "antd";

import {
    SearchOutlined,
    LoadingOutlined,
    CheckCircleTwoTone,
    MinusCircleTwoTone,
    ColumnHeightOutlined,
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";

import { useNavigate } from "react-router-dom";

import axios from "../../../Utils/axios";
import moment from "moment";

import GlobalContext from "../../../Context/GlobalContext";
import AccessDrawer from "./AccessDrawer";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InvitedUserDrawer from "../../../Drawers/InvitedUserDrawer";
import axiosInstance from "../../../Utils/axios";
import ContractDrawer from "./ContractDrawer";

const { Text } = Typography;

const InvitedUsers = () => {
    const [refresh, setRefresh] = useState(false);
    const { role } = useContext(GlobalContext);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const Navigate = useNavigate();
    const [size, setSize] = useState("small");
    const handleSizeChange = (e) => {
        setSize(e.target.value);
    };

    const items = [
        {
            key: "1",
            label: (
                <a
                    href="#"
                    className="text-decoration-none"
                    onClick={() => {
                        handleSizeChange("large");
                    }}
                >
                    Plus grand
                </a>
            ),
        },
        {
            key: "2",
            label: (
                <a
                    href="#"
                    className="text-decoration-none"
                    onClick={() => {
                        handleSizeChange("middle");
                    }}
                >
                    Moyenne
                </a>
            ),
        },
        {
            key: "3",
            label: (
                <a
                    href="#"
                    className="text-decoration-none"
                    onClick={() => {
                        handleSizeChange("small");
                    }}
                >
                    Compact
                </a>
            ),
        },
    ];

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Recherche ${title}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                    // style={{
                    //   width: 90,
                    // }}
                    >
                        Recherche
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Réinitialiser
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: "Matricule",
            dataIndex: "matricule",
            ...getColumnSearchProps("matricule", "Matricule"),
        },
        {
            title: "Nom et prénom",
            dataIndex: "fullName",
            ...getColumnSearchProps("fullName", "Nom et prénom"),
        },
        {
            title: "Date d'embauche",
            dataIndex: "embaucheDate",
            sorter: (a, b) => moment(a.embaucheDate) - moment(b.embaucheDate),
            render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
        },
        {
            title: "Statut",
            render: (_, record) => (
                <Steps
                    labelPlacement="vertical"
                    items={[
                        {
                            title: (
                                <Tooltip
                                    title="Remplissage des données cotées collaborateur invité."
                                    style={{ cursor: "pointer" }}
                                >
                                    Candidat Invité
                                </Tooltip>
                            ),
                            status: record?.validateSelf ? "finish" : "process",
                            icon: record?.validateSelf ? (
                                <CheckCircleTwoTone twoToneColor={"#87d068"} />
                            ) : (
                                <LoadingOutlined />
                            ),
                        },
                        {
                            title: (<Tooltip
                                title="Valider le contrat signé."
                                style={{ cursor: "pointer" }}
                            >
                                RH
                            </Tooltip>),
                            status: !record?.validateSelf
                                ? "wait"
                                : record?.validateContract
                                    ? "finish"
                                    : "process",
                            icon: !record?.validateSelf ? (
                                <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                            ) : record?.validateContract ? (
                                <CheckCircleTwoTone twoToneColor={"#87d068"} />
                            ) : (
                                <LoadingOutlined />
                            ),
                        },
                        {
                            title: (
                                <Tooltip
                                    title="Remplissage des données cotées Administrateur du système."
                                    style={{ cursor: "pointer" }}
                                >
                                    Sys-Admin
                                </Tooltip>
                            ),
                            status: !record?.validateContract
                                ? "wait"
                                : record?.validateSys
                                    ? "finish"
                                    : "process",
                            icon: !record?.validateContract ? (
                                <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                            ) : record?.validateSys ? (
                                <CheckCircleTwoTone twoToneColor={"#87d068"} />
                            ) : (
                                <LoadingOutlined />
                            ),
                        },
                    ]}
                    size="small"
                />
            ),
        },
        {
            title: "Actions",
            render: (_, record) => (
                <Space size="middle">
                    {role === "SUPER_ADMIN" && record?.validateSys && (<Button type="primary" size="small" onClick={() => {
                        handleActiveAccount(record?.matricule);
                    }}> Activer </Button>)}
                    <InvitedUserDrawer record={record} />
                    {role === "SYS_ADMIN" && (
                        <AccessDrawer
                            record={record}
                            disabled={record?.validateSys}
                            refresh={refresh}
                            setRefresh={setRefresh}
                        />
                    )}
                    {role === "SUPER_ADMIN" && (<Button size="small" danger type="primary" onClick={() => handleDeleteInvitation(record?.matricule)}>Annuler</Button>)}
                    {role === "SUPER_ADMIN" && (
                        <>{record?.validateSelf && !record?.validateHR ? <ContractDrawer
                            record={record}
                            disabled={record?.validateHR}
                            refresh={refresh}
                            setRefresh={setRefresh}
                        /> : <Button type="primary" disabled={!record?.validateHR || record?.validateContract} size="small" onClick={() => { handleValidateHRInvitation(record?.matricule) }}>Valider</Button>}
                        </>
                    )}
                </Space>
            ),
        },
    ];

    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleActiveAccount = async (id) => {
        try {
            const { data } = await axiosInstance.patch(`/api/employees/invited/user/activate/${id}`);
            if (data?.status === "success") {
                message.success("Compte activé avec succès.");
                setRefresh(!refresh);
            }
        } catch (error) {
            message.error(error?.response?.data?.message);
        }
    }

    useEffect(() => {
        async function fetchEmployees() {
            try {
                const { data } = await axios.get("/api/employees/invited/users");
                if (data.status === "success") {
                    setEmployees(data.employees);
                    setLoading(false);
                }
            } catch (error) {
                message.error(error?.response?.data?.message);
            }
        }
        fetchEmployees();
    }, [refresh]);

    const handleDeleteInvitation = (id) => {
        try {
            axios.post(`/api/employees/cancel/invitation/${id}`);


            message.success("Invitation annulée avec succès.");
            setRefresh(!refresh);

        } catch (error) {
            message.error(error?.response?.data?.message);
        }
    }

    const handleValidateHRInvitation = async (id) => {
        try {
            await axios.patch(`/api/employees/validate/invitation/${id}`);

            message.success("Contract validée avec succès.");
            setRefresh(!refresh);

        } catch (error) {
            message.error(error?.response?.data?.message);
        }
    }

    return (
        <div className="pt-4">
            {loading ? (
                <div className="d-flex justify-content-center">
                    <Spin size="large" />
                </div>
            ) : (
                <div className="bg-white p-4 rounded-4 shadow-sm ">
                    <div className="mb-3">
                        <h4>Liste des collaborateurs invités</h4>
                    </div>
                    <div className="d-flex justify-content-between flex-column flex-lg-row flex-md-row pb-3">
                        <Button shape="round" icon={<FontAwesomeIcon icon={faArrowLeft} />} onClick={() => Navigate("/administration/employees/list")}>
                            {"Retour"}
                        </Button>
                    </div>
                    <Table
                        title={() => (
                            <span className="fw-bold">
                                <span className="text-warning">Note : </span>
                                Les comptes seront disponibles (activés) à la date
                                d'embauche.
                            </span>
                        )}
                        columns={columns}
                        size={size}
                        dataSource={employees}
                        scroll={{ x: "max-content" }}
                    />
                </div>
            )}
        </div>
    );
};

export default InvitedUsers;

import React, { useState } from "react";
import EquipmentForm from "../../Components/EquipmentForm";
import EquipmentFormList from "../../Components/EquipmentFormList";
import { Button, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import UploadedEquipments from "./UploadedEquipments";

const EquipmentsManagment = () => {
  const [uploadedData, setUploadedData] = useState([]);
  const [loading, setLoading] = useState(false);

  const Navigate = useNavigate();

  const handleNavigate = () => {
    Navigate("/administration/equipments/list");
  }

  return (
    <div>
      <Button shape="round" onClick={handleNavigate} icon={<FontAwesomeIcon icon={faArrowLeft} />}>Liste des équipements
      </Button>
      <div className="row justify-content-between">
        <div className="col-lg-5 my-1">
          <EquipmentFormList uploadedData={uploadedData} setUploadedData={setUploadedData} loading={loading} setLoading={setLoading} />
          <br />
          <div className="bg-white my-3  p-4 rounded-2 shadow-sm">
            <div className="mb-3">
              <h5>Note</h5>
            </div>
            <div>
              <Typography.Paragraph>
                Si vous allez importer un document, assurez-vous que les
                designations sont toutes en majuscules et qu'il s'agit de l'une de
                celles-ci :
                <ul>
                  <li>ECRAN</li>
                  <li>PC</li>
                  <li>CHARGEUR</li>
                  <li>SOURIS</li>
                  <li>CLAVIER</li>
                  <li>CABLE</li>
                  <li>CASQUE</li>
                  <li>TELEPHONE_PORTABLE</li>
                  <li>LIGNE_TELEPHONIQUE</li>
                </ul>
              </Typography.Paragraph>
            </div>
          </div>
        </div>
        <div className="col-lg-7 my-1">
          {uploadedData?.length !== 0 && <UploadedEquipments uploadedData={uploadedData} setUploadedData={setUploadedData} loading={loading} setLoading={setLoading} />}
          <EquipmentForm />
        </div>
      </div>
    </div>
  );
};

export default EquipmentsManagment;

import { Switch } from "antd";
import React from "react";

const Filters = ({ filters, setFilters }) => {
  const onChange = (checked, value) => {
    if (checked) {
      setFilters([...filters, ...value]);
    } else {
      const temp = filters.filter((elem) => !value?.includes(elem));
      setFilters(temp);
    }
  };

  return (
    <div className="bg-white p-3 mt-3 shadow-sm rounded-3">
      <h1 style={{ fontSize: "12px", fontWeight: "500" }}>Filtrer par </h1>
      <div className="d-flex flex-column">
        <div
          style={{
            borderLeft: "2px solid #1ECB44",
            paddingLeft: "10px",
            marginBottom: "7px",
            fontWeight: "400",
          }}
          className="d-flex justify-content-between"
        >
          <span>Congés</span>
          <Switch
            onChange={(e) => onChange(e, ["Congé Maladie",
              "Congé sans solde",
              "Congé Special",
              "Congé annuel",
              "Congé demi-journée",])}
            size="small"
            defaultChecked
          />
        </div>

        <div
          style={{
            borderLeft: "2px solid #8338EC",
            paddingLeft: "10px",
            marginBottom: "7px",
            fontWeight: "400",
          }}
          className="d-flex justify-content-between"
        >
          <span>Autorisations de sortie</span>

          <Switch
            onChange={(e) => onChange(e, ["Autorisation"])}
            size="small"
            defaultChecked
          />
        </div>


      </div>
    </div>
  );
};

export default Filters;

import React, { useContext, useEffect, useState } from "react";
import { Table, Typography } from "antd";
import axios from "../../../Utils/axios";
import GlobalContext from "../../../Context/GlobalContext";
import dayjs from "dayjs";

const Equipments = ({}) => {
  const { equipments, setEquipments } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      title: "Date d'affectation",
      render: (_, record) => (
        <Typography.Text>
          {dayjs(record?._time_line[0]?._start_date).format("DD/MM/YYYY")}
        </Typography.Text>
      ),
    },
    {
      title: "Designation",
      dataIndex: "_categorie",
      key: "Référence",
    },
    {
      title: "Marque",
      dataIndex: "_brand",
      key: "Marque",
    },
    {
      title: "N°Serie",
      dataIndex: "_ref",
      key: "Catégorie",
    },
  ];

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(`/api/equipments/me`);

      setEquipments(data?.equipments);
      setLoading(false);
    }

    fetchData();
  }, []);

  return (
    <div className="py-3">
      <div className="bg-white rounded-3 py-2" style={{ minHeight: "80vh" }}>
        <div style={{ maxHeight: "82vh", overflowY: "scroll" }}>
          <div className="container py-3">
            <h4>💻 Mes équipements :</h4>
            <Table
              columns={columns}
              loading={loading}
              dataSource={equipments}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Equipments;

import React, { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button, Collapse, Drawer, Select, Space, message } from "antd";
import axiosInstance from "../../../../Utils/axios";

const { Panel } = Collapse;

const AccessDrawer = ({ roles, record, setEmployees, employees }) => {
  const [open, setOpen] = useState(false);
  const [primaryRole, setPrimaryRole] = useState(
    roles?.find((elem) => elem?.code === record?.role)
      ? roles?.find((elem) => elem?.code === record?.role)
      : roles?.find((elem) => elem?.code === "EMPLOYEE")
  );
  const [secondairyRole, setSecondairyRole] = useState(
    roles?.find((elem) => elem?.code === record?.sub_role)
      ? roles?.find((elem) => elem?.code === record?.sub_role)
      : roles?.find((elem) => elem?.code === "EMPLOYEE")
  );
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const handleEditRoles = async () => {
    try {
      let tempData = record;
      tempData.role = primaryRole?.code;
      tempData.sub_role = secondairyRole?.code;
      const payload = {
        primaryRole: primaryRole?.code,
        secondRole: secondairyRole?.code
          ? secondairyRole?.code
          : primaryRole?.code,
      };
      const { data } = await axiosInstance.patch(
        "/api/auth/update/roles/" + record?.matricule,
        payload
      );
      const temp = employees?.filter((elem) => {
        if (elem?._id !== record?._id) {
          return elem;
        } else {
          return tempData;
        }
      });
      if (data?.status === "success") {
        message.success(data?.message);
        setEmployees(temp);
        onClose();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Button
        shape="round"
        type="primary"
        size={"small"}
        onClick={showDrawer}
        icon={<EditOutlined />}
      >
        Modifier
      </Button>
      <Drawer
        title={
          "Modifier l'accès pour " + record?.fullName + " #" + record?.matricule
        }
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Annuler</Button>
            <Button onClick={handleEditRoles} type="primary">
              Modifier
            </Button>
          </Space>
        }
      >
        <div className="py-2 border-bottom">
          <label>Rôle principal:</label>
          <Select
            disabled
            onSelect={(e) => {
              const temp = roles?.find((elem) => elem?.code === e);
              setPrimaryRole(temp);
            }}
            defaultValue={record?.role}
            style={{ width: "100%" }}
          >
            {roles?.map((elem) => {
              return (
                <Select.Option value={elem?.code}>{elem?.name}</Select.Option>
              );
            })}
          </Select>
        </div>
        <div className="py-2 border-bottom">
          <label>Rôle secondaire:</label>
          <Select
            onSelect={(e) => {
              const temp = roles?.find((elem) => elem?.code === e);
              setSecondairyRole(temp);
            }}
            defaultValue={record?.sub_role}
            style={{ width: "100%" }}
          >
            {roles?.map((elem) => {
              return (
                <Select.Option value={elem?.code}>{elem?.name}</Select.Option>
              );
            })}
          </Select>
        </div>
        <div>
          <Collapse
            ghost
            expandIconPosition={"end"}
            defaultActiveKey={["1", "2"]}
          >
            <Panel
              header="Previllege Rôle principal:"
              className="border-bottom rounded-0"
              key="1"
              style={{ maxHeight: 250, overflowY: "scroll" }}
            >
              <ul>
                {primaryRole?.previllegesList?.map((elem) => {
                  return (
                    <li>
                      <div
                        style={{ fontSize: "14px", fontWeight: "400" }}
                        className="d-flex flex-column"
                      >
                        <span>{elem?.code}</span>
                        <span
                          className="text-muted"
                          style={{ fontStyle: "italic" }}
                        >
                          {elem?.description}
                        </span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </Panel>
            <Panel
              header="Previllege Rôle secondaire:"
              className="border-bottom rounded-0"
              key="2"
              style={{ maxHeight: 250, overflowY: "scroll" }}
            >
              <ul>
                {secondairyRole?.previllegesList?.map((elem) => {
                  return (
                    <li>
                      <div
                        style={{ fontSize: "14px", fontWeight: "400" }}
                        className="d-flex flex-column"
                      >
                        <span>{elem?.code}</span>
                        <span
                          className="text-muted"
                          style={{ fontStyle: "italic" }}
                        >
                          {elem?.description}
                        </span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </Panel>
          </Collapse>
        </div>
      </Drawer>
    </>
  );
};
export default AccessDrawer;

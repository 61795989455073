import { useState, useEffect, useContext } from "react";
import {
  Drawer,
  Avatar,
  Dropdown,
  Menu,
  Typography,
  Tooltip,
  Badge,
  Button,
  Layout,
  message,
} from "antd";

import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import iconDark from "../../Assets/brand_icon_dark.png";

import logoDark from "../../Assets/brand_large_dark.png";

import closeIcon from "../../Assets/close.svg";
import eyeIcon from "../../Assets/eye.svg";
import eyeHiddenIcon from "../../Assets/eyehidden.svg";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

import axios from "../../Utils/axios";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faUser,
  faHouse,
  faBell,
  faCalendarXmark,
  faLaptopFile,
  faPeopleLine,
  faFileLines,
  faToolbox,
  faPeopleGroup,
  faQuestionCircle,
  faCalendarDays,
  faArrowsRotate,
  faKey,
  faHouseLaptop,
  faBoxArchive,
  faClock,
  faFileSignature,
  faIdBadge,
  faFolder,
  faComputer,
  faCode,
} from "@fortawesome/free-solid-svg-icons";

import LocalStorageService from "../../Utils/localStorageService";
import GlobalContext from "../../Context/GlobalContext";

import pathLocations from "../../Constants/clientPathLocations";
import dayjs from "dayjs";

const { Text } = Typography;
const { Header, Sider, Content } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const LayoutClient = () => {
  const location = useLocation();
  const {
    profile,
    setProfile,
    theme,
    handleRoleSwap,
    role,
    apiCollab,
    contextHolderCollab,
    stats,
    setStats,
  } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [isTaskedToDo, setIsTaskedTodo] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("1");

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    async function getStats() {
      const { data } = await axios.get("/api/platform/employee");
      setStats(data);
      return data;
    }

    async function verifyTodo() {
      const { data } = await axios.get("api/todo/me");
      if (data.todos.length > 0) {
        debugger;
        setIsTaskedTodo(true);
        apiCollab["info"]({
          message: "Vous avez des tâches à faire",
          description: `Veuillez noter que vous avez ${
            data.todos.length
          } tâche(s) à faire en tant que ${
            profile?.sub_role === "SUPER_ADMIN"
              ? "resources humaines"
              : profile?.sub_role === "SYS_ADMIN"
              ? "sys admin"
              : "comptabilité"
          }`,
        });
      }
    }
    if (profile) {
      verifyTodo();
      getStats();
    }
  }, [profile]);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const temp = pathLocations.find((elem) => elem.path === location.pathname);

    setSelectedLocation(temp?.key);
  }, [location]);

  const items = [
    profile?.sub_role &&
      profile?.sub_role !== "EMPLOYEE" &&
      profile?.sub_role !== "TEAM_LEAD" &&
      getItem(
        <div style={{ cursor: "pointer" }} onClick={() => handleRoleSwap(role)}>
          Mode{" "}
          {profile?.sub_role === "SUPER_ADMIN"
            ? "Resources Humaines"
            : profile?.sub_role === "SYS_ADMIN"
            ? "Sys Admin"
            : "Comptabilité"}
        </div>,
        "Random Key",
        <Badge dot={isTaskedToDo}>
          <FontAwesomeIcon icon={faArrowsRotate} />{" "}
        </Badge>
      ),
    getItem(
      <Link to="/" style={{ textDecoration: "none" }}>
        Accueil
      </Link>,
      "1",
      <FontAwesomeIcon icon={faHouse} />
    ),
    getItem("Mon Profil", "Prof", <FontAwesomeIcon icon={faUser} />, [
      getItem(
        <Link to="/profile" style={{ textDecoration: "none" }}>
          Mes informations
        </Link>,
        "Prof-1",
        <FontAwesomeIcon icon={faIdBadge} />
      ),
      getItem(
        <Link to="/docs/me" style={{ textDecoration: "none" }}>
          Mes documents
        </Link>,
        "Prof-2",
        <FontAwesomeIcon icon={faFolder} />
      ),
      getItem(
        <Link to="/team/me" style={{ textDecoration: "none" }}>
          Mon équipe
        </Link>,
        "Prof-3",
        <FontAwesomeIcon icon={faPeopleGroup} />
      ),
      getItem(
        <Link to="/access/me" style={{ textDecoration: "none" }}>
          Mes accès
        </Link>,
        "Prof-4",
        <FontAwesomeIcon icon={faKey} />
      ),
      getItem(
        <Link to="/equipments/me" style={{ textDecoration: "none" }}>
          Mes èquipements
        </Link>,
        "Prof-5",
        <FontAwesomeIcon icon={faComputer} />
      ),
      getItem(
        <Link to="/skills" style={{ textDecoration: "none" }}>
          Grille des compétances
        </Link>,
        "Prof-5",
        <FontAwesomeIcon icon={faCode} />
      ),
      getItem(
        <Link to="/balance/archive" style={{ textDecoration: "none" }}>
          Mon solde de congé
        </Link>,
        "34",
        <FontAwesomeIcon icon={faCalendarXmark} />
      ),
    ]),
    getItem(
      <a style={{ textDecoration: "none" }}>Mes demandes</a>,
      "25",
      <FontAwesomeIcon icon={faFileLines} />,
      [
        getItem("Historique", "255", <FontAwesomeIcon icon={faBoxArchive} />, [
          getItem(
            <Link to="/manage/self" style={{ textDecoration: "none" }}>
              Congés/Autorisation
            </Link>,
            "2",
            <FontAwesomeIcon icon={faFileLines} />
          ),

          getItem(
            <Link to="/manage/self/telework" style={{ textDecoration: "none" }}>
              Télétravail
            </Link>,
            "988",
            <FontAwesomeIcon icon={faHouseLaptop} />
          ),

          getItem(
            <Link to="/self/equipments" style={{ textDecoration: "none" }}>
              Equipements
            </Link>,
            "512",
            <FontAwesomeIcon icon={faLaptopFile} />
          ),

          getItem(
            <Link to="/self/access" style={{ textDecoration: "none" }}>
              Accès
            </Link>,
            "977",
            <FontAwesomeIcon icon={faKey} />
          ),
        ]),
        getItem(
          <Link to="/pending/requests" style={{ textDecoration: "none" }}>
            En attente
          </Link>,
          "256",
          <FontAwesomeIcon icon={faClock} />
        ),
      ]
    ),
    getItem(
      <Link to="/absences" style={{ textDecoration: "none" }}>
        Mes absences
      </Link>,
      "33",
      <FontAwesomeIcon icon={faFileSignature} />
    ),
    stats?.isTeamLead &&
      getItem("Mes subordonnés", "5", <FontAwesomeIcon icon={faPeopleLine} />, [
        getItem(
          <Link to="/manage/subordinates" style={{ textDecoration: "none" }}>
            Demandes en attentes
          </Link>,
          "556",
          <FontAwesomeIcon icon={faClock} />
        ),
        getItem(
          <Link
            to="/manage/subordinates/archive"
            style={{ textDecoration: "none" }}
          >
            Historique
          </Link>,
          "557",
          <FontAwesomeIcon icon={faBoxArchive} />
        ),
      ]),
    getItem(
      <Link to="/team/calendar" style={{ textDecoration: "none" }}>
        Calendrier de l'équipe
      </Link>,
      "13",
      <FontAwesomeIcon icon={faCalendarDays} />
    ),
    getItem(
      <Link to="/trombinoscope" style={{ textDecoration: "none" }}>
        Trombinoscope
      </Link>,
      "7",
      <FontAwesomeIcon icon={faPeopleGroup} />
    ),
    getItem(
      <Link to="/tools" style={{ textDecoration: "none" }}>
        Boîte à outils
      </Link>,
      "6",
      <FontAwesomeIcon icon={faToolbox} />
    ),

    getItem(
      <Link to="/faq" style={{ textDecoration: "none" }}>
        FAQ
      </Link>,
      "8",
      <FontAwesomeIcon icon={faQuestionCircle} />
    ),

    getItem(
      // eslint-disable-next-line
      <a
        href="#"
        onClick={() => {
          Navigate("/login");
          localStorage.removeItem("access_token");
        }}
        style={{ textDecoration: "none" }}
      >
        Se déconnecter
      </a>,
      "14",
      <FontAwesomeIcon icon={faArrowRightFromBracket} />
    ),
  ];

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      if (windowSize.innerWidth < 992) {
        setCollapsed(true);
      }
    }
    window.addEventListener("resize", handleWindowResize);

    handleWindowResize();

    async function GetNotifications(autoNotif) {
      const { data } = await axios.get("/api/notif/employee");

      setNotifications(data.notifs);
      setAutoNotif(false);
      LocalStorageService().setNotif(false);
      return data;
    }

    GetNotifications(autoNotif);

    async function fetchProfilePhotoAndName() {
      const { data } = await axios.get("/api/employees/me/mini");
      setProfile(data);
      setLoading(false);
      return data;
    }

    fetchProfilePhotoAndName();
    // eslint-disable-next-line
  }, []);

  const [visibleDrawer, setVisibleDrawer] = useState(false);

  // eslint-disable-next-line
  const showDrawerVisible = () => {
    setVisibleDrawer(true);
  };

  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };

  const Navigate = useNavigate();

  const handleLogin = async () => {
    try {
      setLoading(true);
      setTimeout(() => {
        localStorage.removeItem("access_token");
        Navigate("/login");
      }, [1000]);
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const handleProfile = () => {
    Navigate("/profile");
  };

  const handleHome = () => {
    Navigate("/");
  };

  const [autoNotif, setAutoNotif] = useState(
    JSON.parse(LocalStorageService().getNotif() || true)
  );

  const [Notifications, setNotifications] = useState([]);

  const handleDisableNotif = async (id, status) => {
    try {
      const { data } = await axios.patch("/api/notif/read/" + id, {
        status: status,
      });
      if (data.status === "success") {
        const new_array = Notifications.map((notif) => {
          if (notif._id === id) {
            notif.read = status;
            return notif;
          } else {
            return notif;
          }
        });
        setNotifications(new_array);
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const handleDeleteNotif = async (id, status) => {
    try {
      const { data } = await axios.patch("/api/notif/deleted/" + id, {
        status: status,
      });
      if (data.status === "success") {
        let new_array = [];
        Notifications.forEach((notif) => {
          if (notif._id !== id) new_array.push(notif);
        });
        setNotifications(new_array);
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <div onClick={handleLogin}>
              <div style={{ cursor: "pointer" }}>
                <FontAwesomeIcon
                  style={{ marginRight: "3px" }}
                  icon={faArrowRightFromBracket}
                />{" "}
                Se deconnecter
              </div>
            </div>
          ),
        },
      ]}
    />
  );

  return loading ? (
    <div className="loading">
      <div class="dot-spinner">
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
      </div>
    </div>
  ) : (
    <Layout className="vh-100 vw-100 overflow-hidden">
      <Sider
        style={{ backgroundColor: theme.siderBg }}
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={270}
      >
        <div className="mx-2 my-2 text-center">
          {!collapsed ? (
            <img
              src={logoDark}
              style={{
                height: "45px",
                cursor: "pointer",
                transition: "width 0.2s ease",
              }}
              alt="Neopolis"
              onClick={handleHome}
            />
          ) : (
            <img
              src={iconDark}
              style={{
                height: "30px",
                cursor: "pointer",
                transition: "width 0.2s ease",
              }}
              alt="Neopolis"
              onClick={handleHome}
            />
          )}
        </div>
        <div style={{ height: "calc(100vh - 80px)" }}>
          <Menu
            selectedKeys={[selectedLocation]}
            className="h-100"
            mode="inline"
            theme={theme.siderMode}
            style={{ overflowY: "scroll", backgroundColor: theme.siderBg }}
            inlineCollapsed={collapsed}
            items={items}
          />
        </div>
      </Sider>

      <Layout style={{ background: "#FFFFFF" }}>
        <Header
          style={{
            padding: 0,
            background: theme.headerBg,
          }}
          className="d-flex justify-content-between align-items-center"
        >
          <div>
            <Button
              type="text"
              icon={
                collapsed ? (
                  <MenuUnfoldOutlined />
                ) : (
                  <MenuFoldOutlined
                    style={{
                      color:
                        windowSize.innerWidth < 500 && theme.logo !== "light"
                          ? "white"
                          : "black",
                    }}
                  />
                )
              }
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
            <span className="d-none d-lg-inline-block d-md-inline-block ">
              Nous sommes le{" "}
            </span>

            <span
              className="d-none d-lg-inline-block d-md-inline-block"
              style={{
                fontSisze: "16px",
                fontWeight: "600",
                marginLeft: "3px",
              }}
            >
              {" "}
              {" " + dayjs().format("dddd") + " "}
              {dayjs().format("DD MMMM YYYY")}{" "}
            </span>
          </div>

          <div className="mx-3 d-flex align-items-center ">
            <span
              style={{ paddingRight: "5px", cursor: "pointer" }}
              onClick={showDrawerVisible}
            >
              <Badge dot={Notifications.length > 0}>
                <Avatar
                  shape="square"
                  size={"large"}
                  icon={
                    <FontAwesomeIcon
                      icon={faBell}
                      style={{ fontSize: "24px", marginTop: "6px" }}
                    />
                  }
                  style={{
                    cursor: "pointer",
                  }}
                />
              </Badge>
            </span>

            <div
              className="d-flex flex-column align-items-end mx-2"
              style={{ height: "64px", marginTop: "-16px" }}
            >
              <p
                style={{
                  height: "20px",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                {profile?.fullName}{" "}
                {profile?.sub_role &&
                  profile?.sub_role !== "EMPLOYEE" &&
                  profile?.sub_role !== "TEAM_LEAD" && (
                    <>#{profile?.matricule}</>
                  )}
              </p>
              <p
                style={{ height: "28px", fontWeight: "400", fontSize: "12px" }}
              >
                {profile?.sub_role &&
                profile?.sub_role !== "EMPLOYEE" &&
                profile?.sub_role !== "TEAM_LEAD" ? (
                  <div style={{ cursor: "pointer" }}>
                    En tant que collaborateur
                  </div>
                ) : (
                  <>Matricule: #{profile?.matricule}</>
                )}
              </p>
            </div>

            <Dropdown
              overlay={menu}
              placement="bottomRight"
              trigger={["click"]}
            >
              {profile?.imgProfile ? (
                <Avatar
                  size={"large"}
                  src={profile?.imgProfile}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <Avatar
                  size={"large"}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "24px",
                  }}
                >
                  {profile?.fullName[0] ?? "N"}
                </Avatar>
              )}
            </Dropdown>
          </div>
        </Header>
        <Drawer
          title="Notifications"
          placement="right"
          onClose={onCloseDrawer}
          visible={visibleDrawer}
        >
          {Notifications.map((notif) => {
            return notif.title !== "" && !notif.read ? (
              <div
                className="rounded d-flex mt-2"
                style={{
                  backgroundColor: "#F0FAFD",
                  paddingRight: "10px",
                  paddingBottom: "5px",
                }}
              >
                <div className="px-2 py-1">
                  <span style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon
                      icon={faBell}
                      style={{ color: "#2c5397" }}
                    />
                  </span>
                </div>
                <div className="d-flex justify-content-between w-100">
                  <div className="d-flex flex-column py-1">
                    <Text style={{ fontSize: "12px", fontWeight: "600" }}>
                      {notif.title}
                    </Text>
                    <Text style={{ fontSize: "12px", fontWeight: "400" }}>
                      {moment(notif.send_time_date).format(
                        "MMMM DD YYYY - hh:mm:ss"
                      )}
                    </Text>
                    <Text style={{ fontSize: "12px", fontWeight: "400" }}>
                      {notif.sender}
                    </Text>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <img
                      onClick={() => {
                        handleDeleteNotif(notif._id, true);
                      }}
                      src={closeIcon}
                      title
                      alt="close_icon"
                      style={{ cursor: "pointer" }}
                    />
                    <Tooltip
                      title="Cliquez pour désactiver"
                      placement="topRight"
                    >
                      <img
                        onClick={() => {
                          handleDisableNotif(notif._id, true);
                        }}
                        src={eyeHiddenIcon}
                        alt="hidden_icon"
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="rounded d-flex mt-2"
                style={{
                  backgroundColor: "#F0FAFD",
                  paddingRight: "10px",
                  paddingBottom: "7px",
                }}
              >
                <div className="px-2 py-1">
                  <span style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon
                      icon={faBell}
                      style={{ color: "#2c5397" }}
                    />
                  </span>
                </div>
                <div className="d-flex justify-content-between w-100">
                  <div className="d-flex flex-column py-1">
                    <Text
                      style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#90A9CB",
                      }}
                    >
                      {notif.title}
                    </Text>
                    <Text
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#90A9CB",
                      }}
                    >
                      {moment(notif.send_time_date).format(
                        "MMMM DD YYYY - hh:mm:ss"
                      )}
                    </Text>
                    <Text
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#90A9CB",
                      }}
                    >
                      {notif.sender}
                    </Text>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <img
                      onClick={() => {
                        handleDeleteNotif(notif._id, true);
                      }}
                      src={closeIcon}
                      alt="close_icon"
                      style={{ cursor: "pointer" }}
                    />

                    <img src={eyeIcon} alt="hidden_icon" />
                  </div>
                </div>
              </div>
            );
          })}
        </Drawer>
        <Content
          className="container-fluid h-100 pb-4"
          style={{
            overflowY: "scroll",
            background: "#F4F5F7",
            borderTopLeftRadius: "20px",
          }}
        >
          {contextHolderCollab}
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutClient;

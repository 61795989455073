import React, { useEffect, useState } from "react";
import {
    Button,
    DatePicker,
    Drawer,
    Form,
    Radio,
    Select,
    Space,
    Tabs,
    Tag,
    Timeline,
    message,
} from "antd";
import axiosInstance from "../../../Utils/axios";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePen } from "@fortawesome/free-solid-svg-icons";

const EditContract = ({ editableData, dataSource, setDataSource }) => {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [employees, setEmployees] = useState([]);

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const items = [
        {
            key: "1",
            label: "Avec Période d'essai",
            children: (
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <Form.Item
                            label="Date de début de la période d'essai"
                            name="trailStartDate"
                        >
                            <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <Form.Item
                            label="Date de fin de la période d'essai"
                            name="trailEndDate"
                        >
                            <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
                        </Form.Item>
                    </div>
                </div>
            ),
        },
        {
            key: "2",
            label: "Sans Période d'essai",
        },
    ];

    const filterOption = (input, option) => {
        return (option?.key ?? "").toLowerCase().includes(input.toLowerCase());
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const { data } = await axiosInstance.get("/api/employees/");

                if (data?.status === "success") {
                    setEmployees(data?.employees);
                }
            } catch (error) {
                message.error(error?.response?.data?.message);
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        form.setFieldValue("matricule", editableData?.matricule);
        form.setFieldValue("contractType", editableData?.contractType);
        form.setFieldValue("embaucheDate", dayjs(editableData?.embaucheDate));
        form.setFieldValue("status", editableData?.status);
        if (editableData?.endContractDate) {
            form.setFieldValue(
                "endContractDate",
                dayjs(editableData?.endContractDate)
            );
        }
        if (editableData?.trail?.startDate) {
            form.setFieldValue(
                "trailStartDate",
                dayjs(editableData?.trail?.startDate)
            );
        }
        if (editableData?.trail?.endDate) {
            form.setFieldValue("trailEndDate", dayjs(editableData?.trail?.endDate));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editableData]);

    const handleSave = async () => {
        form
            .validateFields()
            .then(async (res) => {
                try {
                    const userData = employees?.find(
                        (elem) => elem?.matricule === res?.matricule
                    );
                    const contractData = {
                        status: res?.status,
                        contractType: res?.contractType,
                        matricule: res?.matricule,
                        fullName: userData?.fullName,
                        civility: userData?.civility,
                        embaucheDate: dayjs(res?.embaucheDate).toDate(),
                        endContractDate: res?.endContractDate
                            ? dayjs(res?.endContractDate).toDate()
                            : null,
                        trail: {
                            startDate: res?.trailStartDate
                                ? dayjs(res?.trailStartDate).toDate()
                                : null,
                            endDate: res?.trailEndDate
                                ? dayjs(res?.trailEndDate).toDate()
                                : null,
                        },
                        CIN: userData?.CIN,
                        Poste: userData?.Poste,
                    };

                    const { data } = await axiosInstance.patch(
                        "/api/contract/" + editableData?._id,
                        contractData
                    );

                    if (data.status === "success") {
                        const temp = dataSource.map((elem) => {
                            if (elem?._id === editableData?._id) {
                                return {
                                    _id: editableData?._id,
                                    ...contractData,
                                };
                            } else {
                                return elem;
                            }
                        });
                        setDataSource(temp);
                        message.success(data?.message);
                        onClose();
                        form.resetFields();
                    }
                } catch (error) {
                    message.error(error?.response?.data?.message);
                }
            })
            .catch((error) => {
                return error;
            });
    };

    return (
        <>
            <Button
                size="small"
                type="primary"
                onClick={showDrawer}
                shape="round"
                icon={<FontAwesomeIcon icon={faFilePen} />}
            >
                Modifer
            </Button>
            <Drawer
                title="Modifier contrat"
                width={720}
                onClose={onClose}
                open={open}
                bodyStyle={{
                    paddingBottom: 80,
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Annuler</Button>
                        <Button onClick={handleSave} type="primary">
                            Modifier
                        </Button>
                    </Space>
                }
            >
                <Form form={form} layout="vertical">
                    <Timeline>
                        <Timeline.Item>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <Form.Item
                                        label="Collaborateur"
                                        name="matricule"
                                        rules={[
                                            { required: true, message: "Merci de remplir le champ" },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Collaborateur"
                                            showSearch
                                            filterOption={filterOption}
                                        >
                                            {employees?.map((elem) => {
                                                return (
                                                    <Select.Option
                                                        value={elem?.matricule}
                                                        key={elem?.fullName + " #" + elem?.matricule}
                                                    >
                                                        {elem?.fullName} #{elem?.matricule}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Timeline.Item>
                        <Timeline.Item>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <Form.Item
                                        label="Type de contrat"
                                        name="contractType"
                                        rules={[
                                            { required: true, message: "Merci de remplir le champ" },
                                        ]}
                                    >
                                        <Select placeholder="Type de contract (CDI, CIVP 1, CIVP 2, KARAMA, CDD)">
                                            <Select.Option key="1" value="CDI">
                                                CDI
                                            </Select.Option>
                                            <Select.Option key="2" value="CIVP 1">
                                                CIVP 1
                                            </Select.Option>
                                            <Select.Option key="22" value="CIVP 2">
                                                CIVP 2
                                            </Select.Option>
                                            <Select.Option key="3" value="KARAMA">
                                                KARAMA
                                            </Select.Option>
                                            <Select.Option key="4" value="CDD">
                                                CDD
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Timeline.Item>
                        <Timeline.Item>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <Form.Item
                                        label="Date d'embauche"
                                        name="embaucheDate"
                                        rules={[
                                            { required: true, message: "Merci de remplir le champ" },
                                        ]}
                                    >
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            format={"DD/MM/YYYY"}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <Form.Item
                                        label="Date de fin du contrat"
                                        name="endContractDate"
                                    >
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            format={"DD/MM/YYYY"}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </Timeline.Item>
                        <Timeline.Item>
                            <div>
                                <Tabs defaultActiveKey="1" items={items} />
                            </div>
                        </Timeline.Item>
                        <Timeline.Item>
                            <Form.Item name="status">
                                <Radio.Group>
                                    <Radio value={"actif"}>
                                        <Tag color="green">Activé</Tag>
                                    </Radio>
                                    <Radio value={"expired"}>
                                        <Tag>Expiré</Tag>
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Timeline.Item>
                    </Timeline>
                </Form>
            </Drawer>
        </>
    );
};
export default EditContract;

import { Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarsStaggered,
  faUser,
  faUserGear,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import { Link, Outlet } from "react-router-dom";


function getItem(label, key, icon, children, disabled, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
    disabled,
  };
}

const Parameters = () => {
  const items = [
    getItem(
      <Link className="text-decoration-none" to="/administration/parametre">
        Administration
      </Link>,
      "1",
      <FontAwesomeIcon icon={faUserGear} />,
      null,
      true
    ),
    getItem(
      <Link
        className="text-decoration-none"
        to="/administration/parametre/account"
      >
        Mon compte
      </Link>,
      "2",
      <FontAwesomeIcon icon={faUser} />
    ),
    getItem(
      <Link
        className="text-decoration-none"
        to="/administration/parametre/roles"
      >
        Rôles et prévillages
      </Link>,
      "3",
      <FontAwesomeIcon icon={faUserLock} />
    ),
    getItem(
      <Link
        className="text-decoration-none"
        to="/administration/parametre/other"
      >
        Autres
      </Link>,
      "4",
      <FontAwesomeIcon icon={faBarsStaggered} />
    ),
  ];
  return (
    <div className="pt-4">
      <div
        style={{ minHeight: "60vh" }}
        className="bg-white p-4 rounded shadow-sm "
      >
        <div className="mb-3">
          <h4>Paramètres</h4>
        </div>
        <div className="row h-100">
          <div className="col-lg-2 col-md-3 h-100">
            <Menu
              defaultSelectedKeys={["1"]}
              className="h-100"
              mode="inline"
              items={items}
            />
          </div>
          <div className="col-lg-10 col-md-9">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parameters;

import React, { useContext, useEffect, useState } from "react";
import { Button } from "antd";

import axios from "../../Utils/axios";
import GlobalContext from "../../Context/GlobalContext";
import EquipmentsRequests from "../../Components/EquipmentsRequests";
import { useNavigate } from "react-router-dom";

const MyEquipments = ({}) => {
  const { equipments, setEquipments } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      title: "Réference",
      dataIndex: "_ref",
      key: "Catégorie",
    },
    {
      title: "Type",
      dataIndex: "_categorie",
      key: "Référence",
    },
    {
      title: "Marque",
      dataIndex: "_brand",
      key: "Marque",
    },

    // {
    //   title: "Date d'acquisition",
    //   key: "date",
    //   render: (record) => (
    //     <Typography.Text>
    //       {dayjs(
    //         record._time_line[record._time_line.length - 1]._start_date
    //       ).format("DD/MM/YYYY")}
    //     </Typography.Text>
    //   ),
    // },
    // {
    //   title: "Actions",
    //   render: (record) => (
    //     <Space>
    //       {/* <EditEquipmentModal record={record} /> */}
    //       {/* {access && <EquipmentHistoryDrawer timeLine={record._time_line} />} */}
    //       {/* <AffectToModal record={record} employees={employees} /> */}
    //       <Button type="primary">Demande de réparation</Button>
    //     </Space>
    //   ),
    // },
  ];

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(`/api/equipments/me`);

      setEquipments(data.equipments);
      setLoading(false);
    }

    fetchData();
  }, []);

  const Navigate = useNavigate();
  return (
    <div className=" bg-white p-4 rounded shadow-sm mt-4">
      <div className="mb-3 d-flex justify-content-between">
        <h4>📋 Historique de mes demandes d'équipements :</h4>
        <Button
          type="primary"
          shape="round"
          onClick={() => Navigate("/request/equipments",{
            state: {
              equipments,
             
            },
          })}
        >
          Nouvelle demande
        </Button>
      </div>
      <EquipmentsRequests />
    </div>
  );
};

export default MyEquipments;

import React from 'react';
import { Avatar, List, Typography } from 'antd';

const OnLeaveList = ({ data }) => (
    <div className='py-3'>
        <h4>
            🏖️ Collaborateurs en Congé pendant la Période Sélectionnée
        </h4>
        <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item, index) => (
                <List.Item>
                    <List.Item.Meta
                        avatar={<Avatar>{item?.fullName[0]}</Avatar>}
                        title={<Typography.Text>{item?.fullName}</Typography.Text>}
                        description={item?.description}
                    />
                </List.Item>
            )}
        />
    </div>
);
export default OnLeaveList;

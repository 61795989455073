import { useState, useEffect, useRef, useContext } from "react";
import {
  Table,
  Space,
  Spin,
  Button,
  Input,
  Tooltip,
  Dropdown,
  Tag,
} from "antd";

import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import Highlighter from "react-highlight-words";
import { ColumnHeightOutlined } from "@ant-design/icons";

import axios from "../../../Utils/axios";
import GlobalContext from "../../../Context/GlobalContext";
import AccessDrawer from "./AccessDrawer";

const ManageAccess = () => {
  const { role } = useContext(GlobalContext);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const items = [
    {
      key: "1",
      label: (
        /* eslint-disable-next-line */
        <a
          href="#"
          className="text-decoration-none"
          onClick={() => {
            handleSizeChange("large");
          }}
        >
          Plus grand
        </a>
      ),
    },
    {
      key: "2",
      label: (
        /* eslint-disable-next-line */
        <a
          href="#"
          className="text-decoration-none"
          onClick={() => {
            handleSizeChange("middle");
          }}
        >
          Moyenne
        </a>
      ),
    },
    {
      key: "3",
      label: (
        /* eslint-disable-next-line */
        <a
          href="#"
          className="text-decoration-none"
          onClick={() => {
            handleSizeChange("small");
          }}
        >
          Compact
        </a>
      ),
    },
  ];

  const [size, setSize] = useState("small");
  const handleSizeChange = (new_size) => {
    setSize(new_size);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Recherche ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            // style={{
            //   width: 90,
            // }}
          >
            Recherche
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Matricule",
      dataIndex: "matricule",
      ...getColumnSearchProps("matricule", "Matricule"),
    },
    {
      title: "Nom et prénom",
      dataIndex: "fullName",
      ...getColumnSearchProps("fullName", "Nom et prénom"),
    },
    {
      title: "Type d'accès",
      dataIndex: "role",
      filters: [
        {
          text: <Tag>Rh/Admin</Tag>,
          value: "SUPER_ADMIN",
        },
        {
          text: <Tag>Comptabilité</Tag>,
          value: "ACCOUNTANT",
        },
        {
          text: <Tag>Chef D'equipe</Tag>,
          value: "TEAM_LEAD",
        },
        {
          text: <Tag>Admin Sys</Tag>,
          value: "SYS_ADMIN",
        },
        {
          text: <Tag>Collaborateur</Tag>,
          value: "EMPLOYEE",
        },
      ],
      onFilter: (value, record) =>
        record.role === value || record.sub_role === value,
      render: (_, record) => (
        <Space>
          <Tag color="blue">
            {roles?.find((elem) => elem?.code === record?.role)?.name}
          </Tag>
          {record?.sub_role && (
            <Tag color="green">
              {roles?.find((elem) => elem?.code === record?.sub_role)?.name}
            </Tag>
          )}
        </Space>
      ),
    },

    {
      title: "Actions",
      render: (_, employee) => (
        <Space size="middle">
          <AccessDrawer
            record={employee}
            roles={roles}
            employees={employees}
            setEmployees={setEmployees}
          />
        </Space>
      ),
    },
  ];
  const [roles, setRoles] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchEmployees() {
      axios.get("/api/employees/").then((response) => {
        if (response.data.status === "success") {
          setEmployees(response.data.employees);
          axios.get("/api/role").then((res) => {
            if (res.data.status === "success") {
              setRoles(res.data.roles);
              setLoading(false);
            }
          });
        }
      });
    }
    fetchEmployees();
  }, []);

  return (
    <div className="pt-4">
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spin size="large" />
        </div>
      ) : (
        <div className="bg-white p-4 rounded-4 shadow-sm ">
          <div className="mb-3">
            <h4 className="fw-bold">Gérer l'accès</h4>
          </div>

          <Table
            className="shadow-sm rounded-3"
            columns={columns}
            size={size}
            dataSource={employees}
            title={() => (
              <div className="d-flex justify-content-between">
                <Space>
                  <Tooltip title="Note pour les types d'accès !">
                    <ExclamationCircleOutlined style={{ cursor: "pointer" }} />
                  </Tooltip>
                  <Tag color="blue">Rôle principal</Tag>
                  <Tag color="green">Rôle secondaire</Tag>{" "}
                </Space>
                <Tooltip title="Densité">
                  <Dropdown
                    menu={{
                      items,
                    }}
                  >
                    <ColumnHeightOutlined
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                    />
                  </Dropdown>
                </Tooltip>
              </div>
            )}
            scroll={{ x: "max-content" }}
          />
        </div>
      )}
    </div>
  );
};

export default ManageAccess;

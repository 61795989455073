import { Empty } from "antd";
import React from "react";
import underConstruction from "../../../Assets/Under-construction-bro.svg";

const KpisManagement = () => {
  return (
    <div>
      <Empty
        image={underConstruction}
        imageStyle={{
          height: 460,
        }}
        description=""
      />
    </div>
  );
};

export default KpisManagement;

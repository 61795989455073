import { useState, useEffect } from "react";
import {
  Spin,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Button,
  message,
} from "antd";
import axios from "../../../Utils/axios";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
const { Text } = Typography;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const Holidays = () => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    const fixedEvents = [
      "Fête de la révolution",
      "Fête de l’indépendance",
      "Fête du travail",
      "Fête de la république",
      "Le premier jour de l’année",
      "Fête des martyrs",
      "Fête de l’évacuation",
      "Fête de la femme",
    ];

    if (fixedEvents.includes(record.label)) {
      setEditingKey("");
      return;
    }
    form.setFieldsValue({
      name: "",
      age: "",
      address: "",
      ...record,
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (_id) => {
    try {
      const row = await form.validateFields();
      const newData = [...holidays];
      const index = newData.findIndex((item) => _id === item._id);

      await axios.patch("/api/holidays/" + _id, row);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setHolidays(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setHolidays(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      message.error(errInfo?.response?.data?.message);
    }
  };

  const columns = [
    { title: "Evenement", dataIndex: "label", editable: true },
    {
      title: "Nombre du jours",
      dataIndex: "Days",
      render: (text) => <Text strong>{text} Jour(s)</Text>,
      editable: true,
    },
    {
      title: "Date début",
      dataIndex: "Date",
      render: (text) => <Text>{moment(text).format("DD MMMM")} </Text>,
      editable: true,
    },
    {
      title: "Actions",
      render: (_, record) => {
        const fixedEvents = [
          "Fête de la révolution",
          "Fête de l’indépendance",
          "Fête du travail",
          "Fête de la république",
          "Le premier jour de l’année",
          "Fête des martyrs",
          "Fête de l’évacuation",
          "Fête de la femme",
        ];
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record._id)}
              style={{
                marginRight: 8,
              }}
            >
              Sauvegarder
            </Typography.Link>
            <Popconfirm
              placement="topLeft" title="Vous êtes sûr d'annuler ?" onConfirm={cancel}>
              {/* eslint-disable-next-line */}
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <>
            {fixedEvents.includes(record.label) ? (
              <Typography.Text disabled>Fixée</Typography.Text>
            ) : (
              <Typography.Link
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
              >
                Modifier
              </Typography.Link>
            )}
          </>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const [loading, setLoading] = useState(true);
  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    const fetchHolidays = async () => {
      const { data } = await axios.get("/api/holidays/");

      setLoading(false);
      setHolidays(data.holidays);

      return data;
    };
    fetchHolidays();
  }, []);

  const handleExportToXLSX = () => {
    const data = holidays.map((element) => {
      return {
        Evenement: element.label,
        "Nombre du jours": element.Days,
        "Date de début": moment(element.Date).format("DD/MM/YYYY"),
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(data);

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(
      workbook,
      `Liste du jours fériés ${moment().format("DD/MM/YYYY HH-mm")}.xlsx`
    );
  };

  return (
    <div className="bg-white p-4 rounded shadow-sm ">
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <div className="mb-3">
            <h4>Liste du jours fériés</h4>
          </div>
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              dataSource={holidays}
              className="shadow-sm"
              columns={mergedColumns}
              scroll={{ x: "max-content" }}
              rowClassName="editable-row"
              pagination={{
                onChange: cancel,
                pageSize: 14,
              }}
            />
          </Form>
        </div>
      )}
    </div>
  );
};

export default Holidays;

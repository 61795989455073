import React, { useEffect, useState } from "react";
import { Spin, message } from "antd";
import axiosInstance from "../../Utils/axios";
import GeneralProfile from "./Components/GeneralProfile";

const Profile = () => {
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get("/api/employees/profile");

        setProfile(data?.employee);
        setLoading(false);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
    fetchData();
  }, [refresh]);

  return (
    <div className="py-3">
      <div className="bg-white rounded-3 py-2" style={{ minHeight: "80vh" }}>
        <div style={{ maxHeight: "82vh", overflowY: "scroll" }}>
          <div className="container py-3">
            <h4>🪪 Mes Informations :</h4>
            {loading ? (
              <div className="d-flex justify-content-center align-items-center ">
                <Spin size="large" />
              </div>
            ) : (
              <GeneralProfile data={profile} refresh={refresh} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;

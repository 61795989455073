import React, { useEffect, useState } from "react";
import classes from "./EditorText.module.css";
import { Editor } from "react-draft-wysiwyg";

import "./ReactDraft.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Col, Form, Row } from "antd";

function EditorText() {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [message,setMessage]=useState("")

  const onEditorStateChange = (newState) => {
    setEditorState(newState);
    let mes = draftToHtml(convertToRaw(newState.getCurrentContent()));
    setMessage(mes)

  
  };
  useEffect(() => {
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(message))
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ minHeight: "100%" }}>

      {/* <Row> */}
        {/* <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <label for="text"> Description: </label>
        </Col> */}
        <Col xs={24} sm={24} md={24} lg={16} xl={16} >
          <Form.Item name="description">
          <Editor
            editorState={editorState}
            toolbarClassName={classes.toolbarClassName}
            wrapperClassName={classes.wrapperClassName}
            editorClassName={classes.editorClassName}
            onEditorStateChange={onEditorStateChange}
          /></Form.Item>
        </Col>
      {/* </Row> */}
    </div>
  );
}

export default EditorText;

import { ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Button, Modal, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../Utils/axios";

const DeleteService = ({ services, setServices, record }) => {
  const [modal, contextHolder] = Modal.useModal();

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(
        "/api/administration/service/delete/" + record?._id
      );
      const temp = services.filter((elem) => elem?._id !== record?._id);
      setServices(temp);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const confirm = () => {
    modal.confirm({
      onOk: handleDelete,
      title: "⚠️ Attention ⚠️",
      icon: <ExclamationCircleOutlined />,
      content: (
        <p style={{ fontSize: 14 }}>
          Une fois ce service supprimé, tous les accèss associés seront
          supprimés avec lui.
          <br /> Êtes-vous toujours sûr de vouloir continuer ?
        </p>
      ),

      okText: "Oui",
      cancelText: "Cancel",
    });
  };
  return (
    <>
      <Button
        onClick={confirm}
        type="primary"
        size="small"
        shape="circle"
        danger
      >
        <FontAwesomeIcon icon={faTrashCan} />
      </Button>
      {contextHolder}
    </>
  );
};
export default DeleteService;

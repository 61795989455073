import React, { useState } from "react";
import { Form, Input, Button, Typography } from "antd";
import LoginTag from "../../Assets/Forgot-password-pana.svg";
import axios from "../../Utils/axios";
import Logo from "../../Assets/logo-neop-dev-bleu.png";
import { useNavigate } from "react-router-dom";
import ConfirmEmailModal from "../../Components/ConfirmEmailModal";

const { Text, Link } = Typography;

const ForgotPassword = () => {
  const Navigate = useNavigate();

  const [Trigger, setTrigger] = useState(false);

  const handleTrigger = (TriggerValue) => {
    setTrigger(TriggerValue);
  };

  const handleReset = async (formValues) => {
    // Navigate("/");
    try {
      const { data } = await axios.post("api/auth/forgot-password", formValues);
      if (data.status === "success") handleTrigger(true);
    } catch (e) { }
  };
  return (
    <div className="vh-100 bg-light vw-100" style={{ overflow: "hidden" }}>
      <div className="d-flex vw-100">
        <div className="col-lg-4 col-md-7 col-sm-12  bg-white shadow-lg p-5 vh-100">

          <div className="d-flex flex-column justify-content-between h-100">
            <div></div>
            <div>
              <h3 className="mb-4">
                Mot de passe oublié ?
              </h3>
              <p className="lead border-bottom pb-2 mb-3" style={{ fontSize: "14px" }}>
                Entrez l'email de votre compte et nous vous enverrons un lien
                pour réinitialiser votre mot de passe. </p>
              <Form
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 24 },
                }}
                layout="vertical"
                size="large"
                onFinish={handleReset}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "S’il vous plaît entrer votre email!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item>
                  <div className="d-flex justify-content-between align-items-center flex-column flex-xl-row">
                    <Button className="my-1" type="primary" htmlType="submit" shape="round">
                      Réinitialiser !
                    </Button>
                    <Link
                      className="my-1 fw-bold"
                      onClick={() => {
                        Navigate("/login");
                      }}
                    >
                      Se connecter ?
                    </Link>
                  </div>
                </Form.Item>
              </Form>
            </div>
            <div>
              <div className="d-flex justify-content-between">
                <Text type="primary" className="mt-2" strong>
                  ©2023 Neopolis-dev
                </Text>
                <img src={Logo} style={{ height: "35px" }} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-6 col-sm-12 justify-content-center d-none d-lg-flex d-md-flex ">
          <img src={LoginTag} alt="LOGIN" style={{ width: "85%" }} />
        </div>
      </div>
    </div>

  );
};

export default ForgotPassword;

import React, { useState } from "react";
import { Button, Form, Input, Modal, message, Tooltip } from "antd";
import axiosInstance from "../../../../../Utils/axios";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const RejectModal = ({ record, index, refresh, setRefresh }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    form
      .validateFields()
      .then((res) => {
        axiosInstance
          .patch(
            `/api/request/access/${index === 1 ? "hr" : "sysadmin"}/${
              record?._id
            }`,
            {
              status: false,
              motif: res?.motif,
            }
          )
          .then((res) => {
            setRefresh(!refresh);
            setIsModalOpen(false);
          })
          .catch((error) => message.error(error?.response?.data?.message));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Tooltip title="Rejeter la demande">
        <Button
          size="small"
          shape="circle"
          type="primary"
          disabled={
            record?.answered_by.length !== index || record?.status !== "PENDING"
          }
          onClick={showModal}
          danger
        >
          <FontAwesomeIcon icon={faXmark} />
        </Button>
      </Tooltip>
      <Modal
        title="Etes-vous sûr de rejeter la demande ?"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Soumettre"
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            label="Motif"
            name="motif"
            rules={[{ whitespace: true, required: true, message: "Veuillez fournir un motif." }]}
          >
            <Input.TextArea
              placeholder="Veuillez fournir un motif..."
              rows={4}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default RejectModal;

import React, { useContext, useEffect, useState } from "react";
import { Spin, Timeline } from "antd";
import axios from "../../../Utils/axios";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../../Context/GlobalContext";
import StatisticComponent from "./StatisticComponent";
import TodoTable from "../../Components/TodoList/TodoTable";

const Home = ({ status, setStatus }) => {
  const { setRedirectData, role } = useContext(GlobalContext);
  const Navigate = useNavigate();
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [todayLeaves, setTodayLeaves] = useState([]);
  const [page, setPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState(1);
  const [actualities, setActualities] = useState([]);
  const [todayRemote, setTodayRemote] = useState([]);

  useEffect(() => {
    async function getStatistics() {
      const { data } = await axios.get("/api/platform/stats");
      if (data) {
        setStats(data);
        setLoading(false);
      }
      return data;
    }

    async function getTodayLeaves() {
      const { data } = await axios.get("/api/leaves/get/today/leaves");
      setTodayLeaves(data.todayLeave);
      setTodayRemote(data.todayRemote);
      return data;
    }

    getTodayLeaves();
    getStatistics();
  }, []);

  useEffect(() => {
    async function getActualities() {
      const { data } = await axios.get(
        `/api/factory/actualities?page=${page}&pageSize=3`
      );
      setActualities([...actualities, ...data.actualities]);
      setPageNumbers(data.totalPages);
    }
    getActualities();
  }, [page]);

  const handlePlusOthers = () => {
    setRedirectData({
      type: "OTHERS",
      filters: ["PENDING"],
    });
    Navigate("/administration/requests");
  };

  const handlePlusLeaves = () => {
    setRedirectData({
      type: "DEFAULT",
      filters: ["Pending", "Validating", "Unjustified"],
    });
    Navigate("/administration/requests");
  };
  return (
    <div className="container">
      <div className="p-4 ">
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spin size="large" />
          </div>
        ) : (
          <div className="row">
            <div className="row mb-4">
              {/* {role === "SUPER_ADMIN" && (
                <div className="col-lg-5 col-md-5 col-sm-12 ">
                  <div className="bg-white p-3 rounded-3 shadow-sm">
                    <Timeline className="container py-2 mb-4">
                      <Timeline.Item>
                        <h1
                          style={{ fontWeight: "500", fontSize: "20px" }}
                          className="pb-2"
                        >
                          👥 Collaborateurs
                        </h1>
                        <div className="row my-4">
                          <div className="col-lg-12 mb-2 col-md-12 ">
                            <StatisticComponent
                              color="#ed7985"
                              stats={stats.actifUsers}
                              label="Collaborateurs actifs"
                            />
                          </div>
                          <div className="col-lg-12 col-md-12 ">
                            <StatisticComponent
                              color="#2b95fb"
                              stats={stats.disabledUsers}
                              label="Collaborateurs non actifs"
                            />
                          </div>
                        </div>
                      </Timeline.Item>

                      <Timeline.Item>
                        <h1
                          style={{ fontWeight: "500", fontSize: "20px" }}
                          className="pb-2"
                        >
                          📮 Demandes
                        </h1>
                        <div className="row ">
                          <div className="col-lg-12 my-1 col-md-12 col-sm-12 ">
                            <StatisticComponent
                              color="#2b95fb"
                              stats={stats.untreatedLeaves}
                              label="Demandes de congé / autorisations non traitées"
                              handleClick={handlePlusLeaves}
                            />
                          </div>
                          <div className="col-lg-12 my-1 col-md-12 col-sm-12 ">
                            <StatisticComponent
                              color="#faa32f"
                              stats={stats.untreatedRequests}
                              label="Autres demandes non traitées"
                              handleClick={handlePlusOthers}
                            />
                          </div>
                        </div>
                      </Timeline.Item>
                    </Timeline>
                  </div>
                </div>
              )} */}
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="bg-white p-3 rounded-3 shadow-sm h-100">
                  <TodoTable />
                </div>
              </div>
            </div>
            <div className="p-3">
              <div className="row bg-white rounded-3"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import {
    Button,
    Collapse,
    Drawer,
    Form,
    Input,
    Popconfirm,
    Radio,
    Select,
    Space,
    notification,
    message,
    Upload,
} from "antd";
import {
    MinusCircleOutlined,
    PlusOutlined,
    InboxOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAt,
    faKey,
    faLink,
    faLock,
    faServer,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../Utils/axios";

const { Dragger } = Upload;

const status = [
    {
        label: "Activer",
        value: true,
    },
    {
        label: "Désactiver",
        value: false,
    },
];

const { Panel } = Collapse;
const AccessDrawer = ({ record, refresh, setRefresh, disabled }) => {
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);


    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleForm = () => {
        form
            .validateFields()
            .then((res) => {
                let formData = res;
                axiosInstance
                    .patch(
                        "/api/employees/invited/user/sys/" + record?.matricule,
                        formData
                    )
                    .then((response) => {
                        message.success("Accés a été enregistré avec succès");
                        onClose();
                        setRefresh(!refresh);
                    })
                    .catch((err) =>
                        api.error({
                            message: err?.response?.data?.message,
                            placement: "topLeft",
                            duration: 4,
                        })
                    );
            })
            .catch((error) => {
                console.log(error);
                api.error({
                    message: error?.errorFields[0]?.errors[0],
                    placement: "topLeft",
                    duration: 4,
                });
            });
    };

    return (
        <>
            <Button onClick={showDrawer} disabled={disabled} size="small" type="primary">
                Accès
            </Button>
            <Drawer
                title="Ajouter l'accès"
                width={720}
                onClose={onClose}
                open={open}
                bodyStyle={{
                    paddingBottom: 80,
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Annuler</Button>
                        <Button onClick={handleForm} type="primary">
                            Modifier
                        </Button>
                    </Space>
                }
            >
                {contextHolder}
                <Form layout="vertical" form={form}>
                    {/* GIT */}
                    <Collapse ghost expandIconPosition={"end"} defaultActiveKey={["1"]}>
                        <Panel
                            header={
                                <span style={{ fontSize: "16px", fontWeight: "500" }}>
                                    GitLab
                                </span>
                            }
                            className="border-bottom rounded-0"
                            key="1"
                        >
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <Form.Item label="Username" name={["git", "user_name"]}>
                                        <Input prefix={<FontAwesomeIcon icon={faUser} />} />
                                    </Form.Item>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <Form.Item label="Email" name={["git", "email"]}>
                                        <Input
                                            onChange={(e) => {
                                                form.setFieldsValue({
                                                    email: e.target.value.replace(/ /g, ""),
                                                });
                                            }}
                                            prefix={<FontAwesomeIcon icon={faAt} />}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <Form.Item name={["git", "password"]} label="Mot de passe">
                                        <Input.Password
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <Form.Item name={["git", "url"]} label="Lien">
                                        <Input
                                            suffix={<FontAwesomeIcon icon={faLink} />}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </Panel>
                        <Panel
                            header={
                                <span style={{ fontSize: "16px", fontWeight: "500" }}>
                                    ActiveCollab
                                </span>
                            }
                            className="border-bottom rounded-0"
                            key="2"
                        >
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <Form.Item
                                        label="Username"
                                        name={["activeCollab", "user_name"]}
                                    >
                                        <Input prefix={<FontAwesomeIcon icon={faUser} />} />
                                    </Form.Item>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <Form.Item label="Email" name={["activeCollab", "email"]}>
                                        <Input prefix={<FontAwesomeIcon icon={faAt} />} />
                                    </Form.Item>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <Form.Item
                                        name={["activeCollab", "password"]}
                                        label="Mot de passe"
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <Form.Item name={["activeCollab", "url"]} label="Lien">
                                        <Input
                                            suffix={<FontAwesomeIcon icon={faLink} />}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </Panel>
                        <Panel
                            header={
                                <span style={{ fontSize: "16px", fontWeight: "500" }}>
                                    Email Pro
                                </span>
                            }
                            className="border-bottom rounded-0"
                            key="3"
                        >
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <Form.Item label="Email" name={["pro", "email"]}>
                                        <Input prefix={<FontAwesomeIcon icon={faAt} />} />
                                    </Form.Item>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <Form.Item
                                        name={["pro", "password"]}
                                        label="Mot de passe"
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                </div>

                            </div>
                        </Panel>
                    </Collapse>

                    {/* ACTIVECOLAB */}

                    {/* JENKINS */}
                </Form>
            </Drawer>
        </>
    );
};

export default AccessDrawer;

import { Form, Input, Button, Typography, message } from "antd";
import axios from "../../Utils/axios";
import LoginTag from "../../Assets/Reset-password-pana.svg";
import Logo from "../../Assets/logo-neop-dev-bleu.png";
import { useNavigate, useParams } from "react-router-dom";

const { Text } = Typography;

const PasswordReset = () => {
  const Navigate = useNavigate();

  const { token } = useParams();

  const handleReset = async (formValues) => {
    try {
      const { data } = await axios.patch(
        "/api/auth/reset-password/" + token,
        formValues
      );
      if (data) {
        message.success("le mot de passe a été réinitialisé", 3);
        setTimeout(() => {
          Navigate("/login");
        }, 3000);
      }
    } catch (err) { }
  };
  return (

    <div className="vh-100 bg-light vw-100" style={{ overflow: "hidden" }}>
      <div className="d-flex vw-100">
        <div className="col-lg-4 col-md-7 col-sm-12 bg-white shadow-lg p-5 vh-100">
          <div className="d-flex flex-column justify-content-between h-100">
            <div></div>
            <div>
              <h3 className="border-bottom pb-3 mb-4">
                Réinitialisez votre mot de passe
              </h3>
              <Form
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 24 },
                }}
                layout="vertical"
                size="large"
                onFinish={handleReset}
              >
                <Form.Item
                  label="Nouvelle mot de passe"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "S’il vous plaît entrer votre mot de passe!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="Confirmer mot de passe"
                  name="passwordConfirm"
                  rules={[
                    {
                      required: true,
                      message: "S’il vous plaît entrer votre mot de passe!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <div className="d-flex justify-content-between">
                    <Button type="primary" htmlType="submit" shape="round">
                      Réinitialiser !
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
            <div>
              <div className="d-flex justify-content-between">
                <Text type="primary" className="mt-2" strong>
                  ©2023 Neopolis-dev
                </Text>
                <img src={Logo} style={{ height: "35px" }} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-6 col-sm-12 justify-content-center d-none d-lg-flex d-md-flex ">
          <img src={LoginTag} alt="LOGIN" style={{ width: "85%" }} />
        </div>
      </div>
    </div>



  );
};

export default PasswordReset;

import { Button, Empty, message } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import DocumentCard from "../../Components/DocumentCard";
import axios from "../../Utils/axios";
import dayjs from "dayjs";
import { Input, DatePicker, Radio, Space } from "antd";

const { Search } = Input;
const SharedDocs = () => {
  const [documents, setDocuments] = React.useState([]);
  const [value, setValue] = useState("tous");
  const [changed, setChange] = useState(false);

  async function fetchData() {
    try {
      const { data } = await axios.get("/api/docs/");

      setDocuments(data.docs);
      return data;
    } catch (error) {}
  }

  const clearFilters = () => {
    setChange(!changed);
    setValue("tous");
  };

  useEffect(() => {
    fetchData();
  }, [changed]);

  const onChange = async (e) => {
    setValue(e.target.value);
    if (
      e.target.value === "Assurance" ||
      e.target.value === "Best Practise" ||
      e.target.value === "Administratif"
    ) {
      try {
        const { data } = await axios.get(
          `/api/docs/filter/category/${e.target.value}`
        );
        setDocuments(data.docs);
      } catch (error) {}
    } else {
      fetchData();
    }
  };

  const onChangeDate = async (date, dateString) => {
    setValue("tous");
    const [year, weekNum] = dateString.split("-");
    // Calculate the start date and end date of the week using dayjs
    const startDate = dayjs().year(year).week(weekNum).startOf("week");
    const endDate = dayjs().year(year).week(weekNum).endOf("week");
    // Do something with the start date and end date
    try {
      const { data } = await axios.get(
        `/api/docs/filter/date?startDate=${dayjs(startDate).format(
          "DD-MM-YYYY"
        )}&endDate=${dayjs(endDate).format("DD-MM-YYYY")}`
      );
      setDocuments(data.docs);
    } catch (error) {
      message.error(error.response.data.message);
    }
  };
  const handleBlur = async (value) => {
    try {
      const { data } = await axios.get(`/api/docs/filter/title/${value}`);
      setDocuments(data.docs);
    } catch (error) {}
  };

  return (
    <div className="bg-white p-4 mt-4 rounded-4 shadow-sm pb-5">
      <div className="mb-3">
        <h4>🗃️ Boite à outils</h4>
      </div>

      <div className="row">
        <div
          className="col-lg-3 col-md-12 col-sm-12 mt-3 border shadow-sm"
          style={{
            padding: "20px",
            borderRadius: "13px",
          }}
        >
          <div
            style={{
              position: "sticky",
              top: ".9rem",
            }}
          >
            <div className="border-bottom pb-2">
              <label>Catégorie:</label>
              <br />
              <Radio.Group onChange={onChange} value={value}>
                <Space direction="vertical">
                  <Radio value="Assurance">Assurance</Radio>
                  <Radio value="Best Practise">Best Practice</Radio>
                  <Radio value="Administratif">Administratif</Radio>
                  <Radio value="tous">Toutes les catégories</Radio>
                </Space>
              </Radio.Group>
            </div>
            <div className="border-bottom pb-2 pt-2">
              <label>Nom document:</label>
              <br />
              <Search
                placeholder="Exp: Guide ActiveCollab"
                allowClear
                onSearch={handleBlur}
              />
            </div>
            <div className="border-bottom pb-2 pt-2">
              <label>Semaine:</label>
              <br />
              <DatePicker
                onChange={onChangeDate}
                picker="week"
                className="w-100"
              />
            </div>
            <div className="pb-2 pt-2">
              <Button type="primary" onClick={clearFilters}>
                Effacer les filtres
              </Button>
            </div>
          </div>
        </div>

        {/* </Affix> */}
        <div className="col-lg-8 col-md-12 col-sm-12">
          {documents ? (
            documents.map((doc) => {
              return !doc._disabled && <DocumentCard id={doc._id} />;
            })
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 60,
              }}
              description={<span>Il n'y a pas de documents partagés!</span>}
            ></Empty>
          )}
        </div>
      </div>
    </div>
  );
};

export default SharedDocs;

import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
const { Dragger } = Upload;

const UploadArea = ({ setFiles }) => {
  const props = {
    name: "file",
    multiple: true,
    action: `${process.env.REACT_APP_API_URL}/api/upload/`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        setFiles((prev) => [...prev, info.file.response.filename]);
      }
      if (status === "done") {
        message.success(`${info.file.name} fichier téléchargé avec succès.`);
      } else if (status === "error") {
        message.error(
          `${info.file.name} Le téléchargement du fichier a échoué.`
        );
      }
    },
  };
  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Cliquez ou faites glisser le fichier vers cette zone
      </p>
      <p className="ant-upload-hint">
        Joindre des fichiers (captures d'écran, message d'erreur, photos, etc.){" "}
      </p>
    </Dragger>
  );
};
export default UploadArea;

import { useState, useEffect } from "react";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  Space,
  message,
  Select,
  Radio,
  Spin,
} from "antd";

import UploadInput from "../../../Components/UploadInput";
import axios from "../../../Utils/axios";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";
const { Option } = Select;

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const EditEmployeeDrawer = (props) => {
  const { onClose, visible, variables, setVariables, drawerSize } = props;
  const [responsables, setResponsables] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAlternate, setIsAlternate] = useState(false);

  useEffect(() => {
    async function fetchProfiles() {
      const { data } = await axios.get("api/employees/");
      const resData = data.employees.filter(
        (d) => d.matricule !== variables.matricule
      );
      setResponsables(resData);
      return data;
    }

    fetchProfiles();
    // eslint-disable-next-line
  }, []);

  const [form] = Form.useForm();

  variables.birthDate = dayjs(variables.birthDate);
  variables.embaucheDate = dayjs(variables.embaucheDate);

  useEffect(() => {
    form.setFieldsValue(variables);
    setIsAlternate(variables._is_alternate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCV = (base64) => {
    form.setFieldsValue({
      CV: base64,
    });
  };

  const setProfilePic = (base64) => {
    form.setFieldsValue({
      imgProfile: base64,
    });
  };

  const setCIN = (base64) => {
    form.setFieldsValue({
      _cin_File: base64,
    });
  };

  const setRIB = (base64) => {
    form.setFieldsValue({
      _rib_File: base64,
    });
  };

  const UpdateUser = async () => {
    setLoading(true);
    const formValues = form.getFieldsValue();
    try {
      const { data } = await axios.patch(
        "/api/employees/" + formValues.matricule,
        formValues
      );
      setLoading(false);
      if (data.status === "success") {
        message.success(
          "Le profil du collaborateur a été mis à jour avec succès!"
        );
        setVariables(formValues);
        onClose();
      }
    } catch (error) {
      setLoading(false);
      message.error(error.response.data.message);
    }
  };

  return (
    <>
      <Drawer
        title="Modifier Profil"
        width={drawerSize}
        onClose={onClose}
        visible={visible}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Annuler</Button>
            <Button onClick={UpdateUser} type="primary">
              Modifier
            </Button>
          </Space>
        }
      >
        <Spin indicator={antIcon} spinning={loading}>
          <Form form={form} layout="vertical" size="middle" disabled={loading}>
            <div className="row border-bottom mb-3">
              <div className="col-lg-6 col-sm-12">
                <Form.Item label="Nom et prénom" name="fullName">
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({
                        fullName: e.target.value.replace(/[^a-zA-Z ]/g, ""),
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item label="Email Professionel" name="email">
                  <Input />
                </Form.Item>
                <Form.Item label="Email Personel" name="_personal_email">
                  <Input type="email" />
                </Form.Item>
                <Form.Item label="Numéro de télèphone" name="phoneNum">
                  <PhoneInput defaultCountry="TN" placeholder="12 345 678" />
                </Form.Item>

                <Form.Item label="RIB" name="Rib">
                  <Input />
                </Form.Item>
                <Form.Item label="CIN" name="CIN">
                  <Input />
                </Form.Item>
                <Form.Item label="Adresse" name="address">
                  <Input />
                </Form.Item>
                <Form.Item label="Alternance" name="_is_alternate">
                  <Radio.Group
                    onChange={(e) => {
                      form.setFieldsValue({
                        ...form.getFieldsValue(),
                        _is_alternate: e.target.value,
                      });
                      setIsAlternate(e.target.value);
                    }}
                    defaultValue={
                      form.getFieldValue("_is_alternate")
                        ? form.getFieldValue("_is_alternate")
                        : false
                    }
                    buttonStyle="solid"
                  >
                    <Radio.Button value={true}>Oui</Radio.Button>
                    <Radio.Button value={false}>Non</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className="col-lg-6 col-sm-12">
                <Form.Item label="Matricule" name="matricule">
                  <Input disabled />
                </Form.Item>
                <Form.Item label="Date d'embauche" name="embaucheDate">
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item label="Date de naissance" name="birthDate">
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item label="Lieu de naissance" name="birthPlace">
                  <Input />
                </Form.Item>
                <Form.Item label="Type de contrat" name="contractType">
                  <Input />
                </Form.Item>
                <Form.Item label="Responsable" name="_idResponsable">
                  <Select placeholder="choisir un responsable pour cet employé">
                    {responsables.map((emp) => {
                      return (
                        <Option value={emp.matricule}>
                          {emp.fullName} #{emp.matricule}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Poste" name="Poste">
                  <Input />
                </Form.Item>
                {isAlternate && (
                  <Form.Item label="École" name="_alternant_school">
                    <Input />
                  </Form.Item>
                )}
              </div>
            </div>
            <div>
              <h4>Personne à prévenir en cas d'urgence</h4>
            </div>
            <div className="col-lg-6 col-sm-12">
              <Form.Item name="_urgence_name" label="Nom et prénom">
                <Input />
              </Form.Item>
              <Form.Item name="_urgence_relation" label="Relation">
                <Input />
              </Form.Item>
              <Form.Item name="_urgence_phone" label="Numéro de télèphone">
                <Input />
              </Form.Item>
            </div>
            <div>
              <div className="border-top pt-3">
                <h4>Pièces a joindre</h4>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <Form.Item name="_cin_File" label="CIN">
                    <UploadInput setFile={setCIN} />
                  </Form.Item>
                  <Form.Item name="CV" label="CV">
                    <UploadInput setFile={setCV} />
                  </Form.Item>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <Form.Item name="_rib_File" label="RIB">
                    <UploadInput setFile={setRIB} />
                  </Form.Item>
                  <Form.Item name="imgProfile" label="Photo de profile">
                    <UploadInput setFile={setProfilePic} />
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default EditEmployeeDrawer;

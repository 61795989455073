import { message } from "antd";
import { useContext, useEffect, useState } from "react";
import FaqDrawer from "../../Components/FaqDrawer";
import axios from "../../../Utils/axios";
import FaqCard from "../../Components/FaqCard";
import GlobalContext from "../../../Context/GlobalContext";

const Faq = () => {
  const [faqs, setFAQS] = useState([]);
  const { rolePrevilleges } = useContext(GlobalContext);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get("/api/faq/");
      setFAQS(data.faqs);
      return data.faqs;
    }

    fetchData();
  }, []);

  const deleteFaq = async (_id) => {
    try {
      await axios.delete(`/api/faq/${_id}`);
      const temp = faqs.filter((faq) => faq._id !== _id);
      setFAQS(temp);
    } catch (error) {
      message.error(error.response.data.message);
    }
  };
  return (
    <div
      className="bg-white p-4 rounded shadow-sm  vh-100"
      style={{ overflow: "scroll" }}
    >
      <div className="mb-3 d-flex justify-content-between">
        <h4 className="fw-bold">❓ Foire Aux Questions </h4>{" "}
        {rolePrevilleges?.previllegesList?.find(
          (elem) => elem?.code === "EDIT_FAQ"
        ) && <FaqDrawer faqs={faqs} setFaqs={setFAQS} />}
      </div>
      <div className="row">
        {faqs.map((faq) => {
          return (
            <div className="col-lg-12 ">
              <FaqCard
                faq={faq}
                deleteFaq={deleteFaq}
                access={rolePrevilleges?.previllegesList?.find(
                  (elem) => elem?.code === "EDIT_FAQ"
                )}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Faq;

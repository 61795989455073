import CalendarMonth from "./CalendarMonth";
import CalendarDay from "./CalendarDay";
import Filters from "./Filters";
import MiniCalendar from "./MiniCalendar";
import axiosInstance from "../../Utils/axios";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Button, Dropdown, Space, message } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import LeavePrecentage from "./LeavePrecentage";

const TeamCalendar = () => {
  const [filters, setFilters] = useState([
    "Autorisation",
    ["Congé Maladie",
      "Congé sans solde",
      "Congé Special",
      "Congé annuel",
      "Congé demi-journée",]
  ]);
  const [selectedDate, setSelectedDate] = useState("");
  const [data, setData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [mode, setMode] = useState("MONTH");
  const Navigate = useNavigate();

  useEffect(() => {
    if (data?.length > 0) {
      let temp = data?.filter((elem) => {
        if (elem?._is_half_day) {
          if (filters?.includes("Congé demi-journée")) {
            return elem;
          }
        } else if (filters?.includes(elem._typeLeave)) {
          return elem;
        }
      });
      setDisplayedData(temp);
    }
  }, [filters, selectedDate]);

  useEffect(() => {
    axiosInstance
      .get("/api/leaves/team/calendar")
      .then((res) => {
        const temp = res.data.map((elem) => {
          elem.BeginingDate = dayjs(elem.BeginingDate).toDate();
          elem.EndingDate = dayjs(elem.EndingDate).add("23", "hour").toDate();
          return elem;
        });

        setData(temp);
        setDisplayedData(temp);
      })

      .catch((err) => {
        message.error("Something went so wrong !");
        Navigate("/");
      });
  }, []);

  function headerRender(value, mode) {
    if (mode === "year") {
      return null;
    } else {
      return <div style={{ textAlign: "center", padding: "10px 0" }}></div>;
    }
  }

  const onSelect = (newValue) => {
    setMode("AGENDA");
    setSelectedDate(dayjs(newValue).format("DD/MM/YYYY"));
  };

  const items = [
    {
      key: "1",
      label: <Filters filters={filters} setFilters={setFilters} />,
    },
  ];

  return (
    <div className="pt-4 ">
      <div className="bg-white p-4 rounded-4 shadow-sm w-100 h-100">
        <div className="mb-3 d-flex justify-content-between align-items-center">
          <h4>📆 Calendrier de l'équipe</h4>
        </div>

        <div className="h-100 w-100 row flex-row flex-lg-row flex-md-column-reverse">
          <div className="col-lg-9 d-none d-lg-block d-md-block">
            <div className="d-flex justify-content-end">
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    setMode("MONTH");
                    setSelectedDate("");
                  }}
                >
                  Mois
                </Button>
                <Button type="primary" onClick={() => setMode("AGENDA")}>
                  Jour
                </Button>
              </Space>
            </div>
            {mode === "MONTH" ? (
              <CalendarMonth data={displayedData} headerRender={headerRender} />
            ) : (
              <div
                style={{ height: "60vh", overflowY: "scroll" }}
                className="my-3"
              >
                <CalendarDay
                  data={displayedData}
                  singleDate={selectedDate ? selectedDate : 0}
                />
              </div>
            )}
          </div>
          <div className="col-lg-3 ">
            <LeavePrecentage />
            <div className="d-none d-lg-block d-md-block ">
              <Filters filters={filters} setFilters={setFilters} />
            </div>
          </div>
          <div>
            <Dropdown.Button
              className="d-block d-lg-none d-md-none d-flex mt-3 justify-content-end "
              type="primary"
              menu={{ items }}
              icon={<DownOutlined />}
              placement="bottomLeft"
              trigger={["click"]}
            >
              Filtrer par
            </Dropdown.Button>
          </div>
        </div>
        <div className="d-block d-lg-none d-md-none">
          <CalendarDay
            data={displayedData}
            singleDate={selectedDate ? selectedDate : 0}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamCalendar;

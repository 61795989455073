import { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Spin,
  Modal,
  Form,
  Input,
  Button,
  Descriptions,
  Result,
} from "antd";

import { useNavigate, useParams } from "react-router-dom";

import dayjs from "dayjs";
import axios from "../../Utils/axios";

import "../AcceptePage/AcceptePage.css";

const { TextArea } = Input;

const RefusePage = () => {
  const [form] = Form.useForm();

  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [request, setRequest] = useState("");

  const Navigate = useNavigate();

  const { id } = useParams();

  const UpdateLeave = async (formValues) => {
    const { data } = await axios.patch("/api/leaves/options/" + id, {
      status: "Rejected",
      message: formValues.message,
    });
    if (data.status === "success") {
      Modal.success({
        title: "Action Terminé!",
      });
      Navigate("/");
    }

    return data;
  };

  useEffect(() => {
    async function fetchRequest() {
      const { data } = await axios.get("/api/leaves/" + id);
      console.log(data);
      if (data.Leave.status !== "Pending") {
        setLoading(false);
        setSent(true);
        Modal.info({
          title: "La demande a déjà reçu une réponse ou a été annulée",
        });
        return data;
      }
      if (data.status === "success") {
        setRequest(data.Leave);
        setLoading(false);
        return data;
      }
    }
    fetchRequest();
    // eslint-disable-next-line
  }, []);

  const handleReturn = () => {
    Navigate("/");
  };

  return (
    <div className="min-vh-100 h-100 errorPageBackground d-flex align-items-center">
      <div className="container pt-4 d-flex flex-column align-items-center">
        {loading ? (
          <>
            <div className="d-flex justify-content-center">
              <Spin size="large" />
            </div>
            <h5
              className=" text-center mt-3"
              style={{ letterSpacing: "2px", color: "#001529" }}
            >
              Cela peut prendre un moment...
            </h5>
          </>
        ) : (
          <>
            {!sent ? (
              <div className="col-lg-8 col-md-8 col-sm-12 d-flex flex-column align-items-center">
                <Card
                  title={`Demande de ${request?.fullName}`}
                  bordered={false}
                  style={{
                    width: "100%",
                    backgroundColor: "#F4F5F7",
                  }}
                >
                  <div className="mx-3">
                    <Descriptions
                      title="Détails de la demande"
                      bordered
                      className="mb-2"
                    >
                      <Descriptions.Item label="Type de la demande" span={3}>
                        {request?._typeLeave}
                      </Descriptions.Item>
                      <Descriptions.Item label="Date de début" span={3}>
                        {dayjs(request?.BeginingDate).format("DD/MM/YYYY")}
                      </Descriptions.Item>
                      <Descriptions.Item label="Date de fin" span={3}>
                        {dayjs(request?.EndingDate).format("DD/MM/YYYY")}
                      </Descriptions.Item>
                      <Descriptions.Item label="Durée" span={3}>
                        {request?._duration ? request?._duration : request?._is_half_day ? request?._is_half_day : dayjs(request?.EndingDate).diff(request?.BeginingDate, "day") + 1 + ' jour(s)'}
                      </Descriptions.Item>
                     <Descriptions.Item label="Motif" span={3}>
                        {request?._motif}
                      </Descriptions.Item>
                    </Descriptions>
                    <Form form={form} layout="vertical" onFinish={UpdateLeave}>
                      <Form.Item
                        label={
                          "Veuillez fournir une justification claire et détaillée pour le refus de cette demande."
                        }
                        name="message"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Veuillez fournir une justification",
                          },
                        ]}
                      >
                        <TextArea size="large" maxLength={300} rows={4} />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          size="large"
                          htmlType="submit"
                          type="primary"
                          shape="round"
                        >
                          Soumettre
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </Card>
              </div>
            ) : (
              <div
                className="col-lg-8 col-md-8 col-sm-12 d-flex flex-column align-items-center rounded-2"
                style={{
                  backgroundColor: "#F4F5F7",
                }}
              >
                <Result
                  title="La demande a déjà reçu une réponse ou a été annulée"
                  extra={
                    <Button size="large" shape="round" type="primary" onClick={handleReturn}>
                      Accueil
                    </Button>
                  }
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RefusePage;

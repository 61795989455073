import { useState, useEffect, useRef, useContext } from "react";

import {
  SearchOutlined,
  CloseCircleTwoTone,
  LoadingOutlined,
  CheckCircleTwoTone,
  MinusCircleTwoTone,
} from "@ant-design/icons";

import {
  Button,
  Space,
  Table,
  Tag,
  Typography,
  message,
  ConfigProvider,
  Input,
  Tooltip,
  Popconfirm,
  Steps,
} from "antd";
import Highlighter from "react-highlight-words";

import axios from "../../../../Utils/axios";
import moment from "moment";

import GlobalContext from "../../../../Context/GlobalContext";
import TeleworkRequestsDrawer from "../../../../Drawers/TeleworkRequestsDrawer";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { Text } = Typography;

const TeleworkRequests = () => {
  const [refresh, setRefresh] = useState(false);
  const { profile, redirectData, rolePrevilleges } = useContext(GlobalContext);

  const [size, setSize] = useState("default");
  const [optionsTab, setOptions] = useState([
    "fullName",
    "request",
    "status",
    "motif",
    "startDate",
    "endDate",
    "duration",
    "answered_by",
  ]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [dateRange, setDateRange] = useState("");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Recherche ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            Recherche
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Soumis le",
      dataIndex: "_posted_date",
      sorter: (a, b) => moment(a._posted_date) - moment(b._posted_date),
      render: (_, leave) => (
        <Text strong>
          {leave?._posted_date
            ? moment(leave._posted_date).format("DD/MM/YYYY HH:mm")
            : "N/A"}
        </Text>
      ),
    },
    optionsTab.find((elem) => elem === "fullName")
      ? {
          title: "Demandeur",
          dataIndex: "fullName",
          key: "1",
          ...getColumnSearchProps("fullName", "Nom et prénom"),
          render: (_, requestedLeaves) => (
            <Text>{requestedLeaves.fullName}</Text>
          ),
        }
      : { width: 0, className: "d-none" },

    optionsTab.find((elem) => elem === "startDate")
      ? {
          title: "Date de début",
          dataIndex: "BeginingDate",
          // ...getColumnSearchProps("BeginingDate"),
          key: "5",
          sorter: (a, b) => moment(a.BeginingDate) - moment(b.BeginingDate),
          render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "endDate")
      ? {
          title: "Date de fin",
          dataIndex: "EndingDate",
          // ...getColumnSearchProps("EndingDate"),
          key: "6",
          sorter: (a, b) => moment(a.EndingDate) - moment(b.EndingDate),
          render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "duration")
      ? {
          title: "Durée",
          render: (_, requestedLeaves) => (
            <Tooltip
              color="cyan"
              title={
                requestedLeaves._typeLeave === "Télétravail" ? (
                  <div>
                    Sauf:
                    <br />
                    {requestedLeaves._except.map((elem) => {
                      return <Tag color="blue">{elem}</Tag>;
                    })}
                  </div>
                ) : requestedLeaves._typeLeave === "Autorisation" ? (
                  requestedLeaves._duration
                ) : !requestedLeaves._is_half_day ? (
                  requestedLeaves.DaysNumber + " Jour(s)"
                ) : (
                  requestedLeaves._is_half_day
                )
              }
            >
              <Text>
                {requestedLeaves._typeLeave === "Autorisation"
                  ? requestedLeaves._duration
                  : !requestedLeaves._is_half_day
                  ? requestedLeaves.DaysNumber + " Jour(s)"
                  : requestedLeaves._is_half_day}
              </Text>
            </Tooltip>
          ),
        }
      : { width: 0, className: "d-none" },

    optionsTab.find((elem) => elem === "status")
      ? {
          title: "Statut",
          dataIndex: "status",
          key: "7",
          filters: [
            {
              text: <Tag color={"orange"}>En Attente</Tag>,
              value: "Pending",
            },

            {
              text: <Tag color={"green"}>Approuvée</Tag>,
              value: "Accepted",
            },

            {
              text: <Tag color={"cyan"}>En attente d'approbation</Tag>,
              value: "Validating",
            },

            {
              text: <Tag color={"red"}>Rejeté</Tag>,
              value: "Rejected",
            },

            {
              text: <Tag color="red">Annulée</Tag>,
              value: "Canceled",
            },

            {
              text: <Tag color={"gold"}>Non encore justifié</Tag>,
              value: "Unjustified",
            },

            {
              text: <Tag>Échue</Tag>,
              value: "Failed",
            },
          ],
          defaultFilteredValue:
            redirectData?.type === "DEFAULT" && redirectData?.filters
              ? redirectData?.filters
              : [],
          onFilter: (value, record) => record.status.includes(value),
          render: (_, record) => (
            <Steps
              size="small"
              items={[
                {
                  title: "Chef D'équipe",
                  status:
                    record?.status === "Pending"
                      ? record?.validatedByTL
                        ? "finish"
                        : "process"
                      : "finish",
                  icon:
                    record?.status === "Pending" ? (
                      !record?.validatedByTL ? (
                        <LoadingOutlined />
                      ) : (
                        <CheckCircleTwoTone twoToneColor={"#87d068"} />
                      )
                    ) : record?.status !== "Accepted" ? (
                      record?.status === "Canceled" ? (
                        <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                      ) : (
                        <CloseCircleTwoTone twoToneColor={"#f3531e"} />
                      )
                    ) : (
                      <CheckCircleTwoTone twoToneColor={"#87d068"} />
                    ),
                },
                {
                  title: "Resources Humaine",
                  status:
                    record?.status === "Pending"
                      ? record?.validatedByTL
                        ? record?.validatedByHR
                          ? "finish"
                          : "process"
                        : "finish"
                      : "finish",
                  icon:
                    record?.status === "Pending" ? (
                      record?.validatedByTL ? (
                        record?.validatedByHR ? (
                          <CheckCircleTwoTone twoToneColor={"#87d068"} />
                        ) : (
                          <LoadingOutlined />
                        )
                      ) : (
                        <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                      )
                    ) : record?.status !== "Accepted" ? (
                      record?.status === "Canceled" ? (
                        <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                      ) : (
                        <CloseCircleTwoTone twoToneColor={"#f3531e"} />
                      )
                    ) : (
                      <CheckCircleTwoTone twoToneColor={"#87d068"} />
                    ),
                },
              ]}
            />
          ),
        }
      : { width: 0, className: "d-none" },

    rolePrevilleges?.previllegesList?.find(
      (elem) => elem?.code === "PROCESS_LEAVES"
    )
      ? {
          title: "Action",
          key: "action",
          render: (_, record) => (
            <>
              <Space>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#52C41A",
                    },
                  }}
                >
                  <Popconfirm
                    placement="topLeft"
                    title="Etes-vous sûr de rejeter la demande de télétravail ?"
                    onConfirm={() => {
                      handleResponse(false, record?._id);
                    }}
                    okText="Oui"
                  >
                    <Tooltip title="Rejeter la demande">
                      <Button
                        disabled={
                          record?.status !== "Pending" ||
                          !record?.validatedByTL ||
                          record?.validatedByHR === true ||
                          record?.validatedByHR === false
                        }
                        size="small"
                        danger
                        type="primary"
                        shape="circle"
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </ConfigProvider>
                <Popconfirm
                  placement="topLeft"
                  title="Etes-vous sûr d'approuver la demande de télétravail ?"
                  onConfirm={() => {
                    handleResponse(true, record?._id);
                  }}
                  okText="Oui"
                >
                  <Tooltip title="Approuver la demande">
                    <Button
                      shape="circle"
                      disabled={
                        record?.status !== "Pending" ||
                        !record?.validatedByTL ||
                        record?.validatedByHR === true ||
                        record?.validatedByHR === false
                      }
                      size="small"
                      type="primary"
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </Button>
                  </Tooltip>
                </Popconfirm>
                <TeleworkRequestsDrawer record={record} />
              </Space>
            </>
          ),
        }
      : { width: 0, className: "d-none" },
  ];

  const [loading, setLoading] = useState(true);
  const [requestedLeaves, setRequestedLeaves] = useState([]);
  const [type, setType] = useState(
    redirectData?.type ? redirectData?.type : "DEFAULT"
  );

  useEffect(() => {
    if (profile?.role === "SYS_ADMIN") {
      setType("ACCESS");
    }
  }, [profile]);

  const handleResponse = async (status, id) => {
    try {
      const { data } = await axios.patch(
        "/api/leaves/answer/telework/hr/" + id,
        { status: status }
      );
      setRefresh(!refresh);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const { data } = await axios.get(
        `/api/leaves/monthly/pending${
          dateRange
            ? "?startDate=" +
              dateRange.startDate +
              "&endDate=" +
              dateRange.endDate
            : ""
        }`
      );
      const temp = data?.LeavesPerMonth?.filter(
        (elem) => elem?._typeLeave === "Télétravail"
      );
      setRequestedLeaves(temp);
      setLoading(false);
      return data;
    }
    fetchData();
  }, [refresh]);

  return (
    <div className="">
      <Table
        size={size}
        columns={columns}
        loading={loading}
        dataSource={requestedLeaves}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default TeleworkRequests;

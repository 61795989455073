import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Table, Tooltip, ConfigProvider, Space, Button } from "antd";
import axiosInstance from "../../../Utils/axios";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const colorValues = {
  "Congé annuel": {
    color: "#FFE58F",
    label: "C.A",
    value: "Congé annuel",
  },
  "Congé Special": {
    color: "#FFBB96",
    label: "C.S",
    value: "Congé spécial",
  },
  "Congé Maladie": {
    color: "#FFADD1",
    label: "C.M",
    value: "Congé maladie",
  },
  "Congé sans solde": {
    color: "#FFBB96",
    label: "C.S.S",
    value: "Congé sans solde",
  },
  Autorisation: {
    color: "#87E8DE",
    label: "Auth",
    value: "Autorisation",
  },
  "Absence non justifier": {
    color: "#FFA39E",
    label: "Abs",
    value: "Absence non justifier",
  },
  "Absence de reporting": {
    color: "#91CAFF",
    label: "Abs.R",
    value: "Absence de reporting",
  },
  "Demi-journée": {
    color: "#D3ADF7",
    label: "Demi",
    value: "Demi-journée",
  },
};

const ActiveCollabView = ({ selectedMonth }) => {
  const month = dayjs(selectedMonth).month(); // Get the month from the selectedMonth prop
  const daysInMonth = dayjs(selectedMonth).daysInMonth(); // Get the number of days in the selected month
  const [collection, setCollection] = useState([]); // Create a state to hold the data from the API
  const [loading, setLoading] = useState(true);
  const daysArray = Array.from({ length: daysInMonth }, (_, index) =>
    dayjs(selectedMonth)
      .month(month)
      .date(index + 1)
  ); // Create an array of dayjs objects representing each day in the selected month

  const columns = [
    {
      title: "Collaborateur",
      dataIndex: "user_name",
      key: "collab",
      fixed: "left",
      className: "text-center",
    },
    ...daysArray.map((day) => ({
      title: () => (
        <Tooltip
          title={
            day.day() === 0 || day.day() === 6
              ? "Fin de semaine"
              : "Journée de travail"
          }
        >
          {day.format("DD")}
        </Tooltip>
      ),
      dataIndex: day.format("MM/DD"),
      className:
        day.day() === 0 || day.day() === 6
          ? "text-center bg-disabled"
          : "text-center cursor-pointer",
      render: (_, record) =>
        day.day() !== 0 &&
        day.day() !== 6 && (
          <Tooltip
            title={
              record["auth-" + day.format("MM/DD")]
                ? `Autorisation: ${
                    record["auth-" + day.format("MM/DD")]
                  } Heure(s)`
                : record["half-" + day.format("MM/DD")]
                ? `Demi-journée (${
                    record["half-" + day.format("MM/DD")]?.type
                  }): ${record["half-" + day.format("MM/DD")]?.duration}`
                : colorValues[record[day.format("MM/DD")]]
                ? colorValues[record[day.format("MM/DD")]].value
                : record[day.format("MM/DD")]
            }
          >
            <div
              style={{
                backgroundColor: record["auth-" + day.format("MM/DD")]
                  ? colorValues["Autorisation"].color
                  : record["half-" + day.format("MM/DD")]
                  ? colorValues["Demi-journée"].color
                  : colorValues[record[day.format("MM/DD")]]
                  ? colorValues[record[day.format("MM/DD")]].color
                  : typeof record[day.format("MM/DD")] === "number"
                  ? "#B8EA8F"
                  : "#D9D9D9",
              }}
            >
              {record["auth-" + day.format("MM/DD")]
                ? record[day.format("MM/DD")]
                : record["half-" + day.format("MM/DD")]
                ? record[day.format("MM/DD")]
                : colorValues[record[day.format("MM/DD")]]
                ? 0
                : typeof record[day.format("MM/DD")] === "number"
                ? record[day.format("MM/DD")]
                : record[day.format("MM/DD")]
                ? "J.F"
                : record[day.format("MM/DD")]}
            </div>
          </Tooltip>
        ),
    })),
  ]; // Create an array of columns for the table

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const { data } = await axiosInstance.get(
          `api/leaves/record/monthly/active-collab/${selectedMonth}`
        );
        setCollection(data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }

    if (selectedMonth) {
      fetchData();
    }
  }, [selectedMonth]);

  const handleExportToXLSX = () => {};

  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              cellPaddingBlock: 0,
              cellPaddingInline: 0,
            },
          },
        }}
      >
        <Table
          title={() => (
            <div className="d-flex justify-content-between align-items-center">
              <Space className="py-2 px-2">
                Clés:
                {Object.entries(colorValues).map(([key, value]) => (
                  <Tooltip title={value.value}>
                    <div
                      key={key}
                      className="px-2 py-1 rounded"
                      style={{ backgroundColor: value.color }}
                    >
                      {value.label}
                    </div>
                  </Tooltip>
                ))}
              </Space>

              <Button
                type="primary"
                className="mb-2"
                style={{ marginRight: "5px" }}
                onClick={handleExportToXLSX}
                disabled={!(collection.length > 0)}
              >
                <FontAwesomeIcon
                  icon={faFileExport}
                  style={{ marginRight: "5px" }}
                />{" "}
                Exporter
              </Button>
            </div>
          )}
          loading={loading}
          dataSource={collection}
          columns={columns}
          pagination={{ pageSize: 50 }}
          scroll={{
            x: "max-content",
          }}
          bordered
        />
      </ConfigProvider>
    </div>
  );
};

export default ActiveCollabView;

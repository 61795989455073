import { Card, Skeleton, Tooltip } from "antd";
import React, { useContext } from "react";
import GlobalContext from "../../../Context/GlobalContext";
import { useNavigate } from "react-router-dom";

const SelfInformation = ({ stats, handlePlusLeaves }) => {
  const { profile } = useContext(GlobalContext);
  const Navigate = useNavigate();
  return (
    <div className="w-100">
      <h1 style={{ fontWeight: "500", fontSize: "20px" }} className="pb-2">📮 Mes demandes</h1>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12 my-2">
          <Tooltip title="Mes absences qui n'ont pas encore été justifiées ou expliquées, telles que les absences pour raison de maladie ou celles déclarées par RH.">
            <Card
              hoverable
              style={{ padding: "0 !important" }}
              onClick={handlePlusLeaves}
            >
              <h1 style={{ fontWeight: "500", fontSize: "14px" }}>
                Mes absence(s) non encore justifiée(s)
              </h1>
              <div
                className="my-3 mx-2 px-3 d-flex flex-column"
                style={{ borderLeft: "2px solid #EE7985" }}
              >
                <span style={{ fontWeight: "600", fontSize: "28px" }}>
                  {stats?.unjustifiedLeaves || stats?.unjustifiedLeaves === 0 ? (
                    stats?.unjustifiedLeaves
                  ) : (
                    <>
                      <Skeleton.Button active={true} size={"small"} />
                    </>
                  )}
                </span>
              </div>
            </Card>
          </Tooltip>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 my-2">
          <Tooltip title="Mes demandes en attente de traitement, que ce soient des demandes de congés, des demandes de matériel ou des demandes d'accès...">
            <Card
              hoverable
              style={{ padding: "0 !important" }}
              onClick={handlePlusLeaves}
            >
              <h1 style={{ fontWeight: "500", fontSize: "14px" }}>
                Mes demande(s) non encore traitée(s)
              </h1>
              <div
                className="my-3 mx-2 px-3 d-flex flex-column"
                style={{ borderLeft: "2px solid #2A96FF" }}
              >
                <span style={{ fontWeight: "600", fontSize: "28px" }}>
                  {stats?.untreatedRequests || stats?.untreatedRequests === 0 ? (
                    stats?.untreatedRequests
                  ) : (
                    <>
                      <Skeleton.Button active={true} size={"small"} />
                    </>
                  )}
                </span>
              </div>
            </Card>
          </Tooltip>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 my-2">
          <Tooltip title="Cliquez ici pour afficher le détail.">
            <Card
              hoverable
              style={{ padding: "0 !important" }}
              onClick={() => {
                Navigate("/balance/archive");
              }}
            >
              <h1 style={{ fontWeight: "500", fontSize: "14px" }}>
                Mon Solde de congé
              </h1>
              <div
                className="my-3 mx-2 px-3 d-flex flex-column"
                style={{ borderLeft: "2px solid #FFA318" }}
              >
                <span style={{ fontWeight: "600", fontSize: "28px" }}>
                  {profile?.sold}
                </span>
              </div>
            </Card>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default SelfInformation;

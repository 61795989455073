import { useEffect, useState } from "react";
import axios from "../../../Utils/axios";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Modal,
  Radio,

  Tabs,
  Divider,
} from "antd";

import UploadInput from "../../../Components/UploadInput";

import StepsForm from "../../Components/StepsForm";
import { createProfile } from "../../../API/employeeAPICalls";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import SelfAccountForm from "../../Components/SelfAccountForm";

const { Option } = Select;

const EmployeeForm = () => {
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [isAlternate, setIsAlternate] = useState(false);
  // const [initFormValues, setInitFormValues] = useState({
  //   type: "",
  //   isFull: false,
  // });
  const params =useLocation()
  const [form] = Form.useForm();

  const setCV = (base64) => {
    form.setFieldsValue({
      CV: base64,
    });
  };

  const setProfilePic = (base64) => {
    form.setFieldsValue({
      imgProfile: base64,
    });
  };

  const setCIN = (base64) => {
    form.setFieldsValue({
      _cin_File: base64,
    });
  };

  const setRIB = (base64) => {
    form.setFieldsValue({
      _rib_File: base64,
    });
  };

  const handleSubmit = async () => {
    const formValues = form.getFieldsValue(true);
    setLoading(true);
    try {
      const { data } = await createProfile(formValues);
      if (data) {
        Modal.success({ title: "Le profil a été crée avec succès !" });
        Navigate("/administration/employees/list");
      }
    } catch (error) {
      setLoading(false);
      Modal.error({
        title: "Impossible de créer un compte!",
        content: <p>{error?.response?.data?.message}</p>,
      });
    }
  };

  useEffect(() => {
    async function fetchProfiles() {
      const { data } = await axios.get("api/employees/");
      setEmployees(data.employees);
      return data;
    }

    fetchProfiles();
  }, []);

  const items = [
    {
      key: "1",
      label: "Avec Période d'essai",
      children: (
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <Form.Item
              label="Date de début de la période d'essai"
              name="trailStartDate"
            >
              <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
            </Form.Item>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <Form.Item
              label="Date de fin de la période d'essai"
              name="trailEndDate"
            >
              <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Sans Période d'essai",
    },
  ];

  const steps = [
    {
      title: "Généralités",
      content: (
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Form.Item
              label="Nom et prénom"
              name="fullName"
              validateTrigger={"onDone"}
              rules={[{ required: true, message: "Merci de remplir le champ" }]}
            >
              <Input
                onChange={(e) => {
                  form.setFieldsValue({
                    fullName: e.target.value.replace(/[^a-zA-Z ]/g, ""),
                  });
                }}
              />
            </Form.Item>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Form.Item
              label="Numéro de télèphone"
              name="phoneNum"
              validateTrigger={"onDone"}
              rules={[{ required: true, message: "Merci de remplir le champ" }]}
            >
              <PhoneInput defaultCountry="TN" placeholder="12 345 678" />
            </Form.Item>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <Form.Item label="Alternance" name="_is_alternate">
              <Radio.Group
                onChange={(e) => {
                  form.setFieldsValue({
                    ...form.getFieldsValue(),
                    _is_alternate: e.target.value,
                  });
                  setIsAlternate(e.target.value);
                }}
                defaultValue={false}
                buttonStyle="solid"
              >
                <Radio.Button value={true}>Oui</Radio.Button>
                <Radio.Button value={false}>Non</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <Form.Item label="Civilité" name="civility">
              <Radio.Group defaultValue={"M."} buttonStyle="solid">
                <Radio.Button value={"Mme"}>Mme</Radio.Button>
                <Radio.Button value={"M."}>M.</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <Form.Item
              label="Email Professionel"
              name="email"
              validateTrigger={"onDone"}
              rules={[
                { type: "email", message: "Veuillez entrer un email valid" },
                { required: true, message: "Merci de remplir le champ" },
                {
                  validator: (_, value) => {
                    if (value) {
                      if (!value.includes("@neopolis-dev.com")) {
                        return Promise.reject(
                          "l'email professionnel doit se terminer par '@neopolis-dev.com'"
                        );
                      } else {
                        return Promise.resolve();
                      }
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Input placeholder="email@neopolis-dev.com" />
            </Form.Item>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <Form.Item
              label="Email Personel"
              name="_personal_email"
              validateTrigger={"onDone"}
              rules={[
                { required: true, message: "Merci de remplir le champ" },
                { type: "email", message: "veuillez entrer un email valid" },
              ]}
            >
              <Input type="email" />
            </Form.Item>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <Form.Item
              name="CV"
              label="CV"
              validateTrigger={"onDone"}
              rules={[{ required: true, message: "Merci de remplir le champ" }]}
            >
              <UploadInput setFile={setCV} />
            </Form.Item>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <Form.Item
              name="imgProfile"
              label="Photo de profil"
              rules={[{ required: true, message: "Merci de remplir le champ" }]}
            >
              <UploadInput setFile={setProfilePic} />
            </Form.Item>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            {isAlternate && (
              <Form.Item
                label="École"
                name="_alternant_school"
                rules={[
                  { required: true, message: "Veuillez remplir le champ" },
                ]}
              >
                <Input />
              </Form.Item>
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Personnelle",
      content: (
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <Form.Item
              label="Date de naissance"
              name="birthDate"
              rules={[{ required: true, message: "Merci de remplir le champ" }]}
            >
              <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
            </Form.Item>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <Form.Item
              label="Lieu de naissance"
              name="birthPlace"
              rules={[{ required: true, message: "Merci de remplir le champ" }]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-12">
            <Form.Item
              label="Adresse"
              name="address"
              rules={[{ required: true, message: "Merci de remplir le champ" }]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Form.Item
              maxLength={8}
              minLength={8}
              type="Number"
              label="CIN"
              name="CIN"
              rules={[
                {
                  pattern: /^[\d]{8,8}$/,
                  message: "CIN ne doit avoir que 8 chiffres",
                },
                { required: true, message: "Merci de remplir le champ" },
              ]}
              validateTrigger="onDone"
            >
              <Input
                style={{ width: "100%" }}
                maxLength={8}
                onChange={(e) => {
                  form.setFieldsValue({
                    CIN: e.target.value.replace(/[^0-9]/g, ""),
                  });
                }}
              />
            </Form.Item>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <Form.Item
              name="_cin_File"
              label="Joindre CIN"
              rules={[{ required: true, message: "Merci de remplir le champ" }]}
            >
              <UploadInput setFile={setCIN} />
            </Form.Item>
          </div>
        </div>
      ),
    },
    {
      title: "Liés au travail",
      content: (
        <>
          <Divider orientation="center">Contrat</Divider>
          <div className="row bg-light py-4 rounded-4 shadow-md">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <Form.Item
                label="Type de contrat"
                name="contractType"
                rules={[
                  { required: true, message: "Merci de remplir le champ" },
                ]}
              >
                <Select placeholder="Type de contract (CDI, CIVP 1, CIVP 2, KARAMA, CDD)">
                  <Select.Option key="1" value="CDI">
                    CDI
                  </Select.Option>
                  <Select.Option key="2" value="CIVP 1">
                    CIVP 1
                  </Select.Option>
                  <Select.Option key="22" value="CIVP 2">
                    CIVP 2
                  </Select.Option>
                  <Select.Option key="3" value="KARAMA">
                    KARAMA
                  </Select.Option>
                  <Select.Option key="4" value="CDD">
                    CDD
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12">
              <Form.Item
                label="Date d'embauche"
                name="embaucheDate"
                rules={[
                  { required: true, message: "Merci de remplir le champ" },
                ]}
              >
                <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
              </Form.Item>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12">
              <Form.Item label="Date de fin du contrat" name="endContractDate">
                <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
              </Form.Item>
            </div>

            <Tabs
              type="card"
              centered
              size="large"
              defaultActiveKey="1"
              items={items}
            />
          </div>

          <Divider orientation="center">Autres informations requises</Divider>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <Form.Item
                label="Poste"
                name="Poste"
                rules={[
                  { required: true, message: "Merci de remplir le champ" },
                ]}
              >
                <Input />
              </Form.Item>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <Form.Item label="Responsable" name="_idResponsable">
                <Select placeholder="choisir un responsable pour cet employé">
                  {employees.map((emp) => {
                    return (
                      <Option value={emp.matricule}>
                        {emp.fullName} #{emp.matricule}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <Form.Item
                label="Matricule"
                name="matricule"
                rules={[
                  { required: true, message: "Merci de remplir le champ" },
                ]}
              >
                <Input maxLength={5} />
              </Form.Item>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <Form.Item
                label="RIB"
                name="Rib"
                rules={[
                  { required: true, message: "Merci de remplir le champ" },
                  {
                    pattern: /^[\d]{20,20}$/,
                    message: "RIB ne doit avoir que 20 chiffres",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  maxLength={20}
                  onChange={(e) => {
                    form.setFieldsValue({
                      Rib: e.target.value.replace(/[^0-9]/g, ""),
                    });
                  }}
                />
              </Form.Item>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <Form.Item
                maxLength={2}
                minLength={0}
                type="Number"
                label="Solde"
                name="sold"
                rules={[
                  { required: true, message: "Merci de remplir le champ" },
                ]}
                validateTrigger="onDone"
              >
                <Input
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    form.setFieldsValue({
                      sold: e.target.value.replace(/[^0-9 .]/g, ""),
                    });
                  }}
                />
              </Form.Item>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <Form.Item
                name="_rib_File"
                label="Joindre RIB"
                rules={[
                  { required: true, message: "Merci de remplir le champ" },
                ]}
              >
                <UploadInput setFile={setRIB} />
              </Form.Item>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Personne à prévenir en cas d'urgence",
      content: (
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Form.Item
              name="_urgence_name"
              label="Nom et prénom"
              rules={[{ required: true, message: "Merci de remplir le champ" }]}
            >
              <Input
                onChange={(e) => {
                  form.setFieldsValue({
                    _urgence_name: e.target.value.replace(/[^a-zA-Z ]/g, ""),
                  });
                }}
              />
            </Form.Item>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Form.Item
              validateTrigger={"onDone"}
              name="_urgence_relation"
              label="Relation"
              rules={[{ required: true, message: "Merci de remplir le champ" }]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Form.Item
              name="_urgence_phone"
              label="Numéro de télèphone"
              rules={[{ required: true, message: "Merci de remplir le champ" }]}
            >
              <PhoneInput defaultCountry="TN" placeholder="12 345 678" />
            </Form.Item>
          </div>
        </div>
      ),
    },
  ];

  // const handleFinish = (formValues) => {
  //   if (!formValues.isFull) {
  //     formValues.isFull = false;
  //   }
  //   setInitFormValues(formValues);
  // };

  return (
    <div
      className="bg-white p-4 rounded-4 shadow-sm"
      style={{ minHeight: "75vh" }}
    >
      {/* <div className="mb-3">
        <h4>Ajouter un Collaborateur</h4>
      </div> */}
  
      {params?.state?.type === "Ajouter un collaborateur" && (
        <Form
          form={form}
          disabled={loading}
          layout="vertical"
          size="middle"
          onFinish={handleSubmit}
        >
          <StepsForm
            // setInitFormValues={setInitFormValues}
            steps={steps}
            form={form}
            loading={loading}
          />
        </Form>
      )}
      {params?.state?.type === "Inviter un collaborateur" && (
        <SelfAccountForm
          employees={employees}
          // setInitFormValues={setInitFormValues}
        />
      )}
    </div>
  );
};

export default EmployeeForm;

import React from "react";
import UserSkills from "./UserSkills";

const SkillsGrid = () => {
  return (
    <div className="pt-2">
      <div className="mb-3">
        <h4>Grille des Compétances</h4>
      </div>
      <div className="bg-white p-4 rounded-4 shadow-sm ">
        <div>
          <UserSkills />
        </div>
      </div>
    </div>
  );
};

export default SkillsGrid;

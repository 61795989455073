import React, { useContext, useEffect, useState } from "react";
import { Table, message, Tag, Button, Space } from "antd";
import GlobalContext from "../../../Context/GlobalContext";
import axiosInstance from "../../../Utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import AccessDrawer from "./AccessDrawer";

const UsersAccessList = () => {
  const { rolePrevilleges } = useContext(GlobalContext);
  const [access, setAccess] = useState([]);
  const columns = [
    {
      title: "Utilisateur",
      dataIndex: "userFullName",
    },
    {
      title: "Service",
      render: (_, record) => record?.service?.name,
    },
    {
      title: "Login",
      dataIndex: "userName",
    },
    {
      title: "Serveur(s) assosié(s)",
      render: (_, record) => (
        <div className="row px-3">
          <Tag color="blue" className="col-auto" key={record.server._id}>
            {record.server.hostName}
          </Tag>
        </div>
      ),
    },
    {
      title: "Actions",
      render: (_, record) => (
        <Space>
          <Button size="small" type="primary" shape="circle">
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Button size="small" type="primary" shape="circle" danger>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
          <AccessDrawer record={record} />
        </Space>
      ),
    },
  ];

  const handleExport = () => {
    const workbook = XLSX.utils.book_new();
    const renamedAccess = access.map((item) => {
      return {
        Matricule: item.userMatricule,
        Utilisateur: item.userFullName,
        Service: item?.service?.name,
        Login: item?.userName,
        Serveur: item?.server?.hostName ?? "N/A",
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(renamedAccess);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Access Data");
    XLSX.writeFile(
      workbook,
      `access_data_${dayjs().format("DD-MM-YYYY-HH-mm")}.xlsx`
    );
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get("/api/administration/access/");

        setAccess(data?.data);
        return data;
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
    fetchData();
  }, []);

  return (
    <div
      className="bg-white p-4 rounded shadow-sm"
      style={{ minHeight: "80vh" }}
    >
      <div className="mb-3 d-flex justify-content-between">
        <h4 className="fw-bold">🔑 Accès utilisateurs</h4>
        <Space>
          <Button type="primary" shape="round" onClick={handleExport}>
            Exporter
          </Button>
          <Button shape="round">Ajouter</Button>
        </Space>
      </div>
      <Table columns={columns} dataSource={access} size="small" bordered />
    </div>
  );
};

export default UsersAccessList;

import { Tooltip, message } from "antd";
import dayjs from "dayjs";
import axiosInstance from "../../../Utils/axios";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEquals } from "@fortawesome/free-solid-svg-icons";

const SelfKPI = ({ kpis }) => {

  return (
    <div className="w-100 ">
      <h1 style={{ fontWeight: "500", fontSize: "20px" }} className="pb-2">
        🎯 Mes KPIS{" "}
        <span style={{ fontWeight: "300" }}>depuis le début de</span>{" "}
        {dayjs().format("YYYY")}
      </h1>
      <div className="row">
        <div className="col-lg-3 col-md-12 col-sm-12  my-2">
          <Tooltip
            title="Total de jours de travail cumulé sans absence de reporting"
            className="h-100"
          >
            <div
              className="d-flex flex-column justify-content-center align-items-center p-3 h-100"
              style={{
                background: "rgba(238, 121, 133, .2)",
                borderRadius: "10px",
                color: "#EE7985",
              }}
            >
              <h1
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  textAlign: "center",
                  color: "black",
                }}
              >
                Dernière Absence de <br /> Reporting Il y a
              </h1>
              <div
                className="d-flex flex-column justify-content-center align-items-center rounded-3 px-3 py-2"

              >
                <span style={{ fontWeight: "600", fontSize: "28px" }}>
                  {kpis?.taux_respect_delais_reporting}
                </span>
                <span
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Jour(s)
                </span>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center mt-2"></div>
            </div>
          </Tooltip>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12  my-2">
          <Tooltip
            title={
              <div>
                Proportion de demandes de congés soumises qui ont été approuvées
                par l'entreprise.
              </div>
            }
          >
            <div
              className="d-flex flex-column justify-content-center align-items-center p-3 h-100"
              style={{
                background: "rgba(42, 150, 255, .15)",
                borderRadius: "10px",
                color: "#2A96FF",
              }}
            >
              <h1
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  textAlign: "center",
                  color: "black",
                }}
              >
                Taux d'Approbation <br /> des Congés
              </h1>

              <div
                className="d-flex flex-column justify-content-center align-items-center rounded-3 px-3 py-2"

              >
                <span style={{ fontWeight: "600", fontSize: "28px" }}>
                  {kpis?.taux_approbation_conges
                    ? Math.round(kpis?.taux_approbation_conges)
                    : "0"}
                  %
                </span>
                <span
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Approuvé(s)
                </span>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center mt-2"></div>
            </div>
          </Tooltip>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12  my-2">
          <Tooltip title="Total de jours de travail sans congé">
            {" "}
            <div
              className="d-flex flex-column justify-content-center align-items-center  p-3 h-100"
              style={{
                background: "rgba(255, 163, 24, .15)",
                borderRadius: "10px",
                color: "#FFA318",
              }}
            >
              <h1
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  textAlign: "center",
                  color: "black",
                }}
              >
                Cumul Depuis <br /> Dérnier Congé
              </h1>

              <div
                className="d-flex flex-column justify-content-center align-items-center rounded-3 px-3 py-2"

              >
                <span style={{ fontWeight: "600", fontSize: "28px" }}>
                  {kpis?.total_jours_travail_sans_conge}
                </span>
                <span
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Jour(s)
                </span>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center mt-2"></div>
            </div>
          </Tooltip>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12  my-2">
          <Tooltip title="Total d'autorisations de sortie">
            <div
              className="d-flex flex-column justify-content-center align-items-center p-3 h-100"
              style={{
                background: "rgba(121, 121, 121, .15)",
                borderRadius: "10px",
                color: "#797979",
              }}
            >
              <h1
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  textAlign: "center",
                  color: "black",
                }}
              >
                Total des Autorisations <br /> de Sortie
              </h1>

              <div className="row justify-content-evenly align-items-center w-100">
                <div
                  className="col-lg-5 d-flex flex-column justify-content-center align-items-center rounded-3  p-2"

                >
                  <span style={{ fontWeight: "600", fontSize: "28px" }}>
                    {kpis?.autorisations_number}
                  </span>
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      textAlign: "center",
                    }}
                  >
                    <Tooltip title="Autorisation(s)">Autoris.</Tooltip>
                  </span>
                </div>
                <div className="col-lg-2">
                  <FontAwesomeIcon icon={faEquals} />
                </div>
                <div
                  className="col-lg-5 d-flex flex-column justify-content-center align-items-center rounded-3 p-2"

                >
                  <span style={{ fontWeight: "600", fontSize: "28px" }}>
                    {kpis?.total_autorisations_sortie}
                  </span>
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      textAlign: "center",
                    }}
                  >
                    Heure(s)
                  </span>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center mt-2"></div>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default SelfKPI;

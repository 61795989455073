import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input, Space, Checkbox, message } from "antd";
import axiosInstance from "../../../Utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

const EditService = ({ services, setServices, servers, record }) => {
  const [open, setOpen] = useState(false);
  const [selectedServers, setSelectedServers] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(record);
    setSelectedServers(record?.server?.map((elem) => elem?._id));
  }, [record]);

  const onChange = (list) => {
    setSelectedServers(list);
    form.setFieldValue(
      "server",
      list.map((elem, idx) => {
        return { _id: elem, url: form.getFieldValue("server")[idx]?.url ?? "" };
      })
    );
  };

  const handleSubmit = async (values) => {
    try {
      const { data } = await axiosInstance.patch(
        "/api/administration/service/update/" + record?._id,
        values
      );

      const temp = services.map((elem) => {
        return elem?._id === record?._id ? data?.data?.service : elem;
      });
      setServices(temp);
      setOpen(false);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Button
        type="primary"
        size="small"
        shape="circle"
        onClick={() => setOpen(true)}
      >
        <FontAwesomeIcon icon={faPen} />
      </Button>
      <Modal
        title="✅ Nouveau service"
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={800}
        footer={[
          <Space>
            <Button key="back" onClick={() => setOpen(false)}>
              Annuler
            </Button>
            <Button key="submit" type="primary" onClick={() => form.submit()}>
              Enregistrer
            </Button>
          </Space>,
        ]}
      >
        <Form
          onFinish={handleSubmit}
          form={form}
          layout="vertical"
          className="row border rounded-3 p-3"
        >
          <Form.Item
            className="col-lg-6"
            name={"name"}
            label="Nom du service"
            rules={[
              {
                whitespace: true,
                required: true,
                message: "Le nom du service est requis",
              },
            ]}
          >
            <Input placeholder="Nom du service" />
          </Form.Item>
          <div className="border rounded-3 py-2 shadow-sm my-2">
            <Form.Item label="Serveur(s) associé(s)">
              <Checkbox.Group
                onChange={onChange}
                value={selectedServers}
                options={servers?.map((elem) => {
                  console.log(elem?.pseudo);
                  return {
                    label:
                      elem?.pseudo !== "" && elem?.pseudo
                        ? elem?.pseudo
                        : elem?.hostName,
                    value: elem?._id,
                  };
                })}
              />
            </Form.Item>
            <Form.List name="server">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => {
                    return (
                      <>
                        <p>
                          URL de{" "}
                          {servers.find(
                            (elem) =>
                              elem._id ===
                              form.getFieldValue("server")[key]?._id
                          )?.pseudo !== "" &&
                          servers.find(
                            (elem) =>
                              elem._id ===
                              form.getFieldValue("server")[key]?._id
                          )?.pseudo
                            ? servers.find(
                                (elem) =>
                                  elem._id ===
                                  form.getFieldValue("server")[key]?._id
                              )?.pseudo
                            : servers.find(
                                (elem) =>
                                  elem._id ===
                                  form.getFieldValue("server")[key]?._id
                              )?.hostName}{" "}
                          :
                        </p>
                        <div key={key} className="row">
                          <Form.Item
                            className="col-3"
                            {...restField}
                            name={[name, "_id"]}
                          >
                            <Input disabled />
                          </Form.Item>
                          <Form.Item
                            className="col-9"
                            {...restField}
                            name={[name, "url"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing URL",
                              },
                            ]}
                          >
                            <Input placeholder="URL" />
                          </Form.Item>
                          {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                        </div>
                      </>
                    );
                  })}
                </>
              )}
            </Form.List>
          </div>
          <Form.Item name={"description"} label="Description">
            <Input.TextArea placeholder="Description" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default EditService;

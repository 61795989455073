import { Button, Popconfirm, Space, Table, Tag, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../../../Utils/axios";
import dayjs from "dayjs";
import GlobalContext from "../../../Context/GlobalContext";

const PlannedTasks = () => {
    const { rolePrevilleges } = useContext(GlobalContext)
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);

    const columns = [
        {
            title: "Tâche",
            dataIndex: "description",
        },
        {
            title: "Date de déclenchement",
            dataIndex: "triggerDate",
            render: (text) => <>{dayjs(text).format("DD/MM/YYYY")}</>,
        },
        {
            title: "Statut",
            dataIndex: "status",
            render: (text) => (
                <>
                    {text === "pending" ? (
                        <Tag color="warning">En Attente</Tag>
                    ) : text === "cancelled" ? (
                        <Tag>Annulée</Tag>
                    ) : (
                        <Tag color="success">Terminé</Tag>
                    )}
                </>
            ),
        },
        {
            title: "Action",
            render: (_, record) => (
                <Space>
                    {rolePrevilleges?.previllegesList?.find(
                        (elem) => elem?.code === "CANCEL_SCHEDULED_RESIGNATION"
                    ) &&
                        <Popconfirm disabled={record?.status !== "pending"}
                            placement="topLeft"
                            title="Etes-vous sûr d'annuler cette tâche ?"
                            okText="Oui"
                            cancelText="Non"
                            onConfirm={() => handleCancel(record?._id)}
                        >
                            <Button disabled={record?.status !== "pending"} size="small" shape="round">
                                Annuler
                            </Button>
                        </Popconfirm>}
                    <Button size="small" shape="round">
                        Consulter
                    </Button>
                </Space>
            ),
        },
    ];

    const handleCancel = async (id) => {
        try {
            const { data } = await axiosInstance.patch(`/api/plan/cancel/${id}`);

            setTasks(
                tasks.map((elem) => {
                    if (elem?._id === id) {
                        elem.status = "cancelled";
                    }
                    return elem;
                })
            );
            message.success(data?.message);
        } catch (error) {
            message.error(error?.response?.data?.message);
        }
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const { data } = await axiosInstance.get("/api/plan/");

                setTasks(data?.tasks);
                setLoading(false);
            } catch (error) {
                message.error(error?.response?.data?.message);
                setLoading(false);
            }
        }
        fetchData();
    }, []);
    return (
        <div className="bg-white p-4 rounded-4 shadow-sm ">
            <div className="mb-3">
                <h4>Tâches planifiées</h4>
            </div>
            <Table columns={columns} loading={loading} dataSource={tasks} />
        </div>
    );
};

export default PlannedTasks;

import React, { useState, useEffect } from "react";
import { Button, Steps, Spin, Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const { Step } = Steps;

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
function StepsForm({ steps, form, loading, setInitFormValues }) {
  const [current, setCurrent] = useState(0);
const Navigate=useNavigate()
  const next = () => {
    form.validateFields().then(() => {
      setCurrent(current + 1);
    });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(
    getWindowSize().innerWidth <= 992
  );

  useEffect(() => {
    function handleWindowResize() {
      if (getWindowSize().innerWidth < 992) {
        setWindowSize(true);
      } else {
        setWindowSize(false);
      }
    }
    window.addEventListener("resize", handleWindowResize);

    handleWindowResize();
    // disabled-warning-next-line
  }, []);

  return (
    <>
      <Steps
        direction={windowSize ? "vertical" : "horizontal"}
        current={current}
      >
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <Spin indicator={antIcon} spinning={loading}>
        <div className="my-5 p-4 rounded shadow-md row">
          <div className="steps-content py-4 ">{steps[current].content}</div>

          <Space className="steps-action border-top pt-3">
            {current >= 0 && (
              <Button
                shape="round"
                style={{
                  margin: "0 8px",
                }}
                onClick={() => {
                  if (current === 0) {
                    Navigate('/administration/employees/list')

                  } else {
                    prev();
                  }
                }}
              
              >
                {current !== 0 && <FontAwesomeIcon
                  icon={faArrowLeft}
                  style={{ marginRight: "5px", marginTop: "5px" }}
                />}
                { current === 0? "Annuler " :"Précédent"}
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button shape="round" type="primary" onClick={() => next()}>
                Suivant{" "}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                />
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button shape="round" type="primary" htmlType="submit">
                Soumettre
              </Button>
            )}
          </Space>
        </div>
      </Spin>
    </>
  );
}

export default StepsForm;

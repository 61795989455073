import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "./Reducers";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import AppContext from "./Context/AppContext";
import moment from "moment";
import "moment/locale/fr";
import "dayjs/locale/fr";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

dayjs.locale("fr");
moment.locale("fr");

moment.tz("Africa/Tunis");
dayjs.tz.setDefault("Africa/Tunis");

const store = createStore(reducers, compose(applyMiddleware(thunk)));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <AppContext>
        <App />
      </AppContext>
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Space, Tag, Typography } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../../Context/GlobalContext";
// import axiosInstance from "../../../Utils/axios";

const TodoItem = ({ data, items, setItems }) => {
  const { apiCollab } = useContext(GlobalContext);
  const Navigate = useNavigate();

  // const handleFinish = async () => {
  //     try {
  //         const response = await axiosInstance.patch(
  //             "/api/todo/close/" + data?._id
  //         );
  //         if (response?.data?.status === "success") {
  //             message.success("Tâche terminé!");
  //             setItems([...items?.filter((item) => item?._id !== data?._id)]);
  //         }
  //     } catch (error) {
  //         message.error(error?.response?.data?.message);
  //     }
  // };

  return (
    <Card className="my-3">
      <div className="d-flex justify-content-between">
        <h6>{data?.title}</h6>
        <Tag color={data?.status === "open" && "warning"} className="mb-2">
          {data?.status === "open" ? "En attente" : "N/A"}
        </Tag>
      </div>
      <Typography.Paragraph>
        <div dangerouslySetInnerHTML={{ __html: data?.description }} />
      </Typography.Paragraph>
      <Space>
        {JSON.parse(data?.dumpJson)?.path && (
          <Button
            size="small"
            shape="round"
            icon={<FontAwesomeIcon icon={faArrowRight} />}
            onClick={() => {
              if (JSON.parse(data?.dumpJson)?.path) {
                apiCollab.info({
                  duration: 0,
                  message: data?.title,
                });
                Navigate(JSON.parse(data?.dumpJson)?.path);
              }
            }}
          >
            Traiter
          </Button>
        )}
        {/* <Button type='primary' shape='round' size='small' icon={<FontAwesomeIcon icon={faCheck} />} onClick={handleFinish}>Terminer</Button> */}
      </Space>
    </Card>
  );
};

export default TodoItem;

import { useState, useEffect } from "react";
import axios from "../../Utils/axios";
import { Form, Input, Button, Typography, Spin, Divider } from "antd";
import LoginTag from "../../Assets/Fingerprint-pana.svg";
import Logo from "../../Assets/brand_large_dark.png";
import NeoLogo from "../../Assets/brand_large_light.svg"
import {jwtDecode} from "jwt-decode";
import { useNavigate } from "react-router-dom";
import LocalStorageService from "../../Utils/localStorageService";

import { LoadingOutlined } from "@ant-design/icons";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const { Text, Link } = Typography;

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [token, setToken] = useState("");

  const [role, setRole] = useState("");
  const [form] = Form.useForm();

  const Navigate = useNavigate();
  const { _setToken, _setRole } = props;

  const handleLogin = async (formValues) => {
    setLoading(true);
    try {
      const { data } = await axios.patch("/api/auth/login", formValues);
      if (data.status) {
        setToken(data.token);
        setRole(jwtDecode(data.token).Role);
        _setToken(data.token);
        _setRole(jwtDecode(data.token).Role);
      } else {
        setMessage("Le compte est désactivé.");
        setLoading(false);
      }
    } catch (error) {
      setMessage(error.response.data.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.clear();
    localStorage.removeItem("access_token");
  }, []);

  useEffect(() => {
    if (token) {
      LocalStorageService().setToken(token);
      if (role === "EMPLOYEE" || role === "TEAM_LEAD") {
        if (!props.maintainance) {
          Navigate("/maintenance");
        } else {
          Navigate("/");
        }
      } else {
        Navigate("/administration/");
        LocalStorageService().setNotif(true);
      }
    }
    // eslint-disable-next-line
  }, [role, token]);

  return (
    <div className="vh-100 bg-light vw-100" style={{ overflow: "hidden" }}>
      <div className="d-flex vw-100 px-0 m-0">
        <div className="col-lg-4 col-md-6 col-sm-12  bg-white p-5 vh-100 shadow-lg ">
          <div className="d-flex flex-column justify-content-between h-100">
            <div className="d-flex justify-content-center ">
            <img
              src={Logo}
              style={{
                height: "105px",
                cursor: "pointer",
                transition: "width 0.2s ease",
              }}
              alt="Neopolis"
            />
            </div>
            <div>
              <h3 className="mb-4 ">Bienvenue

              </h3>
              <h5 className="">
                Veuillez vous connecter pour accéder à votre compte.
              </h5>
              <Divider />
              <Form
                disabled={loading}
                form={form}
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 24 },
                }}
                layout="vertical"
                size="large"
                onFinish={handleLogin}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "S’il vous plaît entrer votre email!",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({
                        email: e.target.value.replace(/ /g, ""),
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Mot de passe"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "S’il vous plaît entrer votre mot de passe!",
                    },
                  ]}
                >
                  <Input.Password
                    onChange={(e) => {
                      form.setFieldsValue({
                        password: e.target.value.replace(/ /g, ""),
                      });
                    }}
                  />
                </Form.Item>
                {message && <Text type="danger">{message}</Text>}
                <Form.Item>
                  <div className="d-flex justify-content-between align-items-center flex-column flex-xl-row">
                    <Button type="primary" className="my-1" htmlType="submit" shape="round">
                      Se connecter
                    </Button>
                    {loading && (
                      <Spin
                        style={{ marginLeft: "10px" }}
                        size="large"
                        indicator={antIcon}
                      />
                    )}
                    <Link
                      className="my-1 fw-bold"
                      onClick={() => {
                        Navigate("/forgot-password");
                      }}
                    >
                      Mot de passe oublié ?

                    </Link>
                  </div>
                </Form.Item>
              </Form>
            </div>
            <div>
              <div className="d-flex justify-content-between">
                <Text type="primary" className="mt-2" strong>
                  ©2023 Neopolis Development
                </Text>
                <img src={NeoLogo} style={{ height: "35px" }} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-6 col-sm-12 justify-content-center d-none d-lg-flex d-md-flex ">
          <img src={LoginTag} alt="LOGIN" style={{ width: "85%" }} />
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import {
  Button,
  Collapse,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Radio,
  Select,
  Space,
  notification,
  message,
  Upload,
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faKey,
  faLink,
  faLock,
  faServer,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../../Utils/axios";

const { Dragger } = Upload;

const status = [
  {
    label: "Activer",
    value: true,
  },
  {
    label: "Désactiver",
    value: false,
  },
];

const { Panel } = Collapse;
const EditAccessDrawer = ({ data, record, index, refresh, setRefresh }) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      ...data?.form,
    });
  }, [data]);

  const handleUpload = async (info) => {
    let fileList = [...info.fileList];
    // Accept 5 files only
    fileList = fileList.slice(-5);

    fileList.forEach(function (file, index) {
      let reader = new FileReader();
      reader.onload = (e) => {
        file.base64 = e.target.result;
      };
      reader.readAsDataURL(file.originFileObj);
    });

    setFileList(fileList);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleForm = () => {
    form
      .validateFields()
      .then((res) => {
        let formData = res;
        if (formData.vpn) {
          formData.vpn.file_url = fileList || data?.vpn?.file_url;
        }

        axiosInstance
          .patch("/api/access/save/" + record?.sender_id, formData)
          .then((response) => {
            axiosInstance
              .patch(`/api/request/access/sysadmin/${record?._id}`, {
                status: true,
                motif: "N/A",
              })
              .then((response) => {
                message.success(response?.data?.message);
                onClose();
                setRefresh(!refresh);
              })
              .catch((error) => message.error(error?.response?.data?.message));
          })
          .catch((err) =>
            api.error({
              message: err?.response?.data?.message,
              placement: "topLeft",
              duration: 4,
            })
          );
      })
      .catch((error) => {
        api.error({
          message: error?.errorFields[0]?.errors[0],
          placement: "topLeft",
          duration: 4,
        });
      });
  };

  return (
    <>
      <Button
        size="small"
        type="primary"
        disabled={
          record?.answered_by.length !== index || record?.status !== "PENDING"
        }
        onClick={showDrawer}
      >
        Approuver
      </Button>
      <Drawer
        title="Modifier l'accès"
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Annuler</Button>
            <Button onClick={handleForm} type="primary">
              Modifier
            </Button>
          </Space>
        }
      >
        {contextHolder}
        <Form layout="vertical" form={form}>
          {/* GIT */}
          <Collapse ghost expandIconPosition={"end"} defaultActiveKey={["1"]}>
            <Panel
              header={
                <span style={{ fontSize: "16px", fontWeight: "500" }}>
                  GitLab
                </span>
              }
              className="border-bottom rounded-0"
              key="1"
            >
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <Form.Item label="Username" name={["git", "user_name"]}>
                    <Input prefix={<FontAwesomeIcon icon={faUser} />} />
                  </Form.Item>
                </div>
                <div className="col-lg-6 col-md-12">
                  <Form.Item label="Email" name={["git", "email"]}>
                    <Input
                      onChange={(e) => {
                        form.setFieldsValue({
                          email: e.target.value.replace(/ /g, ""),
                        });
                      }}
                      prefix={<FontAwesomeIcon icon={faAt} />}
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-6 col-md-12">
                  <Form.Item name={["git", "url"]} label="Lien">
                    <Input
                      suffix={<FontAwesomeIcon icon={faLink} />}
                    />
                  </Form.Item>
                </div>
              </div>
            </Panel>
            <Panel
              header={
                <span style={{ fontSize: "16px", fontWeight: "500" }}>
                  ActiveCollab
                </span>
              }
              className="border-bottom rounded-0"
              key="2"
            >
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <Form.Item
                    label="Username"
                    name={["activeCollab", "user_name"]}
                  >
                    <Input prefix={<FontAwesomeIcon icon={faUser} />} />
                  </Form.Item>
                </div>
                <div className="col-lg-6 col-md-12">
                  <Form.Item label="Email" name={["activeCollab", "email"]}>
                    <Input prefix={<FontAwesomeIcon icon={faAt} />} />
                  </Form.Item>
                </div>
                <div className="col-lg-6 col-md-12">
                  <Form.Item name={["activeCollab", "url"]} label="Lien">
                    <Input
                      suffix={<FontAwesomeIcon icon={faLink} />}
                    />
                  </Form.Item>
                </div>
              </div>
            </Panel>
            <Panel
              header={
                <span style={{ fontSize: "16px", fontWeight: "500" }}>
                  Jenkins
                </span>
              }
              className="border-bottom rounded-0"
              key="3"
            >
              <div className="row">
                <Form.List name="jenkins">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div key={key} className="row bg-light mb-2 rounded-3 pt-2 ">
                          <div className="col-lg-6 col-md-12">
                            <Form.Item
                              {...restField}
                              name={[name, "user_name"]}
                              label="Username"
                            >
                              <Input
                                prefix={<FontAwesomeIcon icon={faUser} />}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <Form.Item
                              {...restField}
                              name={[name, "email"]}
                              label="Email"
                            >
                              <Input
                                prefix={<FontAwesomeIcon icon={faAt} />}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <Form.Item
                              {...restField}
                              name={[name, "password"]}
                              label="Password"
                            >
                              <Input.Password
                                prefix={<FontAwesomeIcon icon={faKey} />}
                              />
                            </Form.Item>
                          </div>

                          <div className="col-lg-6 col-md-12">
                            <Form.Item
                              {...restField}
                              name={[name, "url"]}
                              label="Lien"
                            >
                              <Input
                                suffix={<FontAwesomeIcon icon={faLink} />}
                              />
                            </Form.Item>
                          </div>

                          <div className="col-lg-6 col-md-12">
                            <Form.Item
                              {...restField}
                              label={<></>}
                              className="d-flex justify-content-end"
                              style={{ marginTop: "-2px" }}
                            >
                              <Space>
                                <Popconfirm
                                  placement="topLeft"
                                  title="Êtes-vous sûr de vouloir supprimer cet accès"
                                  onConfirm={() => remove(name)}
                                >
                                  <Button
                                    type="primary"
                                    danger
                                    icon={<MinusCircleOutlined />}
                                  >
                                    Supprimer
                                  </Button>
                                </Popconfirm>
                              </Space>
                            </Form.Item>
                          </div>
                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          type="link"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Nouveau
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </div>
            </Panel>
            <Panel
              header={
                <span style={{ fontSize: "16px", fontWeight: "500" }}>
                  Plesk
                </span>
              }
              className="border-bottom rounded-0"
              key="4"
            >
              <div className="row">
                <Form.List name="plesk">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div key={key} className="row bg-light mb-2 rounded-3 pt-2 ">
                          <div className="col-lg-6 col-md-12">
                            <Form.Item
                              {...restField}
                              name={[name, "user_name"]}
                              label="Username"
                            >
                              <Input
                                prefix={<FontAwesomeIcon icon={faUser} />}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <Form.Item
                              {...restField}
                              name={[name, "email"]}
                              label="Email"
                            >
                              <Input
                                prefix={<FontAwesomeIcon icon={faAt} />}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <Form.Item
                              {...restField}
                              name={[name, "password"]}
                              label="Password"
                            >
                              <Input.Password
                                prefix={<FontAwesomeIcon icon={faKey} />}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <Form.Item
                              {...restField}
                              name={[name, "email"]}
                              label="Email"
                            >
                              <Input prefix={<FontAwesomeIcon icon={faAt} />} />
                            </Form.Item>
                          </div>

                          <div className="col-lg-6 col-md-12">
                            <Form.Item
                              {...restField}
                              name={[name, "url"]}
                              label="Lien"
                            >
                              <Input
                                addonBefore="http://"
                                defaultValue={"m1511.contaboserver.net:6969"}
                                suffix={<FontAwesomeIcon icon={faLink} />}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <Form.Item
                              {...restField}
                              label={<></>}
                              className="d-flex justify-content-end"
                              style={{ marginTop: "-2px" }}
                            >
                              <Space>
                                <Popconfirm
                                  placement="topLeft"
                                  title="Êtes-vous sûr de vouloir supprimer cet accès"
                                  onConfirm={() => remove(name)}
                                >
                                  <Button
                                    type="primary"
                                    danger
                                    icon={<MinusCircleOutlined />}
                                  >
                                    Supprimer
                                  </Button>
                                </Popconfirm>
                              </Space>
                            </Form.Item>
                          </div>
                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          type="link"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Nouveau
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </div>
            </Panel>
            <Panel
              header={
                <span style={{ fontSize: "16px", fontWeight: "500" }}>
                  Ionos
                </span>
              }
              className="border-bottom rounded-0"
              key="5"
            >
              <div className="row">
                <Form.List name="ionos">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div key={key} className="row bg-light mb-2 rounded-3 pt-2 ">
                          <div className="col-lg-6 col-md-12">
                            <Form.Item
                              {...restField}
                              name={[name, "email"]}
                              label="Email"
                            >
                              <Input prefix={<FontAwesomeIcon icon={faAt} />} />
                            </Form.Item>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <Form.Item
                              {...restField}
                              name={[name, "password"]}
                              label="Password"
                            >
                              <Input.Password
                                prefix={<FontAwesomeIcon icon={faKey} />}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <Form.Item
                              {...restField}
                              name={[name, "url"]}
                              label="Lien"
                            >
                              <Input
                                suffix={<FontAwesomeIcon icon={faLink} />}
                              />
                            </Form.Item>
                          </div>

                          <div className="col-lg-12 col-md-12">
                            <Form.Item
                              {...restField}
                              label={<></>}
                              className="d-flex justify-content-end"
                              style={{ marginTop: "-2px" }}
                            >
                              <Space>
                                <Popconfirm
                                  placement="topLeft"
                                  title="Êtes-vous sûr de vouloir supprimer cet accès"
                                  onConfirm={() => remove(name)}
                                >
                                  <Button
                                    type="primary"
                                    danger
                                    icon={<MinusCircleOutlined />}
                                  >
                                    Supprimer
                                  </Button>
                                </Popconfirm>
                              </Space>
                            </Form.Item>
                          </div>
                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          type="link"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Nouveau
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </div>
            </Panel>
            <Panel
              header={
                <span style={{ fontSize: "16px", fontWeight: "500" }}>VPN</span>
              }
              className="border-bottom rounded-0"
              key="6"
            >
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <Form.Item name={["vpn", "user_name"]} label="Username">
                    <Input prefix={<FontAwesomeIcon icon={faUser} />} />
                  </Form.Item>
                </div>
                <div className="col-lg-6 col-md-12">
                  <Form.Item name={["vpn", "password"]} label="Password">
                    <Input.Password prefix={<FontAwesomeIcon icon={faKey} />} />
                  </Form.Item>
                </div>
                <div className="col-lg-6 col-md-12">
                  <Form.Item
                    label={"Statut"}
                    name={["vpn", "status"]}
                    rules={[
                      {
                        required: true,
                        message: "Veuillez sélectionner un statut.",
                      },
                    ]}
                    style={{ marginTop: "-2px" }}
                  >
                    <Radio.Group
                      options={status}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Form.Item>
                </div>
                <Form.Item>
                  <Dragger
                    multiple={true}
                    onChange={handleUpload}
                    beforeUpload={() => false}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Cliquez ou faites glisser le fichier dans cette zone pour
                      le télécharger
                    </p>
                    <p className="ant-upload-hint">
                      Prise en charge d'un téléchargement unique ou groupé. Il
                      est strictement interdit de télécharger des données non
                      professionnelles ou d'autres fichiers interdits.
                    </p>
                  </Dragger>
                </Form.Item>
              </div>
            </Panel>
            <Panel
              header={
                <span style={{ fontSize: "16px", fontWeight: "500" }}>
                  Autre
                </span>
              }
              className="border-bottom rounded-0"
              key="7"
            >
              <div className="row">
                <Form.List name="others">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div key={key} className="row bg-light mb-2 rounded-3 pt-2 ">
                          <div className="col-lg-6 col-md-12">
                            <Form.Item
                              {...restField}
                              name={[name, "user_name"]}
                              label="Username"
                            >
                              <Input
                                prefix={<FontAwesomeIcon icon={faUser} />}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <Form.Item
                              {...restField}
                              name={[name, "email"]}
                              label="Email"
                            >
                              <Input prefix={<FontAwesomeIcon icon={faAt} />} />
                            </Form.Item>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <Form.Item
                              {...restField}
                              name={[name, "password"]}
                              label="Password"
                            >
                              <Input
                                prefix={<FontAwesomeIcon icon={faKey} />}
                              />
                            </Form.Item>
                          </div>

                          <div className="col-lg-6 col-md-12">
                            <Form.Item
                              label={"Statut"}
                              {...restField}
                              name={[name, "status"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Veuillez sélectionner un statut.",
                                },
                              ]}
                              style={{ marginTop: "-2px" }}
                            >
                              <Radio.Group
                                options={status}
                                optionType="button"
                                buttonStyle="solid"
                              />
                            </Form.Item>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <Form.Item
                              {...restField}
                              name={[name, "url"]}
                              label="Lien"
                            >
                              <Input
                                suffix={<FontAwesomeIcon icon={faLink} />}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <Form.Item
                              {...restField}
                              name={[name, "description"]}
                              label="Description"
                            >
                              <Input.TextArea />
                            </Form.Item>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <Form.Item
                              {...restField}
                              label={<></>}
                              className="d-flex justify-content-end"
                              style={{ marginTop: "-2px" }}
                            >
                              <Space>
                                <Popconfirm
                                  placement="topLeft"
                                  title="Êtes-vous sûr de vouloir supprimer cet accès"
                                  onConfirm={() => remove(name)}
                                >
                                  <Button
                                    type="primary"
                                    danger
                                    icon={<MinusCircleOutlined />}
                                  >
                                    Supprimer
                                  </Button>
                                </Popconfirm>
                              </Space>
                            </Form.Item>
                          </div>
                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          type="link"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Nouveau
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </div>
            </Panel>
          </Collapse>

          {/* ACTIVECOLAB */}

          {/* JENKINS */}
        </Form>
      </Drawer>
    </>
  );
};

export default EditAccessDrawer;

import { useState, useEffect } from "react";
import { Collapse, Input, Skeleton, Typography } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import axios from "../../Utils/axios";
import moment from "moment";
import FaqCard from "../../Admin/Components/FaqCard";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { Text } = Typography;

const { Panel } = Collapse;
const { Search } = Input;
const panelStyle = {
  marginBottom: 24,
  background: "#f2f5fb",
  borderRadius: "8px",
  border: "none",
};
const FAQ = () => {
  // eslint-disable-next-line
  const [holidays, setHolidays] = useState([]);
  const [faqs, setFAQS] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get("/api/faq/");
      setFAQS(data.faqs);
      return data.faqs;
    }

    const fetchHolidays = async () => {
      const { data } = await axios.get("/api/holidays/");

      setHolidays(data.holidays);

      return data;
    };

    fetchHolidays();
    fetchData();
  }, []);
  return (
    <div className="bg-white shadow-md rounded-4">
      <div className="mt-4 p-3">
        <div className="mb-4">
          <h4>❓ Foire Aux Questions </h4>
        </div>

        <div className="d-flex justify-content-center flex-column align-items-center ">
          {/* <Search
            placeholder="Rechercher..."
            allowClear
            // onSearch={onSearch}
            enterButton
            style={{
              width: 300,
            }}
            className="mb-4"
            size="large"
          /> */}
          {faqs.map((faq, idx) => {
            return <FaqCard faq={faq} idx={idx} access={false} />;
          })}

          {holidays.length ? (
            <Collapse
              expandIconPosition={"end"}
              style={{ width: "70%" }}
              bordered={false}
              expandIcon={({ isActive }) => (
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  style={{ fontSize: "24px", color: "#4764AC" }}
                  rotate={isActive ? 90 : 0}
                />
              )}
            >
              <Panel
                className="shadow-sm"
                header={
                  <span
                    className="text-muted"
                    style={{ fontWeight: "500", fontSize: "16px" }}
                  >
                    Les jours fériés ?
                  </span>
                }
                key={"1"}
                style={panelStyle}
              >
                {holidays.map((holiday, index) => {
                  return (
                    <div key={index} className="py-1 px-2">
                      {" "}
                      <Text>{holiday.label}</Text>
                      {" : "}
                      <span style={{ fontWeight: "500" }}>
                        {moment(holiday.Date).format("DD MMMM YYYY")}
                      </span>{" "}
                      - {holiday.Days}
                      Jour(s)
                    </div>
                  );
                })}
              </Panel>
            </Collapse>
          ) : (
            <Skeleton.Input active={true} size={"default"} block="true" />
          )}
        </div>
      </div>
    </div>
  );
};

export default FAQ;

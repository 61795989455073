import { useContext, useEffect, useState } from "react";
import {
  Space,
  Table,
  Tag,
  Typography,
  message,
  Form,
  Modal,
  Button,
  Tooltip,
  Popconfirm,
  Input,
  Steps,
} from "antd";

import {
  CloseCircleTwoTone,
  LoadingOutlined,
  CheckCircleTwoTone,
  MinusCircleTwoTone,
} from "@ant-design/icons";

import moment from "moment";
import dayjs from "dayjs";
import axios from "../../Utils/axios";
import UploadInput from "../../Components/UploadInput";
import GlobalContext from "../../Context/GlobalContext";
import { useNavigate } from "react-router-dom";
import TeleworkRequestsDrawer from "../../Drawers/TeleworkRequestsDrawer";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const { Text, Paragraph } = Typography;

const TeleworkList = () => {
  const { redirectData, profile } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const [size, setSize] = useState("default");

  const [leaves, setLeaves] = useState([]);
  // eslint-disable-next-line
  const [expand, setExpand] = useState(true);
  const [loading, setLoading] = useState(true);

  const [optionsTab, setOptions] = useState([
    "_typeLeave",
    "_posted_date",
    "_answered_by",
    "_event",
    "BeginingDate",
    "EndingDate",
    "Durée",
    "status",
  ]);

  const setFile = (base64) => {
    form.setFieldsValue({
      OptionalFiles: base64,
    });
  };

  const columns = [
    optionsTab.find((elem) => elem === "_posted_date")
      ? {
        title: "Soumis le",
        dataIndex: "_posted_date",
        sorter: (a, b) => moment(a._posted_date) - moment(b._posted_date),
        render: (_, requestedLeave) => (
          <Text strong>
            {requestedLeave._posted_date
              ? moment(requestedLeave._posted_date).format("DD/MM/YYYY HH:mm")
              : "N/A"}
          </Text>
        ),
      }
      : { width: 0, className: "d-none" },
   
    optionsTab.find((elem) => elem === "BeginingDate")
      ? {
        title: "Date de début",
        dataIndex: "BeginingDate",
        sorter: (a, b) => moment(a.BeginingDate) - moment(b.BeginingDate),
        render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
      }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "EndingDate")
      ? {
        title: "Date de Fin",
        dataIndex: "EndingDate",
        sorter: (a, b) => moment(a.EndingDate) - moment(b.EndingDate),
        render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
      }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "Durée")
      ? {
        title: "Durée",
        render: (_, requestedLeaves) => (
          <Text>
            {requestedLeaves._typeLeave === "Autorisation"
              ? requestedLeaves._duration
              : !requestedLeaves._is_half_day
                ? requestedLeaves.DaysNumber + " Jour(s)"
                : requestedLeaves._is_half_day}
          </Text>
        ),
      }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "status")
      ? {
        title: "Statut",
        dataIndex: "status",
        filters: [
          {
            text: <Tag color={"orange"}>En Attente</Tag>,
            value: "Pending",
          },

          {
            text: <Tag color={"green"}>Approuvée</Tag>,
            value: "Accepted",
          },

          {
            text: <Tag color={"cyan"}>En attente d'approbation</Tag>,
            value: "Validating",
          },

          {
            text: <Tag color={"red"}>Rejeté</Tag>,
            value: "Rejected",
          },

          {
            text: <Tag color="red">Annulée</Tag>,
            value: "Canceled",
          },

          {
            text: <Tag color={"red"}>Non encore justifié</Tag>,
            value: "Unjustified",
          },
          {
            text: <Tag>Échue</Tag>,
            value: "Failed",
          },
        ],
        defaultFilteredValue:
          redirectData?.type === "DEFAULT" && redirectData?.filters
            ? redirectData?.filters
            : [],
        onFilter: (value, record) => {
          return record.status.includes(value);
        },
        render: (_, record) => (
          <Steps size="small" items={[{
            title: "Chef D'équipe",
            status: record?.status === "Pending" ? record?.validatedByTL ? "finish" : "process" : "finish",
            icon: record?.status === "Pending" ? !record?.validatedByTL ? <LoadingOutlined /> : <CheckCircleTwoTone twoToneColor={"#87d068"} /> : record?.status !== "Accepted" ? record?.status === "Canceled" ? <MinusCircleTwoTone twoToneColor={"#b1b1b1"} /> : <CloseCircleTwoTone twoToneColor={"#f3531e"} /> : <CheckCircleTwoTone twoToneColor={"#87d068"} />,
          },
          {
            title: "Resources Humaine",
            status: record?.status === "Pending" ? record?.validatedByTL ? record?.validatedByHR ? "finish" : "process" : "finish" : "finish",
            icon: record?.status === "Pending" ? record?.validatedByTL ? record?.validatedByHR ? <CheckCircleTwoTone twoToneColor={"#87d068"} /> : <LoadingOutlined /> : <MinusCircleTwoTone twoToneColor={"#b1b1b1"} /> : record?.status !== "Accepted" ? record?.status === "Canceled" ? <MinusCircleTwoTone twoToneColor={"#b1b1b1"} /> : <CloseCircleTwoTone twoToneColor={"#f3531e"} /> : <CheckCircleTwoTone twoToneColor={"#87d068"} />,
          }
          ]} />
        ),
      }
      : { width: 0, className: "d-none" },

    {
      title: "Actions",
      render: (_, record) => (
        <>
          <Space>
            {record.status === "Pending" && (
              <>
              <Tooltip title="Annuler vote demande.">
                <Button
                  onClick={() => {
                    handleActions({
                      _id: record._id,
                      value: "Canceled",
                    });
                  }}
                  type="primary"
                  shape="circle"
                  size="small"
                >
                  <FontAwesomeIcon icon={faBan} />
                </Button>
                </Tooltip>
              </>
            )}
            {(record.status === "Rejected" ||
              record.status === "Failed" ||
              record.status === "Canceled" ||
              record.status === "Validating") && (
                <Tooltip title="Annuler vote demande.">
                <Button type="primary"   shape="circle" size="small" disabled>
                  <FontAwesomeIcon icon={faBan} />
                </Button>
                </Tooltip>
              )}
            {record.status === "Accepted" && (
              <Popconfirm
                placement="topLeft"
                disabled={moment().isSameOrAfter(moment(record.BeginingDate))}
                title="êtes-vous sûr d'annuler la demande"
                okText="Oui"
                onConfirm={() => {
                  Modal.info({
                    title: "Annuler la demande",
                    content: (
                      <>
                        <Form form={form} layout="vertical">
                          <Form.Item name="_motif" label="Motif:" required>
                            <Input.TextArea />
                          </Form.Item>
                        </Form>
                      </>
                    ),
                    onOk: () => {
                      handleCancel(record._id);
                    },
                  });
                }}
              >
                <Tooltip title="Annuler vote demande.">
                <Button
                  disabled={moment().isSameOrAfter(moment(record.BeginingDate))}
                  type="primary"
                  size="small"
                  shape="circle"
                >
                 <FontAwesomeIcon icon={faBan} />
                </Button>
                </Tooltip>
              </Popconfirm>
            )}
            <TeleworkRequestsDrawer record={record} />
          </Space>
        </>
      ),
    },
  ];

  const handleReminder = async (id) => {
    try {
      const { data } = await axios.post("/api/reminder/leave/" + id);

      if (data.status === "success") {
        message.success(data.message);
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const handleActions = async (options) => {
    try {
      const { data } = await axios.patch("/api/leaves/options/" + options._id, {
        status: options.value,
        self: true,
      });
      if (data.status === "success") {
        message.success(data.message);
        const newRequestedLeaves = leaves.map((RL) => {
          if (RL._id === options._id) {
            RL.status = options.value;
            RL._answered_at = dayjs();
            RL._answered_by = profile?.fullName;
          }
          return RL;
        });
        setLeaves(newRequestedLeaves);
      }
    } catch (error) { }
  };

  const handleCancel = async (id) => {
    const formValues = form.getFieldsValue();
    if (!formValues._motif) {
      return message.warning("Veuillez remplir le motif");
    }
    try {
      const { data } = await axios.patch("/api/leaves/cancel/" + id, {
        _motif: formValues._motif,
      });
      if (data.status === "success") {
        const temp = leaves.map((leave) => {
          if (leave._id === id) {
            leave.status = "Canceled";
          }
          return leave;
        });

        setLeaves(temp);
        message.success(data.message);
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  useEffect(() => {
    async function fetchLeaves() {
      const { data } = await axios.get("/api/leaves/self/telework");
      setLoading(false);
      setLeaves(data.Leaves);
      return data;
    }
    fetchLeaves();
  }, []);

  const Navigate = useNavigate();
  return (
    <div className="pt-4">
      <div className="bg-white p-4 rounded shadow-sm ">
        <div className="mb-3 d-flex justify-content-between">
          <h4>📋 Historique de mes demandes de Télétravail :</h4>
          <Button
            shape="round"
            onClick={() => Navigate("/request/telework")}
            type="primary"
          >
            Nouvelle demande
          </Button>
        </div>

        <Table
          loading={loading}
          size={size}
          columns={columns}
          scroll={{ x: "max-content" }}
          dataSource={leaves}
        />
      </div>
    </div>
  );
};

export default TeleworkList;

import { Button, DatePicker, Form, Modal, Select, message, Tooltip } from "antd";
import { useContext, useState } from "react";
import axios from "../../../Utils/axios";
import GlobalContext from "../../../Context/GlobalContext";
import { faUserTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AffectToModal = ({ employees, record }) => {
  const { equipments, setEquipments } = useContext(GlobalContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    const formValues = form.getFieldsValue();

    if (
      formValues._current_holder &&
      formValues._current_holder !== record._current_holder && formValues.affectationDate
    ) {
      try {
        const { data } = await axios.patch(
          "/api/equipments/affect/" + record._id,
          formValues
        );
        const tmp = equipments.map((elem) => {
          if (elem._id === record._id) {
            elem._status = "AFFECTED_TO";
            elem._current_holder = formValues._current_holder;
          }
          return elem;
        });
        setEquipments(tmp);
        message.success(data.message);
         setIsModalOpen(false);
      } catch (error) {
        message.error(error.response.data.message);
         setIsModalOpen(false);
      }
    }
    else{
      message.error("Veuillez remplir tous les champs obligatoires !");

    }
    
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
    <Tooltip title="Affecter l'équipement">
      <Button size="small" shape="circle" type="primary" onClick={showModal}>
      <FontAwesomeIcon icon={faUserTag} />
      </Button>
    </Tooltip>
      <Modal
        title="Affecter l'équipement à"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} 
            layout="vertical"

         >
          <Form.Item name="_current_holder" label="Collaborateur :" >
            <Select defaultValue={record._current_holder}>
              {employees?.map((elem) => {
                return (
                  <Select.Option value={elem.matricule}>
                    {elem.fullName} #{elem.matricule}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Date d'affectation :" name="affectationDate" >
            <DatePicker style={{width:"100%"}} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default AffectToModal;

import { useState, useEffect, useContext, useRef } from "react";
import {
  Table,
  Typography,
  Space,
  Spin,
  Button,
  Input,
  Form,
  Modal,
  InputNumber,
  message,
  Tooltip,
  Dropdown,
  Tag,
  Checkbox,
  Menu,
  notification,
  Popconfirm,
} from "antd";

import * as XLSX from "xlsx";
import {
  SearchOutlined,
  ExclamationCircleFilled,
  ColumnHeightOutlined,
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";

import { useNavigate } from "react-router-dom";

import axios from "../../../Utils/axios";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBoxArchive,
  faCoins,
  faFileExport,
  faIdCard,
  faPersonCircleQuestion,
  faUserLock,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

import GlobalContext from "../../../Context/GlobalContext";
import PlanResignationModal from "./PlanResignationModal";

const { Text } = Typography;
const { confirm } = Modal;

const renderRole = (role) => {
  switch (role) {
    case "SYS_ADMIN":
      return "Sys Admin";
    case "SUPER_ADMIN":
      return "Resources Humaines";
    case "EMPLOYEE":
      return "Collaborateur";
    case "TEAM_LEAD":
      return "Chef de projet";
    case "ACCOUNTANT":
      return "Comptable";
  }
};

const EmployeesList = () => {
  const [refresh, setRefresh] = useState(false);
  const { rolePrevilleges, role } = useContext(GlobalContext);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [soldForm] = Form.useForm();
  const [colonnes, setColonnes] = useState([]);
  const [size, setSize] = useState("small");
  const [tempEmployees, setTempEmployess] = useState([]);

  const options = [
    {
      label: (
        <Tag
          color={colonnes.includes("Nom et prénom") ? "blue" : "default"}
          style={{
            fontSize: "18px",
            padding: "5px 10px",
            marginBlock: "0.5rem",
          }}
        >
          Nom et prénom
        </Tag>
      ),
      value: "Nom et prénom",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Matricule") ? "blue" : "default"}
          style={{
            fontSize: "18px",
            padding: "5px 10px",
            marginBlock: "0.5rem",
          }}
        >
          Matricule
        </Tag>
      ),
      value: "Matricule",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Numéro CIN") ? "blue" : "default"}
          style={{
            fontSize: "18px",
            padding: "5px 10px",
            marginBlock: "0.5rem",
          }}
        >
          Numéro CIN
        </Tag>
      ),
      value: "Numéro CIN",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Date d'embauche") ? "blue" : "default"}
          style={{
            fontSize: "18px",
            padding: "5px 10px",
            marginBlock: "0.5rem",
          }}
        >
          Date d'embauche
        </Tag>
      ),
      value: "Date d'embauche",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Email personnel") ? "blue" : "default"}
          style={{
            fontSize: "18px",
            padding: "5px 10px",
            marginBlock: "0.5rem",
          }}
        >
          Email personnel
        </Tag>
      ),
      value: "Email personnel",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Adresse") ? "blue" : "default"}
          style={{
            fontSize: "18px",
            padding: "5px 10px",
            marginBlock: "0.5rem",
          }}
        >
          Adresse
        </Tag>
      ),
      value: "Adresse",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Date de naissance") ? "blue" : "default"}
          style={{
            fontSize: "18px",
            padding: "5px 10px",
            marginBlock: "0.5rem",
          }}
        >
          Date de naissance
        </Tag>
      ),
      value: "Date de naissance",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Type de contrat") ? "blue" : "default"}
          style={{
            fontSize: "18px",
            padding: "5px 10px",
            marginBlock: "0.5rem",
          }}
        >
          Type de contrat
        </Tag>
      ),
      value: "Type de contrat",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Solde de congé") ? "blue" : "default"}
          style={{
            fontSize: "18px",
            padding: "5px 10px",
            marginBlock: "0.5rem",
          }}
        >
          Solde de congé
        </Tag>
      ),
      value: "Solde de congé",
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() =>
          Navigate("/administration/employees/form", {
            state: {
              type: "Inviter un collaborateur",
            },
          })
        }
      >
        Inviter un collaborateur
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          Navigate("/administration/employees/form", {
            state: {
              type: "Ajouter un collaborateur",
            },
          })
        }
      >
        Ajouter un collaborateur
      </Menu.Item>
    </Menu>
  );

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleChangeSold = async (matricule) => {
    try {
      const formValues = soldForm.getFieldsValue();
      // eslint-disable-next-line
      const { data } = await axios.patch(
        "/api/employees/solde/" + matricule,
        formValues
      );
      message.success("Mise à jour avec succès");
      const temp = employees.map((employee) => {
        if (employee.matricule === matricule) {
          employee.sold = formValues.sold;
        }
        return employee;
      });

      setEmployees(temp);
    } catch (error) {}
  };

  const showConfirm = (matricule, sold) => {
    confirm({
      title: "Voulez-vous modifier le sold?",
      icon: <ExclamationCircleFilled />,
      content: (
        <Form form={soldForm} layout="vertical" className="pt-4">
          <Form.Item name="sold" label={<Text strong>Sold</Text>}>
            <InputNumber defaultValue={sold} />
          </Form.Item>
          <Form.Item name="message" label={<Text strong>Motif</Text>}>
            <Input.TextArea placeholder="N'hésitez pas à détailler la cause de votre demande pour une meilleure compréhension et pour une gestion plus efficace." />
          </Form.Item>
        </Form>
      ),
      okText: "Modifier",
      onOk() {
        handleChangeSold(matricule);
      },
      onCancel() {},
    });
  };

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Recherche ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            // style={{
            //   width: 90,
            // }}
          >
            Recherche
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      // console.log(employees?.filter((elem) => elem[dataIndex].toString().toLowerCase().includes(value.toLowerCase())))
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Matricule",
      dataIndex: "matricule",
      ...getColumnSearchProps("matricule", "Matricule"),
    },
    {
      title: "Nom et prénom",
      dataIndex: "fullName",
      ...getColumnSearchProps("fullName", "Nom et prénom"),
    },
    {
      title: "Date d'embauche",
      dataIndex: "embaucheDate",
      sorter: (a, b) => moment(a.embaucheDate) - moment(b.embaucheDate),
      render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
    },
    {
      title: "Numéro Téléphone",
      dataIndex: "phoneNum",
    },
    {
      title: "Poste",
      dataIndex: "Poste",
    },
    role !== "SYS_ADMIN"
      ? {
          title: "Solde",
          dataIndex: "sold",
          sorter: (a, b) => a.sold - b.sold,
        }
      : {
          title: "Role",
          render: (_, record) =>
            record?.sub_role
              ? renderRole(record?.sub_role)
              : renderRole(record?.role),
        },

    {
      title: "Actions",
      render: (_, employee) => (
        <Space size="small">
          <Tooltip title="Voir le profil">
            <Button
              shape="circle"
              size="small"
              type="primary"
              onClick={() => {
                Navigate("/administration/employees/" + employee.matricule);
              }}
            >
              <FontAwesomeIcon icon={faIdCard} />
            </Button>
          </Tooltip>

          {rolePrevilleges?.previllegesList?.find(
            (elem) => elem?.code === "ENABLE_ACCOUNT"
          ) &&
            employee.status === false && (
              <Popconfirm
                okText="Oui"
                cancelText="Non"
                title="Débloquer le collaborateur"
                description={`Etes-vous sûr de débloquer ${employee?.fullName} ?`}
                onConfirm={async () => {
                  await axios.patch("/api/auth/activate/" + employee.matricule);
                  setRefresh(!refresh);
                }}
              >
                <Tooltip title="Débloquez le collaborateur">
                  <Button shape="circle" size="small">
                    <FontAwesomeIcon icon={faUserPlus} />
                  </Button>
                </Tooltip>
              </Popconfirm>
            )}

          {rolePrevilleges?.previllegesList?.find(
            (elem) => elem?.code === "DISABLE_ACCOUNT"
          ) &&
            employee?.status === true && (
              <Tooltip title="Archiver le collaborateur">
                <Dropdown
                  overlay={
                    <Menu>
                      {rolePrevilleges?.previllegesList?.find(
                        (elem) => elem?.code === "SCHEDULE_RESIGNATION"
                      ) &&
                        employee?.status === true && (
                          <PlanResignationModal record={employee} />
                        )}
                      {rolePrevilleges?.previllegesList?.find(
                        (elem) => elem?.code === "DISABLE_ACCOUNT"
                      ) && (
                        <Popconfirm
                          onConfirm={async () => {
                            await axios.patch(
                              "/api/auth/disable/" + employee.matricule
                            );
                            setRefresh(!refresh);
                          }}
                          okText="Oui"
                          cancelText="Non"
                          title="Archiver le collaborateur"
                          description={`Etes-vous sûr d'archiver ${employee?.fullName} ?`}
                        >
                          <Menu.Item>Archiver</Menu.Item>
                        </Popconfirm>
                      )}
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <Button danger shape="circle" type="primary" size="small">
                    <FontAwesomeIcon icon={faUserLock} />
                  </Button>
                </Dropdown>
              </Tooltip>
            )}
          {rolePrevilleges?.previllegesList?.find(
            (elem) => elem?.code === "EDIT_LEAVE_BALANCE"
          ) &&
            employee?.status === true && (
              <Tooltip title="Modifier le solde">
                <Button
                  size="small"
                  shape="circle"
                  onClick={() => showConfirm(employee.matricule, employee.sold)}
                >
                  <FontAwesomeIcon icon={faCoins} />
                </Button>
              </Tooltip>
            )}
        </Space>
      ),
    },
  ];

  const Navigate = useNavigate();

  const [employees, setEmployees] = useState([]);
  const [archivedEmployees, setArchivedEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function fetchArchive() {
      try {
        const { data } = await axios.get("/api/employees/disabled/users");
        if (data.status === "success") {
          setArchivedEmployees(data.employees);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
    async function fetchEmployees() {
      const { data } = await axios.get("/api/employees/");
      if (data.status === "success") {
        setEmployees(data.employees);
        setLoading(false);
      }
    }
    fetchEmployees();
    fetchArchive();
  }, [refresh]);

  const [mode, setMode] = useState(true);
  const onChangeColonnes = (checkedValues) => {
    setColonnes(checkedValues);
  };

  const handleExportToXLSX = () => {
    if (colonnes.length > 0) {
      let tableData = tempEmployees.length > 0 ? tempEmployees : employees;
      const data = tableData?.map((record) => {
        return colonnes.reduce((acc, key) => {
          switch (key) {
            case "Nom et prénom":
              acc[key] = record.fullName;
              break;
            case "Matricule":
              acc[key] = record.matricule;
              break;
            case "Poste":
              acc[key] = record.Poste;
              break;
            case "Numéro CIN":
              acc[key] = record.CIN;
              break;
            case "RIB":
              acc[key] = record.Rib;
              break;
            case "Date d'embauche":
              acc[key] = moment(record.embaucheDate).format("DD/MM/YYYY");
              break;
            case "Email professionnel":
              acc[key] = record.email;
              break;
            case "Email personnel":
              acc[key] = record._personal_email;
              break;
            case "Téléphone":
              acc[key] = record.phoneNum;
              break;
            case "Adresse":
              acc[key] = record.address;
              break;
            case "Date de naissance":
              acc[key] = moment(record.birthDate).format("DD/MM/YYYY");
              break;
            case "Lieu de naissance":
              acc[key] = record.birthPlace;
              break;
            case "Type de contrat":
              acc[key] = record.contractType;
              break;
            case "Solde de congé":
              acc[key] = record.sold;
              break;
            // Add more cases as needed
            default:
              acc[key] = ""; // Handle default case or provide a default value
              break;
          }
          return acc;
        }, {});
      });

      const worksheet = XLSX.utils.json_to_sheet(data);

      const workbook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      XLSX.writeFile(
        workbook,
        `Liste des collaborateur ${moment().format("DD/MM/YYYY HH-mm")}.xlsx`
      );
    } else {
      notification.warning({
        message: "",
        description: "Veuillez choisir au moins une colonne",
      });
    }
  };

  return (
    <div className="pt-4">
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spin size="large" />
        </div>
      ) : (
        <div className="bg-white p-4 rounded-4 shadow-sm ">
          <div className="mb-3">
            <h4>
              {mode
                ? "Liste des collaborateurs actifs"
                : "Liste des collaborateurs archivés"}
            </h4>
          </div>
          <div className="d-flex justify-content-between flex-column flex-lg-row flex-md-row pb-3">
            {mode ? (
              <Space className="my-1">
                {
                  <Button
                    shape="round"
                    icon={<FontAwesomeIcon icon={faPersonCircleQuestion} />}
                    onClick={() =>
                      Navigate("/administration/employees/invited")
                    }
                  >
                    Liste des Collaborateur(s) Invité(s)
                  </Button>
                }
                {rolePrevilleges?.previllegesList?.find(
                  (elem) => elem?.code === "USERS_ARCHIVE_MANAGEMENT"
                ) && (
                  <Button
                    shape="round"
                    icon={<FontAwesomeIcon icon={faBoxArchive} />}
                    onClick={() => setMode(!mode)}
                  >
                    {"Archive"}
                  </Button>
                )}
              </Space>
            ) : (
              <Button
                shape="round"
                icon={<FontAwesomeIcon icon={faArrowLeft} />}
                onClick={() => setMode(!mode)}
              >
                {"Retour"}
              </Button>
            )}
            <Space className="my-1">
              {rolePrevilleges?.previllegesList?.find(
                (elem) => elem?.code === "EXPORT_USERS"
              ) && (
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => setOpen(true)}
                  disabled={!(employees.length > 0)}
                  icon={<FontAwesomeIcon icon={faFileExport} />}
                >
                  Exporter
                </Button>
              )}
              {rolePrevilleges?.previllegesList?.find(
                (elem) => elem?.code === "INVITE_USERS"
              ) && (
                <Dropdown overlay={menu} trigger={["click"]}>
                  <Button shape="round">
                    <FontAwesomeIcon icon={faUserPlus} />
                  </Button>
                </Dropdown>
              )}
            </Space>
          </div>
          <Table
            className="shadow-sm"
            columns={columns}
            size={size}
            dataSource={mode ? employees : archivedEmployees}
            scroll={{ x: "max-content" }}
            onChange={(pagination, filters, sorter, extra) => {
              setTempEmployess(extra.currentDataSource);
            }}
          />
        </div>
      )}
      <Modal
        title="Choisir les colonnes à exporter"
        open={open}
        centered
        onOk={handleExportToXLSX}
        onCancel={() => setOpen(false)}
        width={1000}
      >
        <Form.Item>
          <Checkbox.Group
            options={options}
            onChange={(e) => onChangeColonnes(e)}
            style={{ margin: "2rem 0", display: "flex", textAlign: "justify" }}
          />
        </Form.Item>
      </Modal>
    </div>
  );
};

export default EmployeesList;

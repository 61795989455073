import {
  Button,
  Form,
  message,
  Select,
  Tag,
  TimePicker,
  Typography,
} from "antd";
import { useState, useEffect } from "react";
import Clock from "react-live-clock";
import dayjs from "dayjs";
import axios from "../../Utils/axios";
import TeleWorkClockingTable from "../../Components/TeleWorkClockingTable";

const format = "HH:mm";
const TeleWorkClocking = () => {
  const [form] = Form.useForm();
  const [clock, setClock] = useState({
    _start_post: "",
    _end_post: "",
    _start_pause: "",
    _end_pause: "",
  });
  const [records, setRecords] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const handleFinish = (formValues) => {
    setClock({
      ...clock,
      [formValues._type]: dayjs(formValues.time).format("HH:mm"),
    });
  };

  const handleSaveData = async () => {
    try {
      const { data } = await axios.post("/api/clock/", clock);
      message.success(data.message);
      setRecords([...records, { _date: Date.now(), ...clock }]);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const validateFields = () => {
      if (
        clock._start_post !== "" &&
        clock._end_post !== "" &&
        clock._start_pause !== "" &&
        clock._end_pause !== ""
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };
    validateFields();
  }, [clock]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get("/api/clock/me");
      setRecords(data);
      return data;
    }

    fetchData();
  }, []);

  return (
    <div className="bg-white p-4 mt-4 rounded shadow-sm  pb-5 container">
      <div className="border-bottom mb-3 d-flex justify-content-between align-items-center">
        <h3>Pointage télétravail</h3>

        <Tag
          color="geekblue"
          style={{
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          <Clock
            format={"HH:mm:ss"}
            ticking={true}
            // timezone={"UTC+01:00"}
          />
        </Tag>
      </div>
      <div className="row container justify-content-evenly">
        <div
          className="col-lg-6 p-4 rounded shadow-sm pb-5"
          style={{ backgroundColor: "#f4f5f7" }}
        >
          <Form
            form={form}
            onFinish={handleFinish}
            labelCol={{
              sm: { span: 10 },
              md: { span: 8 },
              lg: { span: 7 },
            }}
            wrapperCol={{
              sm: { span: 16 },
              md: { span: 14 },
              lg: { span: 16 },
            }}
          >
            <Form.Item label="Type de pointage" required name="_type">
              <Select placeholder="Choisir un type de pointage">
                <Select.Option value="_start_post">
                  Horaire de prise de poste
                </Select.Option>
                <Select.Option value="_end_post">
                  Horaire de départ
                </Select.Option>
                <Select.Option value="_start_pause">
                  Horaire de début de pause
                </Select.Option>
                <Select.Option value="_end_pause">
                  Horaire de fin de pause
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Horaire" required name="time">
              <TimePicker format={format} />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                sm: { offset: 10 },
                md: { offset: 8 },
                lg: { offset: 7 },
              }}
            >
              <Button type="primary" htmlType="submit">
                Ajouter
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div
          className="col-lg-5 rounded p-4 shadow-sm pb-5"
          style={{ backgroundColor: "#f4f5f7" }}
        >
          <Typography.Paragraph strong>
            Horaire de prise de poste:{" "}
            {clock?._start_post ? (
              <Tag color="blue">{clock?._start_post}</Tag>
            ) : (
              <Tag color="orange">En Attente</Tag>
            )}
          </Typography.Paragraph>

          <Typography.Paragraph strong>
            Horaire de départ:{" "}
            {clock?._end_post ? (
              <Tag color="blue">{clock?._end_post}</Tag>
            ) : (
              <Tag color="orange">En Attente</Tag>
            )}
          </Typography.Paragraph>

          <Typography.Paragraph strong>
            Horaire de début de pause:{" "}
            {clock?._start_pause ? (
              <Tag color="blue">{clock?._start_pause}</Tag>
            ) : (
              <Tag color="orange">En Attente</Tag>
            )}
          </Typography.Paragraph>

          <Typography.Paragraph strong>
            Horaire de fin de pause:{" "}
            {clock?._end_pause ? (
              <Tag color="blue">{clock?._end_pause}</Tag>
            ) : (
              <Tag color="orange">En Attente</Tag>
            )}
          </Typography.Paragraph>

          <Button
            type="primary"
            disabled={disabled}
            onClick={disabled ? null : handleSaveData}
          >
            Sauvegarder
          </Button>
        </div>
        <div
          className="col-lg-11 p-4 rounded shadow-sm pb-5 mt-5"
          style={{ backgroundColor: "#f4f5f7" }}
        >
          <TeleWorkClockingTable
            dataSource={records}
            loading={records ? false : true}
          />
        </div>
      </div>
    </div>
  );
};

export default TeleWorkClocking;

import { Button, Space, Spin, message, Tag } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../Utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";

const DownloadFile = ({ path, name, disabled }) => {
  return (
    <a
      href={!disabled && path}
      download={name}
      target="_blank"
      rel="noreferrer" // Add this line
    >
      <Tag
        color={disabled ? "default" : "blue"}
        style={{ fontSize: "12px", padding: "5px 10px" }}
      >
        <FontAwesomeIcon
          icon={faFileArrowDown}
          style={{ marginRight: "5px" }}
        />
        {name}
      </Tag>
    </a>
  );
};


const Documents = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axiosInstance.get("/api/employees/profile");

        setData(response.data?.employee);
        setLoading(false);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
    fetchData();
  }, []);
  return (
    <div className="py-3">
      <div className="bg-white rounded-3 py-2" style={{ minHeight: "80vh" }}>
        <div style={{ maxHeight: "82vh", overflowY: "scroll" }}>
          <div className="container py-3">
            <h4>🗂️ Mes Documents :</h4>
            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <Spin size="large" />
              </div>
            ) : (
              <div className="row">
                  <div className="col-auto my-2">
                    <DownloadFile
                      path={data?._cin_File ?? null}
                      name={"cin-" + data?.fullName.split(" ").join("-")}
                      disabled={data?._cin_File ? false : true}
                    />
                  </div>
                  <div className="col-auto my-2">
                    <DownloadFile
                      path={data?._rib_File ?? null}
                      name={"rib-" + data?.fullName.split(" ").join("-")}
                      disabled={data?._rib_File ? false : true}
                    />
                  </div>
                  <div className="col-auto my-2">
                    <DownloadFile
                      path={data?.CV ?? null}
                      name={"cv-" + data?.fullName.split(" ").join("-")}
                      disabled={data?.CV ? false : true}
                    />
                  </div>
                </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;

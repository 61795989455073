import { useEffect, useState } from "react";

import { Table, Tag, Typography, Space, Form, Modal, Button } from "antd";

import moment from "moment";
import axios from "../../Utils/axios";
import UploadInput from "../../Components/UploadInput";

const { Text } = Typography;

const AbsencesList = () => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(true);
  const [absences, setAbsences] = useState([]);

  useEffect(() => {
    async function fetchAbsences() {
      const { data } = await axios.get("/api/leaves/employees/absences");
      if (data.status === "success") {
        setLoading(false);
        setAbsences(data.absences);
      }
    }
    fetchAbsences();
  }, []);

  const setFile = (base64) => {
    form.setFieldsValue({
      OptionalFiles: base64,
    });
  };

  const columns = [
    { title: "Type", dataIndex: "_typeLeave" },
    {
      title: "Date de début",
      dataIndex: "BeginingDate",
      key: "5",
      render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
    },
    {
      title: "Date de Fin",
      dataIndex: "EndingDate",
      key: "6",
      render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
    },
    {
      title: "Nombre du jours",
      dataIndex: "DaysNumber",
      key: "8",
      render: (_, record) => (
        <Text>
          {record._is_half_day
            ? record._is_half_day
            : record.DaysNumber + " jour(s)"}
        </Text>
      ),
    },
    {
      title: "Statut",
      dataIndex: "status",
      key: "7",
      render: (_, record) => (
        <>
          {record.status === "Pending" && (
            <Tag color={"orange"}>Non encore justifiée.</Tag>
          )}
          {record.status === "Accepted" && <Tag color={"green"}>Approuvée</Tag>}
          {record.status === "Validating" && (
            <Tag color={"green"}>En attente d'approbation</Tag>
          )}
          {record.status === "Rejected" && <Tag color={"red"}>Rejeté</Tag>}
          {record.status === "Canceled" && <Tag color="red">Annulée</Tag>}
          {record.status === "Unjustified" && (
            <Tag color={"red"}>Non encore justifiée</Tag>
          )}
          {record.status === "Failed" && <Tag color={"red"}>Échue</Tag>}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {record.status === "Pending" || record.status === "Unjustified" ? (
            <>
              {/* eslint-disable-next-line */}
              <a>
                <Button
                  onClick={() => {
                    Modal.info({
                      title: "Joindre un justificatif",
                      cancelText: "Annuler",
                      okCancel: true,
                      content: (
                        <>
                          <Form form={form} layout="vertical">
                            <Form.Item name="OptionalFiles" className="pt-3">
                              <UploadInput setFile={setFile} />
                            </Form.Item>
                          </Form>
                        </>
                      ),
                      onOk: async () => {
                        const formValues = form.getFieldsValue();
                        if (formValues.OptionalFiles) {
                          await axios.patch("/api/leaves/" + record._id, {
                            OptionalFiles: formValues.OptionalFiles,
                            status: "Validating",
                          });
                        }
                      },
                    });
                  }}
                  type="primary"
                  size="small"
                >
                  Joindre un justificatif
                </Button>
              </a>
            </>
          ) : (
            <Button disabled type="primary" size="small">
              N/A
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className="pt-4">
      <div>
        <div className="bg-white p-4 rounded shadow-sm  ">
          <div className=" mb-3 ">
            <h4>Mes absences</h4>
          </div>

          <Table
            loading={loading}
            columns={columns}
            scroll={{ x: "max-content" }}
            dataSource={absences}
          />
        </div>
      </div>
    </div>
  );
};

export default AbsencesList;

import React, { useEffect, useState, useRef } from "react";
import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
  Input,
} from "antd";
import Highlighter from "react-highlight-words";
import dayjs from "dayjs";
import axios from "../../../Utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../Utils/axios";
const UploadedEquipments = ({
  uploadedData,
  setUploadedData,
  loading,
  setLoading,
}) => {
  const [employees, setEmployees] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Rechercher ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            Rechercher
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
          >
            Réinitialiser
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Fermer
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (ref) => {
    const temp = uploadedData?.filter((elem) => elem?._ref !== ref);

    setUploadedData(temp);
  };

  const columns = [
    {
      title: "Actions",
      render: (record) => (
        <Space>
          <Button
            size="small"
            type="primary"
            onClick={() => {
              handleDelete(record?._ref);
            }}
            shape="round"
            danger
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </Button>
        </Space>
      ),
    },
    {
      title: "Réference",
      dataIndex: "_ref",
      key: "Catégorie",
      ...getColumnSearchProps("_ref", "réference"),
    },
    {
      title: "Catégorie",
      dataIndex: "_categorie",
      key: "Référence",
      ...getColumnSearchProps("_categorie", "catégorie"),
    },
    {
      title: "Marque",
      dataIndex: "_brand",
      key: "Marque",
      ...getColumnSearchProps("_brand", "marque"),
    },
    {
      title: "Affectée à",
      render: (record) => (
        <>
          {record._status === "AFFECTED_TO" ? (
            <Tooltip
              title={
                "Affecté à " +
                employees?.find(
                  (elem) => elem.matricule === record._current_holder
                )?.fullName
              }
            >
              <Tag color="blue">
                {" "}
                {
                  employees?.find(
                    (elem) => elem.matricule === record._current_holder
                  )?.fullName
                }
              </Tag>
            </Tooltip>
          ) : (
            <Typography.Text>
              {record._status === "AVAILABLE" ? (
                <Tag color="green">Disponible</Tag>
              ) : (
                <Tag color="red">En pane</Tag>
              )}
            </Typography.Text>
          )}
        </>
      ),
    },
    {
      title: "Date d'acquisition",
      key: "date",
      sorter: (a, b) =>
        dayjs(a?._time_line[a._time_line.length - 1]?._start_date) -
        dayjs(b?._time_line[b._time_line.length - 1]?._start_date),
      render: (record) => (
        <Typography.Text>
          {dayjs(
            record._time_line[record._time_line.length - 1]._start_date
          ).format("DD/MM/YYYY")}
        </Typography.Text>
      ),
    },
    {
      title: "Numéro du facture",
      dataIndex: "_num_bill",
      ...getColumnSearchProps("_num_bill", "n° facture"),
    },
    {
      title: "Nom du Fournisseur",
      dataIndex: "_provider",
    },
  ];

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      axios
        .get("/api/employees/")
        .then(async (res) => {
          setEmployees(res.data.employees);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.response.data.message);
        });
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpload = async () => {
    try {
      setLoading(true);
      messageApi.open({
        type: "loading",
        content: "Insertion en cours...",
        duration: 0,
      });

      const { data } = await axiosInstance.post("/api/equipments/insert/many", {
        equipments: uploadedData,
      });

      if (data?.status === "success") {
        messageApi.destroy();
        setLoading(false);
        message.open({
          type: "success",
          content: "Tous les documents ont été ajoutés avec succès",
          duration: 3,
        });
      }
    } catch (error) {
      setLoading(false);
      messageApi.destroy();
      message.open({
        type: "error",
        content: error?.response?.data?.message,
        duration: 4,
      });
    }
  };

  return (
    <div className="bg-white p-4 mb-4 rounded shadow-sm ">
      <div className="mb-3 d-flex align-items-center justify-content-between">
        <h5>Liste des équipements</h5>
        <Button disabled={loading} type="primary" onClick={handleUpload}>
          Importer
        </Button>
      </div>
      {contextHolder}
      <Table
        dataSource={uploadedData}
        loading={loading}
        scroll={{ x: "max-content" }}
        columns={columns}
        size="small"
      />
    </div>
  );
};

export default UploadedEquipments;

import { Button, Empty, Menu, Space, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import axiosInstance from "../../../Utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faPen } from "@fortawesome/free-solid-svg-icons";

function getItem(label, key, icon, children, disabled, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
    disabled,
  };
}

const RolesAndPrevs = () => {
  const [roles, setRoles] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [items, setItems] = useState([]);
  const [edit, setEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [previlleges, setPrevilleges] = useState([]);

  const handleClick = (rolesList, code) => {
    const temp = rolesList?.find((elem) => elem?.code === code);
    setSelectedItem(temp);
  };

  useEffect(() => {
    function fetchData() {
      axiosInstance
        .get("/api/previllege")
        .then((response) => {
          setPrevilleges(response?.data?.previlleges);
          axiosInstance
            .get("/api/role/")
            .then((res) => {
              let temp = res?.data?.roles.map((elem, idx) => {
                return getItem(
                  <div
                    className="text-decoration-none w-100"
                    onClick={() => {
                      handleClick(res?.data?.roles, elem?.code);
                    }}
                  >
                    {elem?.name}
                  </div>,
                  elem?.code
                );
              });
              temp.push(
                getItem(
                  <div
                    className="text-decoration-none w-100"
                    onClick={() => {
                      const tmp = {
                        name: "Liste de tous les prévillages",
                        code: "prevList",
                        previllegesList: response?.data?.previlleges,
                      };
                      setSelectedItem(tmp);
                    }}
                  >
                    Tous les prévillages
                  </div>,
                  "prevList"
                )
              );
              setItems(temp);
              setSelectedItem(selectedItem || res?.data?.roles[0]);
              setRoles(res?.data?.roles);
            })
            .catch((error) => message.error(error.response.data.message));
        })
        .then((error) => message.error(error.response.data.message));
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const handleSaveRole = async () => {
    const payload = {
      code: selectedItem?.code,
      name: selectedItem?.name,
      description: selectedItem?.description,
      previlleges: selectedItem?.previllegesList?.map((elem) => elem?._id),
    };

    try {
      const { data } = await axiosInstance.patch(
        "/api/role/" + selectedItem?._id,
        payload
      );

      if (data?.status) {
        message.success("Prévilleges mis à jour avec succès");
        setRefresh(!refresh);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const handleCancel = () => {
    setSelectedItem({
      ...roles.find((elem) => selectedItem.code === elem.code),
    });
    setEdit(false);
  };

  const onChange = (params, checked) => {
    let temp = selectedItem;
    let previllegesList;

    if (checked) {
      previllegesList = temp.previllegesList;
      previllegesList.push(previlleges.find((elem) => elem?._id === params));
    } else {
      previllegesList = temp.previllegesList.filter(
        (elem) => elem._id !== params
      );
    }

    temp.previllegesList = previllegesList;

    setSelectedItem({ ...temp });
  };

  return (
    <div className="row">
      <div className={edit ? "d-none" : "col-3"}>
        <Menu
          defaultSelectedKeys={[selectedItem?.code || "EMPLOYEE"]}
          className="h-100"
          mode="inline"
          items={items}
        />
      </div>
      <div className={edit ? "col-12" : "col-9"}>
        <div className="d-flex justify-content-between align-items-center">
          <h4 style={{ fontSize: "20px", fontWeight: "500" }}>
            {selectedItem?.name}
          </h4>
          {selectedItem?.code !== "prevList" && (
            <Space>
              {edit && (
                <Button shape="round" onClick={handleCancel}>
                  Annuler
                </Button>
              )}
              <Button
                type="primary"
                shape="round"
                onClick={() => {
                  if (edit) {
                    handleSaveRole();
                    setEdit(false);
                  } else {
                    setEdit(true);
                  }
                }}
                icon={<FontAwesomeIcon icon={edit ? faFloppyDisk : faPen} />}
              >
                {edit ? "Sauvgarder" : "Modifier"}
              </Button>
            </Space>
          )}
        </div>
        <div className="d-flex flex-column">
          <div
            style={{
              marginLeft: "10px",
              height: "65vh",
              overflowY: "scroll",
              overFlowX: "hidden",
            }}
          >
            {!edit ? (
              <>
                {selectedItem?.previllegesList?.length > 0 && (
                  <ul>
                    {selectedItem?.previllegesList?.map((elem) => {
                      return (
                        <li>
                          <div
                            style={{ fontSize: "14px", fontWeight: "400" }}
                            className="d-flex flex-column"
                          >
                            <span>{elem?.code}</span>
                            <span
                              className="text-muted"
                              style={{ fontStyle: "italic" }}
                            >
                              {elem?.description}
                            </span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </>
            ) : (
              <>
                {previlleges?.length > 0 && (
                  <ul style={{ overFlowX: "hidden" }}>
                    {previlleges?.map((elem) => {
                      return (
                        <li style={{ overFlowX: "hidden" }}>
                          <div
                            style={{ overFlowX: "hidden" }}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <div
                              style={{ fontSize: "18px", fontWeight: "400" }}
                              className="d-flex flex-column"
                            >
                              <span>{elem?.code}</span>
                              <span
                                className="text-muted"
                                style={{ fontStyle: "italic" }}
                              >
                                {elem?.description}
                              </span>
                            </div>
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                              onChange={(e) => onChange(elem?._id, e)}
                              defaultChecked={selectedItem?.previllegesList?.find(
                                (item) => item?._id === elem?._id
                              )}
                            />
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </>
            )}
            {selectedItem?.previllegesList?.length === 0 && <Empty />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolesAndPrevs;

import {
  Button,
  Space,
  Table,
  Tag,
  message,
  Input,
  Popconfirm,
  Typography,
  Tooltip
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import Highlighter from "react-highlight-words";
import axios from "../../../Utils/axios";
import CertificateList from "../../../Components/CertificateList";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faXmark } from "@fortawesome/free-solid-svg-icons";
const { Paragraph } = Typography;

const OtherRequest = (props) => {
  const { filteredData, dateRange, size, currentValue } = props;
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const Navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(
        `/api/request/?status=MODIFICATION${
          dateRange
            ? "?startDate=" +
              dateRange.startDate +
              "&endDate=" +
              dateRange.endDate
            : ""
        }`
      );

      setRequests(data.requests);
      setLoading(false);

      return data;
    }

    fetchData();
  }, [dateRange]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Recherche ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            // style={{
            //   width: 90,
            // }}
          >
            Recherche
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleApprove = async (record) => {
    try {
      const { data } = await axios.patch("/api/request/" + record._id, {
        status: "APPROVED",
      });
      if (data.status === "success") {
        message.success(data.message);
        const new_requests = requests.map((r) => {
          if (r._id === data.request._id) {
            return data.request;
          } else {
            return r;
          }
        });
        setRequests(new_requests);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleReject = async (record) => {
    try {
      const { data } = await axios.patch("/api/request/" + record._id, {
        status: "REJECTED",
      });
      if (data.status === "success") {
        message.success(data.message);
        const new_requests = requests.map((r) => {
          if (r._id === data.request._id) {
            return data.request;
          } else {
            return r;
          }
        });
        setRequests(new_requests);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const columns = [
    {
      title: "Soumis le",
      dataIndex: "send_time_date",
      render: (_, leave) => (
        <Typography.Text strong>
          {leave?.send_time_date
            ? moment(leave.send_time_date).format("DD/MM/YYYY HH:mm")
            : "N/A"}
        </Typography.Text>
      ),
      sorter: (a, b) => moment(a.send_time_date) - moment(b.send_time_date),
    },
    {
      title: "Demandeur",
      dataIndex: "sender",
      ...getColumnSearchProps("sender", "Nom et prénom"),
    },
    {
      title: "Demande",
      dataIndex: "request",
      width: 300,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            expandable: true,
            symbol: "Plus",
          }}
          style={{ whiteSpace: "pre-wrap" }}
        >
          {text}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Motif",
      dataIndex: "motif",
      width: 300,
      render: (_, requestedLeaves) => (
        <>
          <Paragraph
            style={{ margin: 0, whiteSpace: "pre-wrap" }}
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: "Plus",
            }}
          >
            {requestedLeaves.motif}
          </Paragraph>
        </>
      ),
    },

    {
      title: "Statut",
      dataIndex: "status",
      render: (_, record) => (
        <>
          {record.status === "PENDING" && <Tag color="orange">En attente</Tag>}
          {record.status === "APPROVED" && <Tag color="green">Approuvée</Tag>}

          {record.status === "REJECTED" && <Tag color="red">Rejetée</Tag>}
          {record.status === "CANCELLED" && <Tag color="red">Annulée</Tag>}
        </>
      ),
      filters: [
        {
          text: <Tag color="orange">En attente</Tag>,
          value: "PENDING",
        },
        {
          text: <Tag color="green">Acceptée</Tag>,
          value: "APPROVED",
        },
        {
          text: <Tag color="red">Rejetée</Tag>,
          value: "REJECTED",
        },
        {
          text: <Tag color="red">Annulée</Tag>,
          value: "CANCELLED",
        },
      ],
      defaultFilteredValue: filteredData || [],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Actions",
      render: (_, record) => (
        <Space>
          {record.type === "MODIFICATION" && (
            <Button
              size="small"
              shape="circle"
              onClick={() =>
                Navigate(
                  "/administration/edit/request/" +
                    record._id +
                    "/" +
                    record.sender_id
                )
              }
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
          )}
          {record.status === "PENDING" && (
            <>
              <Popconfirm
                placement="topLeft"
                okText="Oui"
                title="Vous êtes sûr d'approuver la demande?"
                onConfirm={() => {
                  handleApprove(record);
                }}
              >
                <Tooltip title="Approuver la demande">
                  <Button size="small" shape="circle" type="primary">
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                </Tooltip>
              </Popconfirm>
              <Popconfirm
                placement="topLeft"
                okText="Oui"
                title="Vous êtes sûr de rejeter la demande?"
                onConfirm={() => {
                  handleReject(record);
                }}
              >
                <Tooltip title="Rejeter la demande">
                  <Button size="small" shape="circle" type="primary" danger>
                    <FontAwesomeIcon icon={faXmark} />
                  </Button>
                </Tooltip>
              </Popconfirm>
            </>
          )}
          {record.status === "APPROVED" && (
            <Tooltip title="Valider la demande">
              <Button shape="circle" disabled size="small" type="primary">
                <FontAwesomeIcon icon={faCheck} />
              </Button>
            </Tooltip>
          )}
          {record.status === "REJECTED" && (
            <Tooltip title="Valider la demande">
              <Button shape="circle" disabled size="small" type="primary">
                <FontAwesomeIcon icon={faCheck} />
              </Button>
            </Tooltip>
          )}
          {record.status === "CANCELED" && (
            <Tooltip title="Valider la demande">
              <Button shape="circle" disabled size="small" type="primary">
                <FontAwesomeIcon icon={faCheck} />
              </Button>
            </Tooltip>
          )}
        </Space>
      ),
    },

  ];

  return (
    <div>
      {currentValue === "OTHERS" ? (
        <Table
          className="shadow-sm"
          columns={columns}
          size={size}
          loading={loading}
          dataSource={requests}
          scroll={{ x: "max-content" }}
        />
      ) : (
        <CertificateList isAdmin={true} size={size} />
      )}
    </div>
  );
};

export default OtherRequest;

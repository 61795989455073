import React, { useEffect, useState } from "react";
import { Table, message, Space, Button } from "antd";
import FormServer from "./FormServer";
import dayjs from "dayjs";
import axiosInstance from "../../../Utils/axios";
import * as XLSX from "xlsx";
import EditServer from "./EditServer";
import DeleteServer from "./DeleteServer";

const ServersConfiguration = () => {
  const [servers, setServers] = useState([]);

  const exportData = () => {
    const dataToExport = servers.map((server) => ({
      'Hostname': server.hostName,
      'Ip Address': server.ipAddress,
      'Region': server.region,
      'Vendeur': server.vendor,
      'Url Dashboard Vendeur': server.urlDashboardVendor,
      'Url De Gestion': server.urlManagement ?? "N/A",
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Servers Data");
    XLSX.writeFile(workbook, `servers_data_${dayjs().format("DD-MM-YYYY-HH-mm")}.xlsx`);
  };
  

  const column = [
    {
      title: "Host Name",
      dataIndex: "hostName",
    },
    {
      title: "IP Address",
      dataIndex: "ipAddress",
    },
    {
      title: "Region",
      dataIndex: "region",
    },
    {
      title: "Vendeur",
      dataIndex: "vendor",
    },
    {
      title: "URL dashboard du vendeur",
      dataIndex: "urlDashboardVendor",
      render: (text) => <>{text ?? "N/A"}</>,
    },
    {
      title: "URL de gestion",
      dataIndex: "urlManagement",
      render: (text) => <>{text ?? "N/A"}</>,
    },
    {
      title: "Action",
      render: (_, record) => (
        <Space>
          <EditServer
            servers={servers}
            setServers={setServers}
            record={record}
          />
          <DeleteServer
            servers={servers}
            setServers={setServers}
            record={record}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get("/api/administration/servers");

        setServers(data?.data?.servers);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="bg-white p-4 rounded-4 shadow-sm ">
      <div className="mb-3 d-flex justify-content-between">
        <h4>⚙️ Configuration des serveurs</h4>
        <Space>
          <FormServer servers={servers} setServers={setServers} />
          <Button onClick={exportData}>
            Exporter
          </Button>
        </Space>
      </div>
      <Table columns={column} dataSource={servers} size="small" bordered />
    </div>
  );
};

export default ServersConfiguration;

import { Button, Table, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../Utils/axios";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TodoTable = () => {
    const [items, setItems] = useState([]);

    const Navigate = useNavigate();

    const columns = [
        {
            title: "Tâche",
            dataIndex: "title",
        },
        {
            title: "Date d'échéance",
            dataIndex: "dueDate",
            sorter: (a, b) => dayjs(a.dueDate) - dayjs(b.dueDate),
            render: (text) => (
                <Typography.Text>{text ? text : "N/A"}</Typography.Text>
            ),
        },
        {
            title: "Consulter",
            render: (_, record) => (
                <Button
                    size="small"
                    onClick={() => {
                        if (record?.path) {
                            Navigate(record?.path);
                        }
                    }}
                    disabled={!record?.path}
                >
                    Consulter
                </Button>
            ),
        },
    ];

    useEffect(() => {
        async function fetchTodos() {
            try {
                const { data } = await axiosInstance.get("/api/todo/me");
                setItems(data?.todos);
            } catch (error) {
                message.error(error?.response?.data?.message);
            }
        }

        fetchTodos();
    }, []);

    return (
        <div>
            <h1 style={{ fontWeight: "500", fontSize: "18px" }} className="pb-2">
                {items?.length} Tâche(s) à faire
            </h1>
            <Table
                dataSource={items}
                columns={columns}
                size="small"
                scroll={{ x: "max-content" }} // set the x value to a value greater than the width of the table
            />
            <div className="d-flex justify-content-end mt-3">
                <Button size="small" icon={<FontAwesomeIcon icon={faArrowRight} />} onClick={() => Navigate("/administration/todos")} shape="round" type="primary">Afficher en détails</Button>
            </div></div>
    );
};

export default TodoTable;

import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Timeline,
  Typography,
} from "antd";
import React, { useContext, useState } from "react";
import axiosInstance from "../../../Utils/axios";
import GlobalContext from "../../../Context/GlobalContext";
import { useNavigate } from "react-router-dom"; 

const SelfAccountForm = ({ employees, setInitFormValues }) => {
  const { theme } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const [isAlternate, setIsAlternate] = useState(false);


  const handleSubmit = async (formValues) => {
    formValues.Role = "EMPLOYEE";
    try {

      const { data } = await axiosInstance.post(
        "/api/employees/half",
        formValues
      );
      if (data) {
        Modal.success({
          title: "Invitation envoyée avec succès !",
          content: (
            <p>
              L'invitation de {formValues?.fullName} a été envoyée à l'email
              suivant. <br />
              <Typography.Text code>
                {formValues?._personal_email}
              </Typography.Text>
            </p>
          ),
        });
        form.resetFields();
      }
    } catch (error) {
      Modal.error({
        title: "Impossible de créer un compte!",
        content: <p>{error.response.data.message}</p>,
      });
    }

  };


  const Navigate =useNavigate()

  return (
    <Form
      onFinish={handleSubmit}
      form={form}
      className="p-3 rounded-3 shadow-sm row"
      layout="vertical"
      size="middle"
    >
      <Timeline className="border-bottom mb-3">
        <Timeline.Item>
          <h6
            style={{
              color: theme?.color,
              marginBottom: "10px",
              paddingTop: "2px",
              fontWeight: "600",
            }}
          >
            🔗 Généralités
          </h6>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <Space>
                <Form.Item label="Civilité" name="civility">
                  <Radio.Group defaultValue={"M."}>
                    <Radio value={"Mme"}>Mme</Radio>
                    <Radio value={"M."}>M.</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="Nom et prénom"
                  name="fullName"
                  validateTrigger={"onDone"}
                  rules={[
                    { required: true, message: "Merci de remplir le champ" },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({
                        fullName: e.target.value.replace(/[^a-zA-Z ]/g, ""),
                      });
                    }}
                  />
                </Form.Item>
              </Space>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <Form.Item
                label="Email Personnel"
                name="_personal_email"
                validateTrigger={"onDone"}
                rules={[
                  { type: "email", message: "Veuillez entrer un email valid" },
                  { required: true, message: "Merci de remplir le champ" },
                ]}
              >
                <Input placeholder="email@gmail.com" />
              </Form.Item>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <Form.Item
                label="Matricule"
                name="matricule"
                rules={[
                  { required: true, message: "Merci de remplir le champ" },
                ]}
              >
                <Input maxLength={5} />
              </Form.Item>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <Form.Item
                label="Date d'inscription"
                name="echeanceDate"
                rules={[
                  { required: true, message: "Merci de remplir le champ" },
                ]}
              >
                <DatePicker placeholder="Date d'échéance" />
              </Form.Item>
              
            </div>
          
            <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-12">
              <Form.Item
                label="Date d'embauche"
                name="EmbaucheDate"
                rules={[
                  { required: true, message: "Merci de remplir le champ" },
                ]}
              >
                <DatePicker placeholder="Date d'embauche" />
              </Form.Item>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-12">
                <Form.Item label="Alternance" name="_is_alternate">
                  <Radio.Group
                    onChange={(e) => {
                      form.setFieldsValue({
                        ...form.getFieldsValue(),
                        _is_alternate: e.target.value,
                      });
                      setIsAlternate(e.target.value);
                    }}
                    defaultValue={false}
                    buttonStyle="solid"
                  >
                    <Radio.Button value={true}>Oui</Radio.Button>
                    <Radio.Button value={false}>Non</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {isAlternate && (
                  <Form.Item
                    label="École"
                    name="_alternant_school"
                    rules={[
                      { required: true, message: "Veuillez remplir le champ" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}
                
              </div >
             

              
            </div>
          </div>
        </Timeline.Item>
        {/* <Timeline.Item>
          <h6
            style={{
              color: theme?.color,
              marginBottom: "10px",
              paddingTop: "2px",
              fontWeight: "600",
            }}
          >
            📋 Contrat
          </h6>
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <Form.Item
                label="Type de contrat"
                name="contractType"
                rules={[
                  { required: true, message: "Merci de remplir le champ" },
                ]}
              >
                <Select placeholder="Type de contract (CDI, CIVP 1, CIVP 2, KARAMA, CDD)">
                  <Select.Option key="1" value="CDI">
                    CDI
                  </Select.Option>
                  <Select.Option key="2" value="CIVP 1">
                    CIVP 1
                  </Select.Option>
                  <Select.Option key="22" value="CIVP 2">
                    CIVP 2
                  </Select.Option>
                  <Select.Option key="3" value="KARAMA">
                    KARAMA
                  </Select.Option>
                  <Select.Option key="4" value="CDD">
                    CDD
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <Form.Item
                label="Date d'embauche"
                name="embaucheDate"
                rules={[
                  { required: true, message: "Merci de remplir le champ" },
                ]}
              >
                <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
              </Form.Item>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12">
              <Form.Item label="Date de fin du contrat" name="endContractDate">
                <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
              </Form.Item>
            </div>
            <div>
              <Tabs defaultActiveKey="1" items={items} />
            </div>
          </div>
        </Timeline.Item> */}
        <Timeline.Item>
          <h6
            style={{
              color: theme?.color,
              marginBottom: "10px",
              paddingTop: "2px",
              fontWeight: "600",
            }}
          >
            👥 Equipe
          </h6>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <Form.Item label="Responsable" name="_idResponsable">
                <Select placeholder="choisir un responsable pour cet employé">
                  {employees.map((emp) => {
                    return (
                      <Select.Option value={emp.matricule}>
                        {emp.fullName} #{emp.matricule}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <Form.Item
                label="Poste"
                name="Poste"
                rules={[
                  { required: true, message: "Merci de remplir le champ" },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </div>
        </Timeline.Item>
      </Timeline>

      <Space>
        <Button
          shape="round"
          onClick={() => {
            Navigate('/administration/employees/list')
          }}
        >
          Annuler
        </Button>
        <Button type="primary" shape="round" htmlType="submit">
          Inviter
        </Button>
      </Space>
    </Form>
  );
};

export default SelfAccountForm;

import {
  faClipboardList,
  faEllipsisVertical,
  faFilePen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Dropdown, Space } from "antd";
import dayjs from "dayjs";
import React from "react";
import FillBucketModal from "./FillBucketModal";
import { useNavigate } from "react-router-dom";

const SkillBucket = ({ data, handleRefresh }) => {
  const Navigate = useNavigate();
  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            Navigate(
              "/administration/employees/skills/bucket/" +
                data?._id +
                "?name=" +
                data?.name
            );
          }}
        >
          <FontAwesomeIcon
            icon={faClipboardList}
            style={{ marginRight: "5px" }}
          />
          Consulter
        </div>
      ),
    },
    {
      key: "2",
      label: <FillBucketModal id={data?._id} handleRefresh={handleRefresh} />,
    },
  ];
  return (
    <Card className="d-flex flex-column shadow-sm h-100">
      <div
        style={{ fontSize: "18px", fontWeight: "500" }}
        className="d-flex justify-content-between align-items-center"
      >
        <span>{data?.name}</span>
        <Dropdown
          menu={{
            items,
          }}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </Space>
          </a>
        </Dropdown>
      </div>
      <div style={{ fontSize: "12px", fontWeight: "400" }}>
        {dayjs(data?.startDate).format("DD/MM/YYYY")} jusqu'à{" "}
        {dayjs(data?.endDate).format("DD/MM/YYYY")}
      </div>
      <div
        className="text-muted"
        style={{ fontSize: "14px", fontWeight: "400" }}
      >
        Compétance(s) :
      </div>
      <div className="row">
        {data?.skillsList.map((elem) => {
          return (
            <span
              className="col-auto m-2 px-2 rounded-3"
              style={{
                backgroundColor: "#E6F4FF",
                color: "#1A64DC",
                border: "1px solid #91CAFF",
                fontSize: "14px",
                padding: "3px 9px",
              }}
            >
              {elem.label} ({elem?.category})
            </span>
          );
        })}
      </div>
      <div
        className="text-muted"
        style={{ fontSize: "14px", fontWeight: "400" }}
      >
        Utilisateur(s) :
      </div>
      <div className="row">
        {data?.dedicatedFor.map((elem) => {
          return (
            <span
              className="col-auto m-2 px-2 rounded-3"
              style={{
                backgroundColor: "#E6F4FF",
                color: "#1A64DC",
                border: "1px solid #91CAFF",
                fontSize: "14px",
                padding: "3px 9px",
              }}
            >
              {elem?.fullName}
            </span>
          );
        })}
      </div>
    </Card>
  );
};

export default SkillBucket;

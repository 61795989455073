import React, { useEffect, useState } from 'react'
import { Empty, message } from "antd"
import axiosInstace from "../../../Utils/axios"
import TodoItem from './TodoItem';

const TodoList = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        async function fetchTodos() {
            try {
                const { data } = await axiosInstace.get("/api/todo/me");
                setItems(data?.todos);
            } catch (error) {
                message.error(error?.response?.data?.message);
            }
        }

        fetchTodos();
    }, [])
    return (
        <div className='p-3 '>

            <div style={{ height: "75vh", overflowY: "scroll" }}>
                {
                    items?.map((elem) => {
                        return <TodoItem data={elem} items={items} setItems={setItems} />
                    })
                }
                {items?.length === 0 && <Empty description={"Aucune Tâche à faire pour le moment."} />}
            </div>
        </div>
    )
}

export default TodoList
